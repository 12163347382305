import { useEffect, useState } from "react";
import { TbTTab, TbTTabView } from "../../../components/TabView/TabView";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { IOrganization, IPublicationApplication, ITeam, IUser } from "../../../types";
import "./SuperadminPage.css";
import useDatabase from "../../../hooks/database";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { DBResult } from "../../../utils/database";
import SnackbarError from "../../../components/SnackbarError";
import FencerRecordFixer from "../../../components/FencerRecordFixer/FencerRecordFixer";
import { mdiPrinter } from "@mdi/js";
import { printTeamList } from "../../../utils/pdfExport";
import Icon from "@mdi/react";

interface PublicationRequestProps {
    data: IPublicationApplication;
    setAcceptingRequest: (val: string) => void;
    setRejectingRequest: (val: string) => void;
}

const PublicationRequest = ({ data, setAcceptingRequest, setRejectingRequest }: PublicationRequestProps) => {
    const userData = useSelector((s: ReduxState) => s.userInfo);

    const [orgData, setOrgData] = useState<IOrganization | null>(null);
    const [orgError, setOrgError] = useState<string | null>(null);

    const userError = !userData ? "Not logged in" : "";

    const DB = useDatabase();

    useEffect(() => {
        DB.getOrganization(data.orgId)
            .then(result => {
                if (result.status === "fail") return setOrgError(result.data);
                setOrgData(result.data);
            })
            .catch(setOrgError);
    }, []);

    if (orgError || userError) {
        return (
            <Card>
                <CardContent>
                    <Typography>{orgError || userError}</Typography>
                </CardContent>
            </Card>
        );
    }

    if (!orgData || !userData) {
        return (
            <Card>
                <CardContent>
                    <Typography>Loading...</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader
                title={`${data.takeover ? "Takeover" : "Publication"} Request for ${orgData.name}`}
                subheader={`Submitted at ${new Date(data.submittedAt).toDateString()}`}
            />
            <CardContent>
                <Typography>
                    Request made by <span style={{ fontWeight: 500 }}>{data.name}</span> (
                    <a href={`mailto:${userData.email}`}>{userData.email}</a>) from school {data.school}
                </Typography>
                {data.additionalInfo ? <Typography>Note from requester: {data.additionalInfo}</Typography> : null}
            </CardContent>
            <CardActions>
                <Button onClick={() => setAcceptingRequest(data.id)} color="success" style={{ marginRight: 10 }}>
                    Accept
                </Button>
                <Button onClick={() => setRejectingRequest(data.id)} color="error">
                    Reject
                </Button>
            </CardActions>
        </Card>
    );
};

const TeamList = ({ teams, users, loading }: { teams: Record<string, ITeam>; users: Record<string, IUser>; loading: boolean }) => {
    const [showUnpublished, setShowUnpublished] = useState(true);

    const rows = Object.values(teams).map(team => ({
        id: team.id,
        name: team.name,
        published: team.published,
        createdAt: new Date(team.createdAt).toLocaleDateString(),
        creator: users[team.createdBy] ? `${users[team.createdBy].firstName} ${users[team.createdBy].lastName}` : "Unknown",
        admins: team.administrators.map(l => (users[l] ? `${users[l].firstName} ${users[l].lastName}` : "Unknown")).join(", ")
    }));

    const displayedRows = rows.filter(l => showUnpublished || l.published);

    displayedRows.sort((a, b) => a.name.localeCompare(b.name));

    const print = async () => {
        const pdf = await printTeamList(displayedRows);
        const pdfArray = await pdf.save();

        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const link = window.URL.createObjectURL(blob);
        window.open(link);
    };

    return (
        <Paper>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: "10px" }}>
                <FormControlLabel
                    sx={{ margin: "0 10px 0 0" }}
                    control={<Switch defaultChecked onChange={() => setShowUnpublished(u => !u)} />}
                    label="Show unpublished?"
                />
                <Button onClick={print} startIcon={<Icon path={mdiPrinter} size={1} />}>
                    Print table
                </Button>
            </Box>
            <TableContainer component={Box} sx={{ height: 500 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Team Name</TableCell>
                            <TableCell>Created On</TableCell>
                            <TableCell>Creator</TableCell>
                            <TableCell>Admins</TableCell>
                            <TableCell>Published</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedRows.map(l => (
                            <TableRow key={`teamList${l.id}`}>
                                <TableCell>{l.name}</TableCell>
                                <TableCell>{l.createdAt}</TableCell>
                                <TableCell>{l.creator}</TableCell>
                                <TableCell>{l.admins}</TableCell>
                                <TableCell sx={{ textAlign: "center" }}>{l.published ? "Yes" : "No"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default function SuperadminPage() {
    const [organizationRequests, setOrganizationRequests] = useState<Record<string, IPublicationApplication>>({});
    const [acceptingRequest, setAcceptingRequest] = useState<string | null>(null);
    const [rejectingRequest, setRejectingRequest] = useState<string | null>(null);
    const [rejectionReason, setRejectionReason] = useState("");
    const [teams, setTeams] = useState<Record<string, ITeam>>({});
    const [users, setUsers] = useState<Record<string, IUser>>({});
    const [teamsLoading, setTeamsLoading] = useState(false);

    const DB = useDatabase();

    const [snackbarError, setSnackbarError] = useState("");

    const handleOrganizationRequests = (result: DBResult<Record<string, IPublicationApplication>>) => {
        if (result.status === "fail") return setSnackbarError(result.data);
        setOrganizationRequests(result.data);
    };

    useEffect(() => {
        DB.getOrganizationRequests(handleOrganizationRequests);

        DB.getTeamList().then(t => {
            if (t.status === "fail") return setSnackbarError(t.data);
            setTeams(t.data);

            const userIDs = new Set<string>();
            for (const id in t.data) {
                userIDs.add(t.data[id].createdBy);
                for (const admin of t.data[id].administrators) {
                    userIDs.add(admin);
                }
            }

            Promise.all([...userIDs].map(l => DB.getUserInfo(l))).then(u => {
                const obj = {};
                for (const user of u) {
                    if (user.status === "fail") continue;
                    obj[user.data.id] = user.data;
                }
                setUsers(obj);
                setTeamsLoading(false);
            });
        });

        return () => DB.stopListeningOrganizationRequests();
    }, []);

    const acceptRequest = async () => {
        if (!acceptingRequest) return;
        const req = organizationRequests[acceptingRequest];
        if (!req) return;
        const orgInfo = await DB.getOrganization(req.orgId);
        if (orgInfo.status === "fail") return setSnackbarError(orgInfo.data);
        const creator = await DB.getUserInfo(orgInfo.data.createdBy);
        if (creator.status === "fail") return setSnackbarError(creator.data);
        setAcceptingRequest(null);
        DB.acceptOrganizationPublication(acceptingRequest, req.orgId, req.orgName, creator.data.email, req.userId, req.takeover);
    };

    const rejectRequest = async () => {
        if (!rejectingRequest) return;
        const req = organizationRequests[rejectingRequest];
        if (!req) return;
        const orgInfo = await DB.getOrganization(req.orgId);
        if (orgInfo.status === "fail") return setSnackbarError(orgInfo.data);
        const creator = await DB.getUserInfo(orgInfo.data.createdBy);
        if (creator.status === "fail") return setSnackbarError(creator.data);
        setRejectingRequest(null);
        DB.rejectOrganizationPublication(
            rejectingRequest,
            req.orgName,
            creator.data.email,
            req.orgId,
            req.takeover,
            rejectionReason || undefined
        );
    };

    const publications = Object.values(organizationRequests).filter(l => !l.takeover);
    const takeovers = Object.values(organizationRequests).filter(l => l.takeover);

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Typography
                variant="h3"
                sx={{
                    fontFamily: "Lexend Deca",
                    fontWeight: 400,
                    marginBottom: "30px"
                }}
            >
                Superadmin Page
            </Typography>
            <TbTTabView>
                <TbTTab title="Publication Requests">
                    <div className="publicationRequests">
                        {publications.map(l => (
                            <PublicationRequest
                                key={`pubR${l.id}`}
                                data={l}
                                setAcceptingRequest={setAcceptingRequest}
                                setRejectingRequest={setRejectingRequest}
                            />
                        ))}
                        {publications.length === 0 ? (
                            <Typography
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    color: "#999",
                                    marginTop: "50px",
                                    fontSize: 24
                                }}
                            >
                                No outstanding publication requests.
                            </Typography>
                        ) : null}
                    </div>
                </TbTTab>
                <TbTTab title="Takeover Requests">
                    <div className="publicationRequests">
                        {takeovers.map(l => (
                            <PublicationRequest
                                key={`pubT${l.id}`}
                                data={l}
                                setAcceptingRequest={setAcceptingRequest}
                                setRejectingRequest={setRejectingRequest}
                            />
                        ))}
                        {takeovers.length === 0 ? (
                            <Typography
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    color: "#999",
                                    marginTop: "50px",
                                    fontSize: 24
                                }}
                            >
                                No outstanding takeover requests.
                            </Typography>
                        ) : null}
                    </div>
                </TbTTab>
                <TbTTab title="Team List">
                    <div className="publicationRequests">
                        <TeamList teams={teams} users={users} loading={teamsLoading} />
                    </div>
                </TbTTab>
                <TbTTab title="Fencer Record Fixer">
                    <div className="publicationRequests">
                        <FencerRecordFixer />
                    </div>
                </TbTTab>
            </TbTTabView>
            <div style={{ height: "1px" }}></div>
            <Dialog open={Boolean(acceptingRequest)} onClose={() => setAcceptingRequest(null)}>
                <DialogTitle>
                    Accept {organizationRequests[acceptingRequest!]?.takeover ? "takeover" : "publication"} request for{" "}
                    {organizationRequests[acceptingRequest!]?.orgName}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This school will now be public. You can unpublish them at any time by going to their school page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAcceptingRequest(null)}>Cancel</Button>
                    <Button variant="contained" style={{ marginRight: 10 }} onClick={acceptRequest}>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(rejectingRequest)} onClose={() => setRejectingRequest(null)}>
                <DialogTitle>
                    Reject {organizationRequests[rejectingRequest!]?.takeover ? "takeover" : "publication"} request for{" "}
                    {organizationRequests[rejectingRequest!]?.orgName}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>You can provide a reason to send to the manager of the school.</DialogContentText>
                    <TextField
                        variant="standard"
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        onChange={e => setRejectionReason(e.target.value)}
                        placeholder="Rejection reason"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRejectingRequest(null)}>Cancel</Button>
                    <Button variant="contained" style={{ marginRight: 10 }} onClick={rejectRequest}>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
}
