import { ListItem, Typography, Box, Skeleton, Link, List, Accordion, AccordionSummary, AccordionDetails , Button} from "@mui/material";
import { IDualMeetBout, BoutSide, IDualMeet_DB } from "../../types";
import { boutWinner } from "../../utils/helpers";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import "../../pages/highschool/FencerInfo/FencerInfo.css"
import { useState, useEffect } from "react";

export interface BoutInListProps {
    id: string;
    bout: IDualMeetBout;
}

export const BoutInList = ({ id, bout }: BoutInListProps) => {
    const fencerSide = bout.fencer1.fencerInfo.id === id ? BoutSide.Fencer1 : BoutSide.Fencer2;
    const winner = boutWinner(bout, false);
    const weWon = winner === null ? null : winner === fencerSide;

    const leftScore = bout.fencer1.score;
    const rightScore = bout.fencer2.score;

    const leftFencer = bout.fencer1;
    const rightFencer = bout.fencer2;

    return (
        <ListItem>
            <Typography color={weWon === null ? "gray" : weWon ? "green" : "red"} fontWeight="bold" fontSize={24}>
                {weWon === null ? "—" : weWon ? "W" : "L"}
            </Typography>
            <Box style={{ margin: "0 20px", display: "flex" }}>
                <Typography fontWeight={fencerSide === BoutSide.Fencer1 ? 700 : 400} fontSize={24}>
                    {leftScore}
                </Typography>
                <Typography style={{ margin: "0 10px" }} fontSize={24}>
                    {" - "}
                </Typography>
                <Typography fontWeight={fencerSide === BoutSide.Fencer2 ? 700 : 400} fontSize={24}>
                    {rightScore}
                </Typography>
            </Box>
            <Typography fontSize={24}>
                {leftFencer.fencerInfo.firstName} {leftFencer.fencerInfo.lastName} vs. {rightFencer.fencerInfo.firstName}{" "}
                {rightFencer.fencerInfo.lastName}
            </Typography>
        </ListItem>
    );
};

export interface MeetSectionProps {
    id: string;
    data: IDualMeet_DB;
    bouts: IDualMeetBout[];
    onclick: () => void;
    expand: boolean
}

export const MeetSection = ({ id, data, bouts, onclick, expand}: MeetSectionProps) => {
    if (!data || !bouts) {
        return <div></div>;
    }
    let meetDate;

    try {
        meetDate = new Date(+data.startedAt).toDateString()
    }

    catch {
        meetDate = "Date could not be retrieved."
    }
    // onChange = {onClick}
    return (
        <Accordion
            className="fencerInfoMeetSection"
            aria-controls={`meet${id}-content`}
            id={`meet${id}header`}
            sx={{ margin: 0 }}
            defaultExpanded={false}
            onChange={onclick}
            expanded={expand}
        >
            <AccordionSummary expandIcon={<Icon path={mdiChevronDown} size="20px" />}>
                <Typography sx={{ padding: "5px" }} fontFamily="Lexend Deca" variant="h5" textAlign="left">
                    {data.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>

                <Link href={`/meet/${data.id}`} target="_blank" sx={{ display: "inline-block", float: "left" }}>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            textAlign: "left",
                            margin: "0 0 0 10px"
                        }}
                    >
                        View meet details
                    </Typography>

                </Link>
                <Box>
                    {bouts.map((bout, i) => (
                        <BoutInList key={`boutInList${i}`} id={id} bout={bout} />
                    ))}
                </Box>
                <Typography sx={{ padding: "5px", fontSize:"20px"}} fontFamily="Lexend Deca" variant="h5" textAlign="right">
                    {meetDate}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};


export interface fencerInfoBox {
    meets: IDualMeet_DB[];
    meetBoutRec: Record<string, IDualMeetBout[]>;
    id: string
}

export const FencerInfoBoxHighSchool = ({ meets, meetBoutRec, id}: fencerInfoBox) => {
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const accordionClicked = (index) => {
        if (expandedAccordions.includes(index))
        setExpandedAccordions(
            expandedAccordions.filter((number) => number !== index)
        );
        else setExpandedAccordions([...expandedAccordions, index]);

    };

    const collapseAll = () => {
        setExpandedAccordions([]);
    };

    const expandAll = () => {
        const newArray = [];
        for (const i in meets) {
            newArray.push(parseInt(i));
        }
        setExpandedAccordions(newArray);
    };


    if (!meets || !meetBoutRec) {
        return <div></div>;
    }

    // expandedAccordions.includes(i)

    useEffect(() => {
        expandAll();
    }, [meets, meetBoutRec]);

    return (
            <Box className="fencerInfoBox">

                 <div style={{marginLeft: "70%", marginRight: "10%", marginBottom: "15px"}}>
                    { (expandedAccordions.length !== meets.length) ? <Button variant="contained" onClick={expandAll}>
                            Expand All
                        </Button>

                        :

                        <Button variant="contained" onClick={collapseAll}>
                            Collapse All
                        </Button>
                    }
                </div>

                {meets.map((l, i) => (
                    <MeetSection key={i} id={id} data={l} bouts={meetBoutRec[l.id]} expand={expandedAccordions.includes(i)}
                    onclick={() => accordionClicked(i)} />
                ))}
            </Box>
    );
};
