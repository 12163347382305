import { useParams, useHistory } from "react-router-dom";
import TbTPage from "../../../components/TbTPage/TbTPage";
import useDatabase from "../../../hooks/database";
import { useEffect, useState } from "react";
import {
    BoutSide,
    DualMeetType,
    ICollegeEvent,
    ICollegeEventRound,
    ICollegeEventRoundMeet,
    IDualMeet,
    IDualMeet_DB,
    UserFlag,
    Weapon
} from "../../../types";
import { CommonLoading } from "../../../components/Loading/Loading";
import {
    Alert,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Modal,
    Paper,
    Snackbar,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    Typography
} from "@mui/material";
import TeamCard from "../../../components/TeamCard";
import React from "react";
import ErrorPage from "../NotFound/NotFound";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { modalBoxStyle } from "../../..";
import { exportCollegeEvent } from "../../../utils/pdfExport";
import { GenTournamentOrder, getTeamIDsFromEvent } from "../../../utils/helpers";
import { mdiChevronLeft, mdiSwapHorizontal, mdiChevronUp, mdiChevronDown, mdiChevronRight, mdiDeleteOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { DBResult, isSuccess } from "../../../utils/database";
import { ERROR_EVENT_DOES_NOT_EXIST } from "../../../utils/constants";
import SnackbarError from "../../../components/SnackbarError";

const CreateMeetCard = ({ startCreatingMeet }: { startCreatingMeet: () => void }) => {
    return (
        <Card style={{ margin: "5px 10px 5px 0", width: 160 }} variant="outlined">
            <CardActionArea onClick={startCreatingMeet} sx={{ textDecoration: "none !important", height: "100%" }}>
                <Box
                    sx={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    <Typography style={{ textAlign: "center", flex: 1, color: "#666" }}>Add meet</Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
};

interface SmallTeam {
    id: string;
    name: string;
    region: string;
}

interface AddMeetModalProps {
    rounds: ICollegeEventRound[];
    teams: SmallTeam[];
    createMeet: (val: [SmallTeam, SmallTeam]) => void;
}

const AddMeetModal = ({ rounds, teams, createMeet }: AddMeetModalProps) => {
    const [availableMeets, setAvailableMeets] = useState<[SmallTeam, SmallTeam][]>([]);

    const [selectedMeet, setSelectedMeet] = useState<[SmallTeam, SmallTeam] | null>(null);
    const [filter, setFilter] = useState("");
    const [switched, setSwitched] = useState(false);

    useEffect(() => {
        const possibleMeets = GenTournamentOrder(teams.length, Math.ceil(teams.length / 2), [])
            .flat()
            .map(l => [teams[l[0] - 1], teams[l[1] - 1]]);

        const meets = rounds.flatMap(l => l.meets);
        const res = possibleMeets.filter(
            l => !meets.some(j => (j.idA === l[0].id && j.idB === l[1].id) || (j.idA === l[1].id && j.idB === l[0].id))
        ) as [SmallTeam, SmallTeam][];

        setAvailableMeets(res);
    }, [rounds, teams]);

    const create = () => {
        if (selectedMeet) {
            createMeet(selectedMeet);
        }
    };

    const switchSides = () => {
        setSwitched(u => !u);
        if (selectedMeet) {
            setSelectedMeet(arr => (arr ? [arr[1], arr[0]] : null));
        }
    };

    return (
        <Box sx={modalBoxStyle}>
            <Typography variant="h4" fontFamily="Lexend Deca">
                Add meet to round
            </Typography>
            <Typography variant="body1" sx={{ margin: "10px 0" }}>
                Choose the meet to add to this round. Note that a team cannot fence twice in one round.
            </Typography>
            <TextField
                label="Search"
                placeholder="Enter a query..."
                variant="outlined"
                sx={{ marginRight: "10px" }}
                value={filter}
                onChange={e => setFilter(e.target.value)}
            />
            <br />
            {availableMeets
                .filter(l => `${l[0].name} vs. ${l[1].name}`.toLowerCase().includes(filter.toLowerCase()))
                .map(l => (
                    <ToggleButton
                        key={`${l[0].id}|${l[1].id}`}
                        value={`${l[0].id}|${l[1].id}`}
                        sx={{ width: 200, margin: "10px" }}
                        selected={l[0].id === selectedMeet?.[0]?.id && l[1].id === selectedMeet?.[1]?.id}
                        onChange={() => setSelectedMeet(l)}
                    >
                        <Typography>
                            {l[0].name} vs. {l[1].name}
                        </Typography>
                    </ToggleButton>
                ))}
            <Box
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "5px"
                }}
            >
                {selectedMeet ? (
                    <Typography>
                        Creating <span style={{ fontWeight: 600 }}>{selectedMeet[0].name}</span> vs.{" "}
                        <span style={{ fontWeight: 600 }}>{selectedMeet[1].name}</span>
                    </Typography>
                ) : null}
                <Stack direction="row" spacing={0} alignItems="center" justifyContent="center" sx={{ marginTop: "5px", flexShrink: 0 }}>
                    <Typography>Switch sides</Typography>
                    <Switch onChange={() => switchSides()} />
                </Stack>
                <Button variant="contained" disabled={!selectedMeet} sx={{ padding: "10px 30px", marginTop: "5px" }} onClick={create}>
                    Create
                </Button>
            </Box>
        </Box>
    );
};

const MeetInfo = ({ data, dbData }: { data: ICollegeEventRound["meets"]["0"]; dbData?: IDualMeet_DB }) => {
    const nameA = (data.abbA || data.nameA).replace(" Men's", "").replace(" Women's", "");
    const nameB = (data.abbB || data.nameB).replace(" Men's", "").replace(" Women's", "");

    const score1 = dbData?.bouts?.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer1), 0);
    const score2 = dbData?.bouts?.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer2), 0);

    return (
        <Card style={{ margin: "5px 10px 5px 0", width: 160 }} variant="outlined">
            <CardActionArea href={`/meet/${data.id}`} sx={{ textDecoration: "none !important", height: "100%" }}>
                <Box
                    sx={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {nameA} {score1 !== undefined ? `(${score1})` : null}
                    </Typography>
                    <Typography
                        style={{
                            textAlign: "center",
                            width: "25px",
                            color: "#777"
                        }}
                    >
                        vs.
                    </Typography>
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {nameB} {score2 !== undefined ? `(${score2})` : null}
                    </Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
};

type MeetInRoundFunc = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => void;

interface MeetInRoundProps {
    error: boolean;
    gender: "mens" | "womens";
    meet: ICollegeEventRoundMeet;
    dbMeet: IDualMeet_DB;
    moveMeetLeft: MeetInRoundFunc;
    moveMeetRight: MeetInRoundFunc;
    moveMeetUp: MeetInRoundFunc;
    moveMeetDown: MeetInRoundFunc;
    switchSides: MeetInRoundFunc;
    deleteMeet: MeetInRoundFunc;
}

const MeetInRound = ({
    error,
    gender,
    meet,
    moveMeetLeft,
    moveMeetRight,
    moveMeetUp,
    moveMeetDown,
    switchSides,
    deleteMeet
}: MeetInRoundProps) => {
    return (
        <Paper
            className="meetInRound"
            style={{ width: 160, borderColor: error ? "red" : undefined }}
            variant="outlined"
            key={`meet${meet.idA}${meet.idB}`}
        >
            <IconButton onClick={() => moveMeetLeft(gender, meet)} size="small">
                <Icon path={mdiChevronLeft} size={0.5} horizontal vertical rotate={180} />
            </IconButton>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <IconButton onClick={() => moveMeetUp(gender, meet)} size="small">
                    <Icon path={mdiChevronUp} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: 115
                    }}
                >
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {(meet.abbA || meet.nameA).replaceAll(/ Men('|’)s/g, "").replaceAll(" Women's", "")}
                    </Typography>
                    <Typography
                        style={{
                            textAlign: "center",
                            width: "20px",
                            color: "#777"
                        }}
                    >
                        vs.
                    </Typography>
                    <Typography style={{ textAlign: "center", flex: 1 }}>
                        {(meet.abbB || meet.nameB).replaceAll(/ Men('|’)s/g, "").replaceAll(" Women's", "")}
                    </Typography>
                </Box>
                <IconButton onClick={() => moveMeetDown(gender, meet)} size="small">
                    <Icon path={mdiChevronDown} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
            </Box>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <IconButton onClick={() => switchSides(gender, meet)} size="small">
                    <Icon path={mdiSwapHorizontal} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
                <IconButton onClick={() => moveMeetRight(gender, meet)} size="small">
                    <Icon path={mdiChevronRight} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
                <IconButton onClick={() => deleteMeet(gender, meet)} size="small">
                    <Icon path={mdiDeleteOutline} size={0.5} horizontal vertical rotate={180} />
                </IconButton>
            </Box>
        </Paper>
    );
};

const TeamInfo = ({ data }: { data: ICollegeEventRound["byes"]["0"] }) => {
    const name = (data.abb || data.name).replace(" Men's", "").replace(" Women's", "");

    if (data.id.startsWith("writeIn")) {
        return (
            <Card style={{ margin: "5px 10px 5px 0" }} variant="outlined">
                <Box
                    sx={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography style={{ textAlign: "center" }}>{name}</Typography>
                </Box>
            </Card>
        );
    }

    return (
        <Card style={{ margin: "5px 10px 5px 0" }} variant="outlined">
            <CardActionArea href={`/team/${data.id}`} sx={{ textDecoration: "none !important" }}>
                <Box
                    sx={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography style={{ textAlign: "center" }}>{name}</Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
};

enum SwitchSidesDialogType {
    ScoresEntered,
    LineupsEntered,
    Permitted,
    Closed
}

const EventInfo = () => {
    const { id } = useParams<{ id: string }>();

    const DB = useDatabase();
    const history = useHistory();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [loading, setLoading] = useState(true);
    const [gender, setGender] = useState(0);
    const [data, setData] = useState<ICollegeEvent | null>(null);
    const [mensTeams, setMensTeams] = useState<{ id: string; name: string; region: string }[]>([]);
    const [womensTeams, setWomensTeams] = useState<{ id: string; name: string; region: string }[]>([]);
    const [administratorOfTeam, setAdministratorOfTeam] = useState(false);

    const [meets, setMeets] = useState<Record<string, IDualMeet_DB>>({});

    const [exportingSabreMens, setExportingSabreMens] = useState(false);
    const [exportingSabreWomens, setExportingSabreWomens] = useState(false);
    const [exportingFoilMens, setExportingFoilMens] = useState(false);
    const [exportingFoilWomens, setExportingFoilWomens] = useState(false);
    const [exportingEpeeMens, setExportingEpeeMens] = useState(false);
    const [exportingEpeeWomens, setExportingEpeeWomens] = useState(false);
    const [exportingAllMens, setExportingAllMens] = useState(false);
    const [exportingAllWomens, setExportingAllWomens] = useState(false);
    const [exportingSabreMensProgress, setExportingSabreMensProgress] = useState(0);
    const [exportingSabreWomensProgress, setExportingSabreWomensProgress] = useState(0);
    const [exportingFoilMensProgress, setExportingFoilMensProgress] = useState(0);
    const [exportingFoilWomensProgress, setExportingFoilWomensProgress] = useState(0);
    const [exportingEpeeMensProgress, setExportingEpeeMensProgress] = useState(0);
    const [exportingEpeeWomensProgress, setExportingEpeeWomensProgress] = useState(0);
    const [exportingAllMensProgress, setExportingAllMensProgress] = useState(0);
    const [exportingAllWomensProgress, setExportingAllWomensProgress] = useState(0);
    const [exportOpen, setExportOpen] = useState(false);

    const [editingEvent, setEditingEvent] = useState(false);
    const [deletingEvent, setDeletingEvent] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [deletingMeet, setDeletingMeet] = useState<ICollegeEventRoundMeet | null>(null);
    const [switchingSides, setSwitchingSides] = useState(SwitchSidesDialogType.Closed);
    const [switchingSidesMeet, setSwitchingSidesMeet] = useState<ICollegeEventRoundMeet | null>(null);
    const [switchInProgress, setSwitchInProgress] = useState(false);

    const [creatingMeet, setCreatingMeet] = useState(false);
    const [creatingMeetRound, setCreatingMeetRound] = useState(0);

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");
    const [modifyError, setModifyError] = useState("");

    const isSiteAdmin = (userInfo?.flags || 0) & UserFlag.MeetManager;

    const startCreatingMeet = (round: number) => {
        setCreatingMeet(true);
        setCreatingMeetRound(round);
    };

    const createMeet = async (teams: [SmallTeam, SmallTeam]) => {
        if (!data) return;

        const meet = await DB.createNewDualMeet(
            teams[0].id,
            teams[1].id,
            DualMeetType.Varsity,
            data.season,
            data.startedAt,
            undefined,
            undefined,
            data.id
        );
        if (meet.status === "fail") setModifyError(meet.data);
        await DB.addMeetToCollegeEvent(data.id, meet.data, gender ? "womens" : "mens", creatingMeetRound);

        setCreatingMeet(false);
        setCreatingMeetRound(0);
    };

    const deleteMeet = async (meet: ICollegeEventRoundMeet) => {
        DB.deleteDualMeet(meet.id);
    };

    const switchSides = (meet: ICollegeEventRoundMeet) => {
        DB.switchSidesOfEventMeet(id, meet).then(() => {
            setSwitchingSides(SwitchSidesDialogType.Closed);
            setSwitchInProgress(false);
            setSwitchingSidesMeet(null);
        });
    };

    const exportYk = async (gender: "boys" | "girls", weapon: Weapon | "All") => {
        if (!data) return;

        const setters: Record<"boys" | "girls", Record<Weapon | "All", React.Dispatch<React.SetStateAction<boolean>>>> = {
            boys: {
                Sabre: setExportingSabreMens,
                Foil: setExportingFoilMens,
                Epee: setExportingEpeeMens,
                All: setExportingAllMens
            },
            girls: {
                Sabre: setExportingSabreWomens,
                Foil: setExportingFoilWomens,
                Epee: setExportingEpeeWomens,
                All: setExportingAllWomens
            }
        };

        const progressUpdates: Record<"boys" | "girls", Record<Weapon | "All", React.Dispatch<React.SetStateAction<number>>>> = {
            boys: {
                Sabre: setExportingSabreMensProgress,
                Foil: setExportingFoilMensProgress,
                Epee: setExportingEpeeMensProgress,
                All: setExportingAllMensProgress
            },
            girls: {
                Sabre: setExportingSabreWomensProgress,
                Foil: setExportingFoilWomensProgress,
                Epee: setExportingEpeeWomensProgress,
                All: setExportingAllWomensProgress
            }
        };

        setters[gender][weapon](true);

        const pdf = await exportCollegeEvent(DB, data, gender, weapon, progressUpdates[gender][weapon]);
        if (pdf.status === "fail") return setSnackbarError(pdf.data);
        const pdfArray = await pdf.data.save();

        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const link = window.URL.createObjectURL(blob);
        window.open(link);

        setters[gender][weapon](false);
    };

    const publishEvent = () => {
        DB.publishCollegeEvent(id);
    };

    const deleteEvent = () => {
        setDeleteInProgress(true);
        DB.deleteCollegeEvent(data?.id || id).then(() => {
            setDeleteInProgress(false);
            history.push(`/events`);
        });
    };

    const checkAdministratorOfTeam = async (event: ICollegeEvent) => {
        const userInfo = await DB.getCurrentUserInfo();
        if (userInfo.status === "fail") return setAdministratorOfTeam(false);
        const linkedFencers = await Promise.all(userInfo.data.linkedFencerIds.map(l => DB.getFencer(l)));
        const linkedTeams = linkedFencers.filter(isSuccess).flatMap(l => l.data.teams);
        const userTeams = [...userInfo.data.teams, ...linkedTeams];

        const teams = getTeamIDsFromEvent(event);
        setAdministratorOfTeam(userTeams.some(l => teams.includes(l)) || false);
    };

    const handleData = (dbResult: DBResult<ICollegeEvent>) => {
        if (dbResult.status === "fail") {
            setCriticalError(dbResult.data);
            return;
        }
        const { data } = dbResult;
        setData(data);

        checkAdministratorOfTeam(data);

        data.mensRounds = data.mensRounds.map(l => ({
            // @ts-ignore
            byes: [],
            // @ts-ignore
            meets: [],
            ...l
        }));
        // @ts-ignore
        data.womensRounds = data.womensRounds.map(l => ({
            // @ts-ignore
            byes: [],
            // @ts-ignore
            meets: [],
            ...l
        }));

        {
            const teams: { id: string; name: string; region: string }[] = [];
            for (const round of data.mensRounds) {
                for (const meet of round.meets) {
                    if (!teams.some(l => l.id === meet.idA))
                        teams.push({
                            id: meet.idA,
                            name: meet.nameA,
                            region: meet.regionA
                        });
                    if (!teams.some(l => l.id === meet.idB))
                        teams.push({
                            id: meet.idB,
                            name: meet.nameB,
                            region: meet.regionB
                        });
                }
                for (const bye of round.byes) {
                    if (!teams.some(l => l.id === bye.id))
                        teams.push({
                            id: bye.id,
                            name: bye.name,
                            region: bye.region
                        });
                }
            }
            setMensTeams(teams);
        }

        {
            const teams: { id: string; name: string; region: string }[] = [];
            for (const round of data.womensRounds) {
                for (const meet of round.meets) {
                    if (!teams.some(l => l.id === meet.idA))
                        teams.push({
                            id: meet.idA,
                            name: meet.nameA,
                            region: meet.regionA
                        });
                    if (!teams.some(l => l.id === meet.idB))
                        teams.push({
                            id: meet.idB,
                            name: meet.nameB,
                            region: meet.regionB
                        });
                }
                for (const bye of round.byes) {
                    if (!teams.some(l => l.id === bye.id))
                        teams.push({
                            id: bye.id,
                            name: bye.name,
                            region: bye.region
                        });
                }
            }
            setWomensTeams(teams);
        }
    };

    const meetListener = (meet: DBResult<IDualMeet_DB>) => {
        if (meet.status === "fail") return setSnackbarError(meet.data);
        setMeets(u => ({ ...u, [meet.data.id]: meet.data }));
    };

    useEffect(() => {
        DB.getCollegeEvent(id, handleData).then(event => {
            if (event.status === "fail") return setCriticalError(event.data);
            const { data } = event;

            const meets = [
                ...data.mensRounds.flatMap(l => l.meets.map(j => j.id)),
                ...data.womensRounds.flatMap(l => l.meets.map(j => j.id))
            ];

            for (const meet of meets) {
                DB.getDualMeetRaw(meet, meetListener);
            }
        });

        return () => {
            DB.stopListeningCollegeEvent(id, handleData);

            if (data) {
                const meets = [
                    ...data.mensRounds.flatMap(l => l.meets.map(j => j.id)),
                    ...data.womensRounds.flatMap(l => l.meets.map(j => j.id))
                ];

                for (const meet of meets) {
                    DB.stopListeningDualMeet(meet, meetListener);
                }
            }
        };
    }, [id]);

    useEffect(() => {
        if (data) setLoading(false);
    }, [data]);

    if (criticalError === ERROR_EVENT_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested event could not be found." />;
    } else if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    if (loading || !data) {
        return (
            <TbTPage>
                <CommonLoading color="#714FCA" size="large" />
            </TbTPage>
        );
    }

    const moveMeetUp = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        DB.moveCollegeEventMeetUp(data.id, gender, meet);
    };

    const moveMeetDown = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        DB.moveCollegeEventMeetDown(data.id, gender, meet);
    };

    const moveMeetLeft = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        DB.moveCollegeEventMeetLeft(data.id, gender, meet);
    };

    const moveMeetRight = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        DB.moveCollegeEventMeetRight(data.id, gender, meet);
    };

    const trySwitchSides = async (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        setSwitchingSidesMeet(meet);

        const meetInfo = await DB.getDualMeetRaw(meet.id);
        if (meetInfo.status === "fail") return setSnackbarError(meetInfo.data);
        const boutResults = await Promise.all(meetInfo.data.bouts.map(l => DB.getBout(l.id)));
        const bouts = boutResults.filter(isSuccess).map(l => l.data);

        if (bouts.some(l => l.fencer1.score || l.fencer2.score)) {
            setSwitchingSides(SwitchSidesDialogType.ScoresEntered);
        } else if (bouts.some(l => l.fencer1.fencerInfo.lastName !== "fencer" || l.fencer2.fencerInfo.lastName !== "fencer")) {
            setSwitchingSides(SwitchSidesDialogType.LineupsEntered);
        } else {
            setSwitchingSides(SwitchSidesDialogType.Permitted);
        }
    };

    const tryDeleteMeet = (gender: "mens" | "womens", meet: ICollegeEventRoundMeet) => {
        setDeletingMeet(meet);
    };

    const activeRounds = gender ? data.womensRounds : data.mensRounds;
    const byesPresent = activeRounds.some(l => l.byes.length > 0);
    const podCount = Math.max(...activeRounds.map(l => l.meets.length));
    const meetCountMens = data.mensRounds.reduce((acc, cur) => acc + cur.meets.length, 0);
    const meetCountWomens = data.womensRounds.reduce((acc, cur) => acc + cur.meets.length, 0);

    mensTeams.sort((a, b) => a.name.localeCompare(b.name));
    womensTeams.sort((a, b) => a.name.localeCompare(b.name));

    const canEditEvent = Boolean(isSiteAdmin) || (data?.createdBy && data?.createdBy === userInfo?.id);

    const closeSwitchingSides = () => {
        setSwitchingSidesMeet(null);
        setSwitchingSides(SwitchSidesDialogType.Closed);
    };

    return (
        <TbTPage className="dualMeetInfoPage">
            <Card
                sx={{
                    maxWidth: 1200,
                    width: "90%",
                    margin: "30px auto 20px auto"
                }}
            >
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                    <Typography component="h1" variant="h4" color="inherit" gutterBottom>
                        {data.name}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        {data.startedAt ? new Date(data.startedAt).toDateString() : "No start date"}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        {data.location}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        {data.address}
                    </Typography>
                    {!data.published && (isSiteAdmin || administratorOfTeam) ? (
                        <Button variant="contained" color="success" onClick={publishEvent} style={{ marginRight: 10 }}>
                            Publish
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button variant="contained" sx={{ marginRight: "10px" }} onClick={() => setExportOpen(true)}>
                        Export Event
                    </Button>
                    {isSiteAdmin ? (
                        <Button variant="outlined" disabled={deleteInProgress} onClick={() => setDeletingEvent(true)}>
                            {deleteInProgress ? "Deleting..." : "Delete Event"}
                        </Button>
                    ) : null}
                </CardContent>
            </Card>

            <Box
                sx={{
                    marginBottom: "10px",
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <Typography fontFamily="Lexend Deca" variant="h4">
                    Schedules
                </Typography>
                {canEditEvent ? (
                    <Button
                        variant={editingEvent ? "contained" : "outlined"}
                        sx={{ marginLeft: "10px" }}
                        onClick={() => setEditingEvent(u => !u)}
                    >
                        {!editingEvent ? "Edit" : "Stop editing"}
                    </Button>
                ) : null}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0 auto",
                    maxWidth: "1200px",
                    width: "90%"
                }}
            >
                <Tabs value={gender} onChange={(e, value) => setGender(value)}>
                    <Tab label="Men's" />
                    <Tab label="Women's" />
                </Tabs>
                <Paper sx={{ width: "100%", overflowX: "auto" }}>
                    {activeRounds.length === 0 ? (
                        <Box
                            sx={{
                                height: 400,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ color: "#777" }}>
                                No meets for {gender === 1 ? "women's" : "men's"}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                padding: "20px 0 0 0"
                            }}
                        >
                            <Box sx={{ flexShrink: 0, width: 100 }}></Box>
                            {Array(podCount)
                                .fill(null)
                                .map((_, idx) => (
                                    <Box
                                        sx={{
                                            flexShrink: 0,
                                            width: 170,
                                            height: 28
                                        }}
                                        key={`podLabel${idx}`}
                                    >
                                        Meet {idx + 1}
                                    </Box>
                                ))}
                            <Box sx={{ flexGrow: 1 }}></Box>
                            {byesPresent ? (
                                <Box sx={{ flexShrink: 0, width: 200 }}>
                                    <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                                        Teams with Byes
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                    )}
                    {activeRounds.map((round, idx) => (
                        <Box
                            key={`activeRound${round.name}`}
                            style={{
                                minWidth: 100 + podCount * 170 + +byesPresent * 200
                            }}
                        >
                            {idx !== 0 && (
                                <Box
                                    style={{
                                        width: "100%",
                                        height: 20,
                                        backgroundColor: "#EEE",
                                        flexShrink: 0,
                                        flexGrow: 0
                                    }}
                                ></Box>
                            )}
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexShrink: 0,
                                    flexGrow: 0,
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "10px 0"
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        width: 100,
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        textAlign: "center"
                                    }}
                                >
                                    {round.name}
                                </Typography>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        flexShrink: 1,
                                        display: "flex",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    {round.meets.map(meet =>
                                        editingEvent ? (
                                            <MeetInRound
                                                meet={meet}
                                                dbMeet={meets[meet.id]}
                                                key={`editMeet${round.name}${meet.id}`}
                                                gender={gender === 1 ? "womens" : "mens"}
                                                error={false}
                                                moveMeetLeft={moveMeetLeft}
                                                moveMeetRight={moveMeetRight}
                                                moveMeetUp={moveMeetUp}
                                                moveMeetDown={moveMeetDown}
                                                switchSides={trySwitchSides}
                                                deleteMeet={tryDeleteMeet}
                                            />
                                        ) : (
                                            <MeetInfo data={meet} dbData={meets[meet.id]} key={`roundMeet${round.name}${meet.id}`} />
                                        )
                                    )}
                                    {!round.meets.length ? (
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                width: 160,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "#666"
                                            }}
                                        >
                                            No meets in round
                                        </Typography>
                                    ) : null}
                                    {editingEvent ? <CreateMeetCard startCreatingMeet={() => startCreatingMeet(idx)} /> : null}
                                </Box>
                                <Box
                                    sx={{
                                        width: 200,
                                        flexShrink: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    {(round.byes || []).map(team => (
                                        <TeamInfo data={team} key={`roundBye${round.name}${team.id}`} />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Paper>
            </Box>

            <Typography fontFamily="Lexend Deca" variant="h4" sx={{ marginBottom: "10px", marginTop: "70px" }}>
                Teams
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0 auto",
                    maxWidth: "1200px",
                    width: "90%"
                }}
            >
                <Box sx={{ flexShrink: 0, flexGrow: 0, width: "45%" }}>
                    <Typography fontFamily="Lexend Deca" variant="h6" sx={{ marginBottom: "10px" }}>
                        Men's
                    </Typography>
                    {!mensTeams.length && (
                        <Typography fontFamily="Lexend Deca" variant="body1" sx={{ color: "#777", fontSize: 18 }}>
                            No men's teams
                        </Typography>
                    )}
                    <Grid container spacing={2}>
                        {mensTeams.map(team => (
                            <TeamCard
                                id={team.id}
                                writeIn={
                                    team.id.startsWith("writeIn")
                                        ? {
                                              name: team.name,
                                              region: team.region
                                          }
                                        : undefined
                                }
                                key={`mensTeam${team.id}`}
                                host={team.name.includes(data.hostName)}
                                eventMode
                            />
                        ))}
                    </Grid>
                </Box>
                <Box sx={{ flexShrink: 0, flexGrow: 0, width: "45%" }}>
                    <Typography fontFamily="Lexend Deca" variant="h6" sx={{ marginBottom: "10px" }}>
                        Women's
                    </Typography>
                    <Grid container spacing={2}>
                        {womensTeams.map(team => (
                            <TeamCard
                                id={team.id}
                                writeIn={
                                    team.id.startsWith("writeIn")
                                        ? {
                                              name: team.name,
                                              region: team.region
                                          }
                                        : undefined
                                }
                                key={`womensTeam${team.id}`}
                                host={team.name.includes(data.hostName)}
                                eventMode
                            />
                        ))}
                    </Grid>
                </Box>
            </Box>

            <Dialog open={exportOpen} onClose={() => setExportOpen(false)}>
                <DialogTitle variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "10px" }}>
                    Export Event to PDF
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ marginRight: "10px", width: 260 }}>Export individual weapon - Men's</Typography>
                        <Button variant="contained" color="success" disabled={exportingSabreMens} onClick={() => exportYk("boys", "Sabre")}>
                            {exportingSabreMens ? `Exporting (${exportingSabreMensProgress}/${meetCountMens})` : "Sabre"}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={exportingFoilMens}
                            sx={{ margin: "0 10px" }}
                            onClick={() => exportYk("boys", "Foil")}
                        >
                            {exportingFoilMens ? `Exporting (${exportingFoilMensProgress}/${meetCountMens})` : "Foil"}
                        </Button>
                        <Button variant="contained" color="success" disabled={exportingEpeeMens} onClick={() => exportYk("boys", "Epee")}>
                            {exportingEpeeMens ? `Exporting (${exportingEpeeMensProgress}/${meetCountMens})` : "Epee"}
                        </Button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0"
                        }}
                    >
                        <Typography style={{ marginRight: "10px", width: 260 }}>Export individual weapon - Women's</Typography>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={exportingSabreWomens}
                            onClick={() => exportYk("girls", "Sabre")}
                        >
                            {exportingSabreWomens ? `Exporting (${exportingSabreWomensProgress}/${meetCountWomens})` : "Sabre"}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={exportingFoilWomens}
                            sx={{ margin: "0 10px" }}
                            onClick={() => exportYk("girls", "Foil")}
                        >
                            {exportingFoilWomens ? `Exporting (${exportingFoilWomensProgress}/${meetCountWomens})` : "Foil"}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={exportingEpeeWomens}
                            onClick={() => exportYk("girls", "Epee")}
                        >
                            {exportingEpeeWomens ? `Exporting (${exportingEpeeWomensProgress}/${meetCountWomens})` : "Epee"}
                        </Button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ marginRight: "10px" }}>Export all weapons</Typography>
                        <Button variant="contained" color="success" disabled={exportingAllMens} onClick={() => exportYk("boys", "All")}>
                            {exportingAllMens ? `Exporting (${exportingAllMensProgress}/${meetCountMens})` : "Men's"}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={exportingAllWomens}
                            sx={{ margin: "0 10px" }}
                            onClick={() => exportYk("girls", "All")}
                        >
                            {exportingAllWomens ? `Exporting (${exportingAllWomensProgress}/${meetCountWomens})` : "Women's"}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Modal open={creatingMeet} onClose={() => setCreatingMeet(false)}>
                <AddMeetModal rounds={activeRounds} teams={gender === 0 ? mensTeams : womensTeams} createMeet={createMeet} />
            </Modal>

            <Dialog open={deletingEvent} onClose={() => setDeletingEvent(false)}>
                <DialogTitle>Delete Event</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this event? <strong>This cannot be undone!</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setDeletingEvent(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            deleteEvent();
                            setDeletingEvent(false);
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={Boolean(deletingMeet)} onClose={() => setDeletingMeet(null)}>
                <DialogTitle>Delete Meet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {deletingMeet?.nameA} vs. {deletingMeet?.nameB}?{" "}
                        <strong>This cannot be undone!</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginRight: "15px", marginBottom: "10px" }}>
                    <Button
                        sx={{ marginRight: "10px" }}
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setDeletingMeet(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            deleteMeet(deletingMeet!);
                            setDeletingMeet(null);
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={switchingSides !== SwitchSidesDialogType.Closed && Boolean(switchingSidesMeet)} onClose={closeSwitchingSides}>
                <DialogTitle>Switch Sides of Meet</DialogTitle>
                <DialogContent>
                    {switchingSides === SwitchSidesDialogType.Permitted && (
                        <DialogContentText>
                            Are you sure you want to change the meet to be {switchingSidesMeet?.nameB} (A) vs. {switchingSidesMeet?.nameA}{" "}
                            (B)? <strong>This cannot be undone!</strong>
                        </DialogContentText>
                    )}
                    {switchingSides === SwitchSidesDialogType.LineupsEntered && (
                        <DialogContentText>
                            Are you sure you want to change the meet to be {switchingSidesMeet?.nameB} (A) vs. {switchingSidesMeet?.nameA}{" "}
                            (B)? <strong>You will lose all entered lineups!</strong>
                        </DialogContentText>
                    )}
                    {switchingSides === SwitchSidesDialogType.ScoresEntered && (
                        <DialogContentText>
                            As the meet {switchingSidesMeet?.nameA} vs. {switchingSidesMeet?.nameB} has begun scoring, it is no longer able
                            to have its sides switched.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions sx={{ marginBottom: "10px", marginRight: "10px" }}>
                    <Button sx={{ marginRight: "10px" }} variant="contained" color="success" onClick={closeSwitchingSides}>
                        {switchingSides === SwitchSidesDialogType.ScoresEntered ? "Okay" : "Cancel"}
                    </Button>
                    {switchingSides !== SwitchSidesDialogType.ScoresEntered && (
                        <Button
                            disabled={switchInProgress}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                switchSides(switchingSidesMeet!);
                                setSwitchInProgress(true);
                            }}
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
};

export default EventInfo;
