import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import {
    Button,
    Box,
    Modal,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { modalBoxStyle } from "../../..";
import BoutScorerComponent from "../../../components/BoutScorerComponent/BoutScorerComponent";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { useWindowSize } from "../../../hooks/window";
import { ICollegeEvent, IDualMeet, IDualMeetBout, IExistingFencer, ITeam, IWriteInFencer, UserFlag, Weapon } from "../../../types";
import { ReduxState } from "../../../utils/store";
import useDatabase from "../../../hooks/database";

import "./DualMeetScorer.css";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";
import OnlineIndicator from "../../../components/OnlineIndicator";
import { CommonLoading } from "../../../components/Loading/Loading";
import { DBResult, isSuccess } from "../../../utils/database";
import ErrorPage from "../NotFound/NotFound";

const overrideEnabled = false;

/**
 * Custom hook to work with query strings
 * @returns
 */
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function DualMeetScorer() {
    const { id } = useParams<{ id: string }>();
    const searchParams = useQuery();
    const size = useWindowSize();
    const DB = useDatabase();

    const [criticalError, setCriticalError] = useState("");

    const [notCurrentEditor, setNotCurrentEditor] = useState(false);
    const [notCurrentEditorPIN, setNotCurrentEditorPIN] = useState("");

    const boutKey = useSelector((s: ReduxState) => s.boutKey);

    const [dualMeetLoaded, setDualMeetLoaded] = useState(false);
    const [eventLoaded, setEventLoaded] = useState(false);

    const [dualMeetData, setDualMeetData] = useState<IDualMeet | null>(null);
    const [eventInfo, setEventInfo] = useState<ICollegeEvent | null>(null);
    const [boutsRaw, setBoutsRaw] = useState<IDualMeetBout[]>([]);
    const [currentBoutID, setCurrentBoutID] = useState<string | null>(searchParams.get("bout"));
    const lastBoutID = useRef<string | null>(null);

    const [timeoutStatusText, setTimeoutStatusText] = useState("");

    const [team1Info, setTeam1Info] = useState<ITeam | null>(null);
    const [team2Info, setTeam2Info] = useState<ITeam | null>(null);
    const [team1Fencers, setTeam1Fencers] = useState<Record<string, Map<IExistingFencer | IWriteInFencer, number>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });
    const [team2Fencers, setTeam2Fencers] = useState<Record<string, Map<IExistingFencer | IWriteInFencer, number>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });

    const currentBout = boutsRaw?.find(l => l.id === currentBoutID);

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const enteredPIN = useSelector((s: ReduxState) => s.enteredPIN);
    const enteredPINExpiration = useSelector((s: ReduxState) => s.enteredPINExpiration);

    const dispatch = useDispatch();

    const boutIdx = boutsRaw.findIndex(l => l.id === currentBoutID || 0) || -1;

    const currentBoutIDRef = useRef(currentBoutID);

    useEffect(() => {
        lastBoutID.current = currentBoutIDRef.current;
        currentBoutIDRef.current = currentBoutID;
    }, [currentBoutID]);

    useEffect(() => {
        if (!currentBout) return;

        if (currentBout?.currentlyEditingUser !== undefined && currentBout?.currentlyEditingUser !== boutKey && overrideEnabled) {
            setNotCurrentEditor(true);
        } else {
            setNotCurrentEditor(false);
            // DB.setCurrentBoutEditor(currentBout.id, boutKey);
        }

        if (lastBoutID.current !== currentBoutIDRef.current && lastBoutID.current !== null && overrideEnabled) {
            DB.setCurrentBoutEditor(lastBoutID.current, null);
        }
    }, [currentBout?.currentlyEditingUser, currentBout?.id]);

    useEffect(() => {
        const id = dualMeetData?.team1?.id;
        if (!id) return;
        DB.getTeam(id).then((team: DBResult<ITeam>) => {
            if (team.status === "fail") return setCriticalError(team.data);
            setTeam1Info(team.data);
        });
    }, [dualMeetData?.team1?.id]);

    const handleTeam2 = (team: DBResult<ITeam>) => {
        if (team.status === "fail") return setCriticalError(team.data);
        setTeam2Info(team.data);
    };

    useEffect(() => {
        const id = dualMeetData?.team2?.id;
        if (!id) return;
        DB.getTeam(id, { listener: handleTeam2 });

        return () => {
            DB.stopListeningTeam(id, handleTeam2);
        };
    }, [dualMeetData?.team2?.id]);

    const handleDualMeet = async (result: DBResult<IDualMeet>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        const { data } = result;
        setDualMeetData(data);
        setDualMeetLoaded(true);
        if (!data.eventID) {
            setEventLoaded(true);
        }
        if (!currentBoutIDRef.current) {
            setCurrentBoutID(data.bouts[0].id);
        }
        {
            const leRoster: Record<string, Map<IExistingFencer | IWriteInFencer, number>> = {
                Sabre: new Map(),
                Foil: new Map(),
                Epee: new Map()
            };
            for (const weapon in data.team1.fencers) {
                const weaponRoster = data.team1.fencers[weapon as Weapon];
                for (const fencerData of weaponRoster) {
                    leRoster[weapon].set(fencerData, fencerData.home);
                }
            }
            setTeam1Fencers(leRoster);
        }
        {
            const leRoster: Record<string, Map<IExistingFencer | IWriteInFencer, number>> = {
                Sabre: new Map(),
                Foil: new Map(),
                Epee: new Map()
            };
            for (const weapon in data.team2.fencers) {
                const weaponRoster = data.team2.fencers[weapon as Weapon];
                for (const fencerData of weaponRoster) {
                    leRoster[weapon].set(fencerData, fencerData.away);
                }
            }
            setTeam2Fencers(leRoster);
        }
    };

    useEffect(() => {
        // TODO: REFACTOR PLEASE
        DB.getDualMeet(id, handleDualMeet).then(meet => {
            if (meet.status === "fail") return setCriticalError(meet.data);
            Promise.all(
                meet.data.bouts.map((l, idx) => {
                    return DB.getBout(l.id, boutData => {
                        if (boutData.status === "fail") return;
                        setBoutsRaw(boutsRaw => {
                            const copy = [...boutsRaw];
                            copy.splice(idx, 1, boutData.data);
                            setBoutsRaw(copy);
                            return boutsRaw;
                        });
                    });
                })
            ).then(bouts => {
                setBoutsRaw(bouts.filter(isSuccess).map(l => l.data));
            });
        });
        return () => {
            DB.stopListeningDualMeet(id, handleDualMeet);
            for (const bout of dualMeetData?.bouts || []) {
                const matchingBout = boutsRaw.find(l => l.id === bout.id);
                if (matchingBout?.currentlyEditingUser === userInfo?.id && overrideEnabled) {
                    DB.updateBout(bout.id, { currentlyEditingUser: null });
                }
                DB.stopListeningBout(bout.id);
            }
        };
    }, []);

    useEffect(() => {
        if (!dualMeetData?.eventID) {
            return;
        }
        DB.getCollegeEvent(dualMeetData?.eventID).then(e => {
            if (e.status === "fail") return setCriticalError(e.data);
            setEventInfo(e.data);
            setEventLoaded(true);
        });
    }, [dualMeetData?.eventID]);

    useEffect(() => {
        if (!currentBout || !currentBoutID) return;

        // TS doesn't like window.location
        // @ts-ignore
        const url = new URL(window.location);
        url.searchParams.set("bout", currentBoutID!);
        window.history.pushState(null, "", url.toString());
    }, [currentBoutID]);

    const administratorOfTeam: "left" | "right" | null = (() => {
        try {
            if (!userInfo || !dualMeetData) return null;

            if (team1Info?.administrators?.includes(userInfo.id) || team1Info?.managers?.includes(userInfo.id)) {
                return "left";
            } else if (team2Info?.administrators?.includes(userInfo.id) || team2Info?.managers?.includes(userInfo.id)) {
                return "right";
            }

            if (team1Info) {
                const team1Fencers = Object.values(team1Info.roster[dualMeetData.season]).flatMap(l => Object.keys(l));
                if (userInfo.linkedFencerIds.some(l => team1Fencers.includes(l))) return "left";
            }

            if (team2Info) {
                const team2Fencers = Object.values(team2Info.roster[dualMeetData.season]).flatMap(l => Object.keys(l));
                if (userInfo.linkedFencerIds.some(l => team2Fencers.includes(l))) return "right";
            }

            return null;
        } catch {
            return null;
        }
    })();

    if (!dualMeetLoaded || !eventLoaded) {
        return (
            <TbTPage>
                <CommonLoading color="#714FCA" size="large" />
            </TbTPage>
        );
    }

    if (!((userInfo?.flags || 0) & UserFlag.MeetManager) && dualMeetData && !administratorOfTeam) {
        if ((enteredPINExpiration || -Infinity) < Date.now()) {
            dispatch({ type: "resetEnteredPINV2" });
            return <PermissionDenied message="You are not authorized to score this meet!" />;
        } else {
            if (enteredPIN !== dualMeetData?.pin1 && enteredPIN !== dualMeetData?.pin2 && enteredPIN !== eventInfo?.refereePin) {
                return <PermissionDenied message="You are not authorized to score this meet!" />;
            }
        }
    }

    if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const isAdmin = (userInfo?.flags || 0) & UserFlag.UberAdmin;
    const overridable =
        isAdmin ||
        (notCurrentEditorPIN.toString().length === 5 &&
            (Number(notCurrentEditorPIN) === dualMeetData?.pin1 || Number(notCurrentEditorPIN) === dualMeetData?.pin2));

    const overrideCurrentEditor = () => {
        if (!currentBout) return;
        if (overridable) {
            DB.setCurrentBoutEditor(currentBout.id, boutKey);
        }
    };

    //#region Bout navigation
    const nextBout = () => {
        if (!currentBout) return;

        const boutsOfWeapon = boutsRaw.filter(l => l.weapon === currentBout.weapon);
        boutsOfWeapon.sort((a, b) => a.order - b.order);
        const currentBoutIdx = boutsOfWeapon.findIndex(l => l.id === currentBoutID);
        if (currentBoutIdx === undefined || currentBoutIdx === -1) return;

        if (currentBoutIdx === boutsOfWeapon.length - 1) {
            const nextWeapon = currentBout.weapon === "Sabre" ? "Foil" : currentBout.weapon === "Foil" ? "Epee" : null;
            if (!nextWeapon) return;

            const matchingBout = boutsRaw.find(l => l.weapon === nextWeapon && l.order === 0);
            if (!matchingBout) return;
            setCurrentBoutID(matchingBout.id);
        }

        const next = boutsOfWeapon[currentBoutIdx + 1];
        if (!next) return;

        setCurrentBoutID(next.id);
    };

    const prevBout = () => {
        if (!currentBout) return;

        const boutsOfWeapon = boutsRaw.filter(l => l.weapon === currentBout.weapon);
        boutsOfWeapon.sort((a, b) => a.order - b.order);
        const currentBoutIdx = boutsOfWeapon.findIndex(l => l.id === currentBoutID);
        if (currentBoutIdx === undefined || currentBoutIdx < 0) return;

        if (currentBoutIdx === 0) {
            const nextWeapon = currentBout.weapon === "Sabre" ? null : currentBout.weapon === "Foil" ? "Sabre" : "Foil";
            if (!nextWeapon) return;

            const matchingBout = boutsRaw.find(l => l.weapon === nextWeapon && l.order === 8);
            if (!matchingBout) return;
            setCurrentBoutID(matchingBout.id);
        }

        const prev = boutsOfWeapon[currentBoutIdx - 1];
        if (!prev) return;

        setCurrentBoutID(prev.id);
    };

    const nextRound = () => {
        const boutIdx = boutsRaw.findIndex(l => l.id === currentBoutID);

        if (boutIdx >= boutsRaw.length - 9) {
            setCurrentBoutID(boutsRaw[boutsRaw.length - 1].id);
        } else {
            setCurrentBoutID(boutsRaw[boutIdx + 9].id);
        }
    };

    const prevRound = () => {
        const boutIdx = boutsRaw.findIndex(l => l.id === currentBoutID);

        if (boutIdx <= 8) {
            setCurrentBoutID(boutsRaw[0].id);
        } else {
            setCurrentBoutID(boutsRaw[boutIdx - 9].id);
        }
    };
    //#endregion Bout navigation

    const mobile = size.width < 600;

    const getBoutTitle = (bout: IDualMeetBout) => {
        if (size.width < 600) {
            return `${bout.weapon} Round #${Math.floor(bout.order / 3) + 1} Bout #${(bout.order % 3) + 1}`;
        } else {
            return `${bout.weapon} Round #${Math.floor(bout.order / 3) + 1} Bout #${(bout.order % 3) + 1} - ${bout.fencer1.fencerInfo.firstName} ${bout.fencer1.fencerInfo.lastName} vs. ${bout.fencer2.fencerInfo.firstName} ${bout.fencer2.fencerInfo.lastName}`;
        }
    };

    const BoutNavigator = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {!mobile && currentBout && (
                    <div onClick={prevRound} className={`dualMeetScorerBoutNav ${boutIdx <= 0 ? "disabled" : ""}`}>
                        <Icon className="dualMeetScorerBoutNavIcon" path={mdiChevronDoubleLeft} size="20px" />
                    </div>
                )}
                {!mobile && currentBout && (
                    <div onClick={prevBout} className={`dualMeetScorerBoutNav ${boutIdx <= 0 ? "disabled" : ""}`}>
                        <Icon className="dualMeetScorerBoutNavIcon" path={mdiChevronLeft} size="20px" />
                    </div>
                )}
                {currentBout ? (
                    <div>
                        {currentBout.weapon} Round {mobile ? "" : "#"}
                        {Math.floor(currentBout.order / 3) + 1} Bout {mobile ? "" : "#"}
                        {(currentBout.order % 3) + 1}
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
                {!mobile && currentBout && (
                    <div onClick={nextBout} className={`dualMeetScorerBoutNav ${boutIdx >= 26 ? "disabled" : ""}`}>
                        <Icon className="dualMeetScorerBoutNavIcon" path={mdiChevronRight} size="20px" />
                    </div>
                )}
                {!mobile && currentBout && (
                    <div onClick={nextRound} className={`dualMeetScorerBoutNav ${boutIdx >= 26 ? "disabled" : ""}`}>
                        <Icon className="dualMeetScorerBoutNavIcon" path={mdiChevronDoubleRight} size="20px" />
                    </div>
                )}
            </div>
        );
    };

    const sabreBouts = boutsRaw.filter(l => l.weapon === "Sabre");
    const foilBouts = boutsRaw.filter(l => l.weapon === "Foil");
    const epeeBouts = boutsRaw.filter(l => l.weapon === "Epee");

    const orderedBouts = [...sabreBouts, ...foilBouts, ...epeeBouts];

    return (
        <TbTPage className="dualMeetsScorerPage">
            <h1 style={{ paddingTop: 30 }}>Dual Meet Scorer</h1>
            <StyledDropdown displayText={currentBout ? getBoutTitle(currentBout) : "Loading..."}>
                {orderedBouts.map(l => (
                    <MenuItem onClick={() => setCurrentBoutID(l.id)} key={`boutYouCanSelectRofl${l.id}`}>
                        {getBoutTitle(l)}
                    </MenuItem>
                ))}
            </StyledDropdown>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px"
                }}
            >
                <OnlineIndicator />
                <Button variant="contained" color="primary" sx={{ margin: "0 0 0 10px" }} href={`/meet/${id}`}>
                    Return to meet info page
                </Button>
            </div>
            {currentBout && currentBoutID && dualMeetData ? (
                <BoutScorerComponent
                    id={currentBoutID}
                    bout={currentBout}
                    bouts={dualMeetData?.bouts?.map(l => l.id) || []}
                    team1Fencers={team1Fencers}
                    team2Fencers={team2Fencers}
                    header={<BoutNavigator />}
                    dualMeetData={dualMeetData}
                    setTeam1Fencers={setTeam1Fencers}
                    setTeam2Fencers={setTeam2Fencers}
                />
            ) : (
                <></>
            )}
            <Modal open={notCurrentEditor}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Override Current Scorer
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 18 }}>
                        You are not the current live scorer for this bout. Do you want to override the current live scorer?{" "}
                        <strong>Note this will kick out the current live scorer.</strong>
                    </Typography>
                    <TextField
                        placeholder="Enter PIN"
                        label="Enter PIN"
                        style={{ margin: "10px 0", width: 150 }}
                        type="text"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        onInput={e => setNotCurrentEditorPIN((e.target as HTMLTextAreaElement).value)}
                    />
                    <div style={{ display: "flex", marginTop: "5px" }}>
                        <Button variant="contained" color="success" href={`/meet/${id}`} sx={{ margin: "0 10px 0 0" }}>
                            Go back
                        </Button>
                        <Button variant="contained" color="error" onClick={overrideCurrentEditor} disabled={!overridable}>
                            Override
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Dialog
                open={timeoutStatusText !== ""}
                onClose={() => setTimeoutStatusText("")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Team Timeout Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {timeoutStatusText} has taken their 14th timeout. They have one timeout remaining.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTimeoutStatusText("")} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </TbTPage>
    );
}
