import {
    IDualMeet,
    IExistingFencer,
    ITeam,
    IOrganization,
    IUser,
    Strip,
    Weapon,
    DualMeetType,
    IDualMeetBout,
    BoutType,
    IDualMeetBoutFencer,
    IBoutEvent,
    RosterCSV,
    IPublicationApplication,
    IDualMeet_DB,
    IDualMeetTeam,
    ActionQueue,
    BoutSide,
    Listener,
    SchoolType,
    ICollegeEventRound,
    ICollegeEvent,
    ICollegeEventTeam,
    IDualMeetBoutFencerInfo,
    Lineup,
    ICollegeEventRoundMeet,
    ITeamInvite,
    DualMeetSignatureKey,
    CollegeProgramType,
    IFencerRecord,
    IBoutCore,
    RosterRecords,
    IOrganizationEdit,
    IDualMeet_Base,
    ID
} from "../types";
import fire, { firebase } from "./firebaseSetup";
import { genRandomColor, getRandomInt, boutWinner, genAbbreviationFromName, genRandomStr, incrementString } from "./helpers";
import { compress, decompress } from "lz-string";
import { meetsRawEqual } from "./equality";
import CacheService from "./cache/cacheService";
import ListenerManager from "./cache/listenerManager";
import VersionService from "./cache/versionService";
import {
    ERROR_ACCOUNT_ISSUE,
    ERROR_ADDING_LINEUPS,
    ERROR_BOUT_DOES_NOT_EXIST,
    ERROR_BOUT_EVENT_DOES_NOT_EXIST,
    ERROR_CANNOT_MOVE_MEET,
    ERROR_DUAL_MEET_DOES_NOT_EXIST,
    ERROR_EVENT_DOES_NOT_EXIST,
    ERROR_FENCER_DOES_NOT_EXIST,
    ERROR_FENCER_RECORDS_DOES_NOT_EXIST,
    ERROR_INVALID_ID,
    ERROR_INVALID_INVITE,
    ERROR_INVITE_CLAIMED,
    ERROR_MEET_NOT_IN_SCHEDULE,
    ERROR_NOT_LOGGED_IN,
    ERROR_PROCESSING_MEET,
    ERROR_SCHOOL_DOES_NOT_EXIST,
    ERROR_TEAM_DOES_NOT_EXIST,
    ERROR_UNKNOWN,
    ERROR_USER_DOES_NOT_EXIST
} from "./constants";
import { GetTeamOptions } from "./database_types";
import { CURRENT_SEASON_STR } from "./season";

type Database = firebase.database.Database;
type Authentication = firebase.auth.Auth;
type Functions = firebase.functions.Functions;

export class DBSuccess<T> {
    public readonly status = "success";
    constructor(public data: T) {}
}
export class DBError<R> {
    public readonly status = "fail";
    constructor(public data: R) {}
}
export type DBResult<T = void, R = string> = DBSuccess<T> | DBError<R>;
// We need this to prevent TS from whining
const AsyncDBResult = Promise;
export type AsyncDBResult<T = void, R = string> = Promise<DBResult<T, R>>;

export const isSuccess = <T = void, R = string>(result: DBResult<T, R>): result is DBSuccess<T> => {
    return result.status === "success";
};
export const isFailure = <T = void, R = string>(result: DBResult<T, R>): result is DBError<R> => {
    return result.status === "fail";
};

/**
 * Helper function for calling a database ref
 */
export function refOnce<T>(ref: string): Promise<T> {
    return new Promise(res =>
        fire
            .database()
            .ref(ref)
            .once("value", v => {
                res(v.val());
            })
    );
}

export function refMultiple<T>(ref: string, listener: (val: T) => void): Promise<T> {
    return new Promise(res =>
        fire
            .database()
            .ref(ref)
            .on("value", v => {
                const val = v.val();
                listener(val);
                res(val);
            })
    );
}

export function removeRefListener(ref: string, listener?: (val: unknown) => void) {
    fire.database().ref(ref).off("value", listener);
}

export const DEFAULT_USER: IUser = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    createdAt: 0,
    updatedAt: 0,
    verificationPin: 99999,
    flags: 0,
    teams: [],
    managingTeams: [],
    linkedFencerIds: []
};

export const DEFAULT_TEAM: ITeam = {
    id: "",
    administrators: [],
    managers: [],
    boysAndGirlsTeam: "both",
    countryCode: "US",
    region: "",
    color: "#FFF",
    createdBy: "",
    createdAt: 0,
    dualMeets: {},
    fencers: [],
    published: false,
    name: "",
    pin: 99999,
    roster: {},
    seasons: [],
    type: SchoolType.HS
};

export const DEFAULT_ORGANIZATION: IOrganization = {
    id: "",
    name: "",
    administrators: [],
    countryCode: "US",
    region: "",
    color: "#FFF",
    createdBy: "",
    createdAt: 0,
    published: false,
    type: SchoolType.HS
};

export const DEFAULT_BOUT: Omit<IDualMeetBout, "fencer1" | "fencer2"> = {
    id: "",
    dualMeetId: "",
    color: "",
    currentSpectatorsNum: 0,
    log: [],
    order: 0,
    pin: 0,
    weapon: "Sabre",
    type: BoutType.DualMeet,
    switchedSides: false,
    priority: null,
    boutTime: 180000,
    startedAt: 0,
    endedAt: 0,
    updatedAt: 0
};

export const DEFAULT_EVENT: ICollegeEvent = {
    address: "",
    createdAt: new Date(),
    createdBy: "",
    hostName: "",
    id: "",
    location: "",
    mensRounds: [],
    mensTeams: [],
    name: "",
    published: false,
    refereePin: 12345,
    season: "",
    startedAt: new Date(),
    womensRounds: [],
    womensTeams: []
};

/**
 * Extends `DB_BASE` with caching features. Fully public
 */
export class DB_V2 {
    public PREFIX = "/test";
    public COMMON_PREFIX = "/common";

    protected database: Database;
    protected auth: Authentication;
    protected functions: Functions;

    public version: VersionService;

    private cache: CacheService;
    get teams() {
        return this.cache.teams;
    }
    get fencers() {
        return this.cache.fencers;
    }
    get organizations() {
        return this.cache.organizations;
    }
    get dualMeets() {
        return this.cache.dualMeets;
    }
    get dualMeetBouts() {
        return this.cache.dualMeetBouts;
    }
    get boutEvents() {
        return this.cache.boutEvents;
    }

    private listeners: ListenerManager;

    public constructor(prefix: string = "/test", db: Database, auth: Authentication, functions: Functions) {
        this.PREFIX = prefix;
        this.database = db;
        this.auth = auth;
        this.functions = functions;

        this.cache = new CacheService(prefix);
        this.cache.initializeStorage();

        this.listeners = new ListenerManager();

        this.version = new VersionService(db, prefix);
    }

    // #region Users

    /**
     * Add newly created user to db
     */
    public createUser(uid: string, firstName: string, lastName: string, email: string): AsyncDBResult<IUser> {
        return COMMON_DB.createUser(uid, firstName, lastName, email);
    }

    /**
     * Takes a filled out `IUser` and adds to `/v2` (migration purposes only)
     */
    public async migrateAccount(user: IUser): Promise<boolean> {
        await this.database.ref(`${this.PREFIX}/users/${user.id}`).set(user);
        return true;
    }

    /**
     * Get info of the current logged in user
     */
    public getCurrentUserInfo(listener?: Listener<DBResult<IUser>>): AsyncDBResult<IUser> {
        return COMMON_DB.getCurrentUserInfo(listener);
    }

    public getUserInfo(userId: string): AsyncDBResult<IUser> {
        return COMMON_DB.getUserInfo(userId);
    }

    /**
     * Links a fencer to a user in the database. Put `null` as `fencerID` to unlink.
     */
    public async linkUserToFencer(uid: string, fencerID: string): AsyncDBResult<null> {
        const userData = await this.getUserInfo(uid);
        if (userData.status === "fail") return userData;
        const user = userData.data;
        const newIds = user.linkedFencerIds.includes(fencerID)
            ? user.linkedFencerIds.filter(l => l !== fencerID)
            : [...user.linkedFencerIds, fencerID];
        await this.database.ref(`${this.COMMON_PREFIX}/users/${uid}`).update({ linkedFencerIds: newIds });
        return new DBSuccess(null);
    }

    public getUserList(listener?: Listener<DBResult<Record<string, IUser>>>): AsyncDBResult<Record<string, IUser>> {
        return COMMON_DB.getUserList(listener);
    }

    public stopListeningUserList() {
        return COMMON_DB.stopListeningUserList();
    }

    // #endregion

    // #region Teams

    /**
     * Get record of team ID to team data
     * NOTE: Potentially expensive as it might get all the data for all teams, not just IDs
     */
    public async getTeamList(listener?: Listener<DBResult<Record<string, ITeam>>>): AsyncDBResult<Record<string, ITeam>> {
        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (this.teams) {
                    found = true;
                    res(new DBSuccess(this.teams));
                }
                const l = v => {
                    const teams: Record<string, ITeam> = v.val() || {};
                    for (const i in teams) {
                        const val = { ...DEFAULT_TEAM, ...teams[i] };
                        teams[i] = val;
                        this.teams[i] = val;
                    }
                    listener(new DBSuccess(teams));
                    if (!found) {
                        res(new DBSuccess(teams));
                    }
                };
                this.listeners.set(listener, l);
                this.database.ref(`${this.PREFIX}/teams`).on("value", l);
            });
        } else {
            const teams = (await refOnce<Record<string, ITeam>>(`${this.PREFIX}/teams`)) || {};
            for (const i in teams) {
                const val = { ...DEFAULT_TEAM, ...teams[i] };
                teams[i] = val;
                this.teams[i] = val;
            }
            return new DBSuccess(teams);
        }
    }

    public stopListeningTeamList(listener?: (val: unknown) => void) {
        const l = this.listeners.get(listener);
        if (!l) return;
        removeRefListener(`${this.PREFIX}/teams`, l);
    }

    /**
     * Get team by ID
     * @param {string} teamID ID of team to get data for
     * @param {GetTeamOptions} [options] Optional configuration options for data retrieval
     */
    public async getTeam(teamID: string, options?: GetTeamOptions): AsyncDBResult<ITeam> {
        const listener = options?.listener || undefined;
        const forceCache = options?.forceCache || false;

        if (forceCache) {
            if (this.teams[teamID]) return new DBSuccess(this.teams[teamID]);
        }

        const handleTeam = (va: Partial<ITeam>) => {
            const val: ITeam = {
                ...DEFAULT_TEAM,
                seasons: Object.keys(va.roster || {}),
                ...va
            };
            for (const i in val.dualMeets) {
                val.dualMeets[i] = val.dualMeets[i].filter(Boolean);
            }
            return val;
        };

        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (this.teams[teamID]) {
                    found = true;
                    res(new DBSuccess(this.teams[teamID]));
                }
                const l = v => {
                    const va = v.val();
                    if (!va) {
                        const response = new DBError(ERROR_TEAM_DOES_NOT_EXIST);
                        listener(response);
                        return res(response);
                    }
                    const val: ITeam = handleTeam(va);
                    for (const season of val.seasons) {
                        if (!(season in val.roster)) {
                            val.roster[season] = {
                                Sabre: {},
                                Foil: {},
                                Epee: {}
                            };
                        }
                    }
                    this.teams[teamID] = val;
                    listener(new DBSuccess(val));
                    if (!found) {
                        res(new DBSuccess(val));
                    }
                };
                this.listeners.set(listener, l);
                this.database.ref(`${this.PREFIX}/teams/${teamID}`).on("value", l);
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/teams/${teamID}`).once("value");
            const teamData = (v.val() as Partial<ITeam>) || null;
            if (!teamData) {
                return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
            }
            const val: ITeam = handleTeam(teamData);
            for (const season of val.seasons) {
                if (!(season in val.roster)) {
                    val.roster[season] = { Sabre: {}, Foil: {}, Epee: {} };
                }
            }
            this.teams[teamID] = val;
            return new DBSuccess(val);
        }
    }

    public stopListeningTeam(id: string, listener?: (val: unknown) => void) {
        const l = this.listeners.get(listener);
        if (!l) return;
        removeRefListener(`${this.PREFIX}/teams/${id}`, l);
    }

    public async getOrganizationFromTeam(teamID: string): AsyncDBResult<IOrganization> {
        const v = await this.database.ref(`${this.PREFIX}/teams/${teamID}`).once("value");
        const dbVal = v.val() as ITeam | null;
        if (dbVal === null) {
            return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        } else if (!dbVal.orgID) {
            const orgList = await this.getOrganizationList();
            for (const orgID in orgList) {
                if (orgList[orgID].boysTeam === teamID || orgList[orgID].girlsTeam === teamID) {
                    this.database.ref(`${this.PREFIX}/teams/${teamID}/orgID`).set(orgID);
                    return this.getOrganization(orgID);
                }
            }
            return new DBError(ERROR_SCHOOL_DOES_NOT_EXIST);
        } else {
            return this.getOrganization(dbVal.orgID);
        }
    }

    /**
     * Create a team
     */
    public async createTeam(
        teamName: string,
        countryCode: string,
        region: string,
        teamGender: "boys" | "girls" | "both",
        type: SchoolType
    ): AsyncDBResult<string> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const d = Date.now();
        const databaseAdditions = {};
        const teamPIN = getRandomInt(10000, 99999);
        const teamID = (await this.database.ref(`${this.PREFIX}/teams`).push()).key!;
        const userData = await refOnce<IUser>(`${this.COMMON_PREFIX}/users/${user.uid}`);

        if (!userData) {
            return new DBError(ERROR_ACCOUNT_ISSUE);
        }

        const newTeam: ITeam = {
            id: teamID,
            administrators: [user.uid],
            managers: [],
            fencers: [],
            boysAndGirlsTeam: teamGender,
            countryCode: countryCode,
            region: region,
            color: `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`,
            createdAt: d,
            createdBy: user.uid,
            dualMeets: {},
            published: false,
            name: teamName,
            pin: teamPIN,
            roster: {},
            seasons: [],
            type
        };

        databaseAdditions[`${this.PREFIX}/teams/${teamID}`] = newTeam;

        databaseAdditions[`${this.COMMON_PREFIX}/users/${user.uid}`] = {
            ...userData,
            teams: [...(userData.teams || []), teamID]
        };
        await this.database.ref().update(databaseAdditions);
        return new DBSuccess(teamID);
    }

    /**
     * Delete a team based on ID
     */
    public async deleteTeam(teamID: string): AsyncDBResult<boolean> {
        const team = await this.getTeam(teamID);

        if (team.status === "fail") {
            return team;
        }

        if (Object.keys(team.data.dualMeets).length) {
            const meets = Object.values(team.data.dualMeets).flat();
            const mapped = await Promise.all(meets.map(l => refOnce<IDualMeet_DB | null>(`${this.PREFIX}/dualMeets/${l}`)));
            const valid = mapped.filter(Boolean);
            if (valid.length) {
                return new DBError("Cannot delete a team with any dual meets.");
            }
        }

        const databaseDeletions: Record<string, string | string[] | null> = {};

        databaseDeletions[`${this.PREFIX}/teams/${teamID}`] = null;

        for (const user of team.data.administrators) {
            try {
                const userData = await this.getUserInfo(user);
                if (userData.status === "fail") return userData;
                databaseDeletions[`${this.COMMON_PREFIX}/users/${user}/teams`] = userData.data.teams.filter(l => l !== teamID);
                databaseDeletions[`${this.COMMON_PREFIX}/users/${user}/linkedFencerId`] = null;
            } catch (e) {
                console.warn(`User ${user} no longer exists -- skipping`);
            }
        }

        for (const fencer of team.data.fencers) {
            databaseDeletions[`${this.PREFIX}/fencers/${fencer}`] = null;
        }

        const organizations = await this.getOrganizationList();
        for (const organization of Object.values(organizations.data)) {
            if (organization.boysTeam === teamID) {
                if (!organization.girlsTeam && !organization.girlsJVTeam && !organization.boysJVTeam) {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}`] = null;
                } else {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}/boysTeam`] = null;
                }
            }
            if (organization.girlsTeam === teamID) {
                if (!organization.boysTeam && !organization.girlsJVTeam && !organization.boysJVTeam) {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}`] = null;
                } else {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}/girlsTeam`] = null;
                }
            }
            if (organization.boysJVTeam === teamID) {
                if (!organization.girlsJVTeam && !organization.girlsTeam && !organization.boysTeam) {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}`] = null;
                } else {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}/boysJVTeam`] = null;
                }
            }
            if (organization.girlsJVTeam === teamID) {
                if (!organization.boysJVTeam && !organization.girlsTeam && !organization.boysTeam) {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}`] = null;
                } else {
                    databaseDeletions[`${this.PREFIX}/organizations/${organization.id}/girlsJVTeam`] = null;
                }
            }
        }

        await this.database.ref().update(databaseDeletions);

        return new DBSuccess(true);
    }

    public async updateTeamConference(teamID: string, conference: string[] | string): AsyncDBResult<boolean> {
        const conferenceAsList: string[] = typeof conference === "string" ? [conference] : conference;

        const databaseAdditions = {};
        databaseAdditions[`${this.PREFIX}/teams/${teamID}/conference`] = conferenceAsList;

        await this.database.ref().update(databaseAdditions);

        return new DBSuccess(true);
    }

    // TODO: refactor functions like this and check for gaps
    public async leaveTeam(teamID: string): AsyncDBResult<boolean> {
        const user = await this.getCurrentUserInfo();
        if (user.status === "fail") return new DBError(ERROR_NOT_LOGGED_IN);
        let administrators = (await refOnce<ITeam["administrators"]>(`${this.PREFIX}/teams/${teamID}/administrators`)) || [];
        const managers = (await refOnce<ITeam["managers"]>(`${this.PREFIX}/teams/${teamID}/managers`)) || [];
        administrators = administrators.filter(l => l !== user.data.id);
        managers.splice(managers.indexOf(user.data.id), 1);
        await this.database.ref(`${this.PREFIX}/teams/${teamID}`).update({ administrators, managers });
        await this.database.ref(`${this.COMMON_PREFIX}/users/${user.data.id}`).update({
            teams: [...user.data.teams.filter(l => l !== teamID)],
            managingTeams: [...user.data.managingTeams.filter(l => l !== teamID)]
        });
        return new DBSuccess(true);
    }

    public async convertTeam(id: string, type: string): AsyncDBResult<boolean> {
        const updates = {};
        updates[`${this.PREFIX}/teams/${id}/region`] = type;
        await this.database.ref().update(updates);
        return new DBSuccess(true);
    }

    public async createTeamAttachedToOrg(
        orgId: string,
        teamName: string,
        teamAbb: string,
        countryCode: string,
        region: string,
        teamGender: "boysTeam" | "girlsTeam" | "boysJVTeam" | "girlsJVTeam" | "both",
        type: SchoolType,
        { published, publishedAt, publishedBy }: { published: boolean; publishedAt?: number; publishedBy?: string },
        conference?: string[] | string
    ): AsyncDBResult<string> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const d = Date.now();
        const databaseAdditions = {};
        const teamPIN = getRandomInt(10000, 99999);
        const teamID = (await this.database.ref(`${this.PREFIX}/teams`).push()).key!;
        const userData = await refOnce<IUser>(`${this.COMMON_PREFIX}/users/${user.uid}`);

        if (!userData) return new DBError(ERROR_ACCOUNT_ISSUE);

        const newTeam: ITeam = {
            id: teamID,
            administrators: [user.uid],
            abbreviation: teamAbb,
            managers: [],
            fencers: [],
            boysAndGirlsTeam: teamGender === "both" ? "both" : teamGender.startsWith("boys") ? "boys" : "girls",
            countryCode: countryCode,
            region: region,
            color: `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`,
            createdAt: d,
            createdBy: user.uid,
            dualMeets: {},
            published,
            name: teamName,
            pin: teamPIN,
            roster: {},
            seasons: [CURRENT_SEASON_STR],
            orgID: orgId,
            type
        };

        if (published && publishedAt) newTeam.publishedAt = publishedAt;
        if (published && publishedBy) newTeam.publishedBy = publishedBy;
        if (conference) {
            newTeam.conference = typeof conference === "string" ? [conference] : conference;
        }

        databaseAdditions[`${this.PREFIX}/teams/${teamID}`] = newTeam;
        databaseAdditions[`${this.PREFIX}/organizations/${orgId}/${teamGender}`] = newTeam.id;

        databaseAdditions[`${this.COMMON_PREFIX}/users/${user.uid}`] = {
            ...userData,
            teams: [...(userData.teams || []), teamID]
        };
        await this.database.ref().update(databaseAdditions);

        return new DBSuccess(teamID);
    }

    /**
     * Delete a season in a team
     */
    public async deleteTeamSeason(teamID: string, seasonName: string): AsyncDBResult<boolean> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const team = await this.getTeam(teamID);

        if (team.status === "fail") {
            return team;
        }

        const databaseDeletions = {};

        databaseDeletions[`${this.PREFIX}/teams/${teamID}/roster/${seasonName}`] = null;
        databaseDeletions[`${this.PREFIX}/teams/${teamID}/roster/${team.data.seasons.indexOf(seasonName)}`] = null;
        databaseDeletions[`${this.PREFIX}/teams/${teamID}/seasons`] = team.data.seasons.filter(l => l !== seasonName);

        await this.database.ref().update(databaseDeletions);

        return new DBSuccess(true);
    }

    public async getTeamRosterAsCSV(teamID: string, season: string): AsyncDBResult<string> {
        const team = await this.getTeam(teamID);
        if (team.status === "fail") {
            return team;
        }

        const teamMembers = team.data.roster[season];
        const sabreRoster = (await Promise.all(Object.keys(teamMembers?.Sabre || {}).map(this.getFencer.bind(this)))) as IExistingFencer[];
        const foilRoster = (await Promise.all(Object.keys(teamMembers?.Foil || {}).map(this.getFencer.bind(this)))) as IExistingFencer[];
        const epeeRoster = (await Promise.all(Object.keys(teamMembers?.Epee || {}).map(this.getFencer.bind(this)))) as IExistingFencer[];

        let csv = "First name,Last name,Graduation year,Weapon,Home strip,Away strip";

        for (const fencer of sabreRoster) {
            csv += `\n${fencer.firstName},${fencer.lastName},${fencer.gradYear},Sabre,${teamMembers.Sabre[fencer.id].home || "Sub"},${teamMembers.Sabre[fencer.id].away || "Sub"}`;
        }
        for (const fencer of foilRoster) {
            csv += `\n${fencer.firstName},${fencer.lastName},${fencer.gradYear},Foil,${teamMembers.Foil[fencer.id].home || "Sub"},${teamMembers.Foil[fencer.id].away || "Sub"}`;
        }
        for (const fencer of epeeRoster) {
            csv += `\n${fencer.firstName},${fencer.lastName},${fencer.gradYear},Epee,${teamMembers.Epee[fencer.id].home || "Sub"},${teamMembers.Epee[fencer.id].away || "Sub"}`;
        }

        return new DBSuccess(csv);
    }

    public async getRosterRecords(
        teamId: string,
        season: string
    ): AsyncDBResult<Record<Weapon, Record<string, { wins: number; losses: number }>>> {
        const debugTime = false;

        debugTime && console.time("Getting roster records...");
        debugTime && console.time("Getting team data...");
        const team = await this.getTeam(teamId);
        debugTime && console.timeEnd("Getting team data...");

        if (team.status === "fail") {
            return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        }

        try {
            debugTime && console.time("Getting meet data...");
            const meets: string[] = team.data.dualMeets[season] || [];
            // const meetsData: IDualMeet[] = await Promise.all(meets.map(l => this.getDualMeet(l)));
            const meetsDataRaw = await Promise.allResolved(meets.map(meet => this.getDualMeetRaw(meet, undefined, true)));
            const meetsData = meetsDataRaw.filter(isSuccess).map(l => l.data);

            const boutIDs: string[][] = [];
            for (const meet of meetsData) {
                boutIDs.push(meet.bouts.filter(Boolean).map(l => l.id));
            }
            debugTime && console.timeEnd("Getting meet data...");

            debugTime && console.time("Getting bout data...");
            const bouts: IDualMeetBout[][] = await Promise.all(
                boutIDs.map(meet =>
                    Promise.allResolved<DBResult<IDualMeetBout>>(meet.map(l => this.getBout(l))).then(l =>
                        l.filter(isSuccess).map(j => j.data)
                    )
                )
            );
            debugTime && console.timeEnd("Getting bout data...");

            debugTime && console.time("Processing bouts...");
            const teamRecords: Record<Weapon, Record<string, { wins: number; losses: number }>> = {
                Sabre: {},
                Foil: {},
                Epee: {}
            };

            for (let i = 0; i < meetsData.length; i++) {
                const meet = meetsData[i];
                const meetBouts = bouts[i];
                const side = meet.team1ID === teamId ? BoutSide.Fencer1 : BoutSide.Fencer2;

                for (const bout of meetBouts) {
                    const fencer = bout[`fencer${side}`];
                    const winner = boutWinner(bout, { team: false });
                    const fencerWon = winner === side;
                    const fencerLost = winner && winner !== side;
                    const record = teamRecords[bout.weapon][fencer.fencerInfo.id!];
                    if (record) {
                        if (fencerWon) {
                            record.wins++;
                        } else if (fencerLost) {
                            record.losses++;
                        }
                    } else {
                        teamRecords[bout.weapon][fencer.fencerInfo.id!] = {
                            wins: Number(fencerWon),
                            losses: Number(fencerLost)
                        };
                    }
                }
            }
            debugTime && console.timeEnd("Processing bouts...");
            debugTime && console.timeEnd("Getting roster records...");

            return new DBSuccess(teamRecords);
        } catch (e: unknown) {
            console.error("Error in retrieving roster records", e);
            return new DBError("An error occurred when retrieving roster records.");
        }
    }

    public async updateTeamsLastAccessedSeason(teamIDs: string[]) {
        const databaseUpdates: Record<string, unknown> = {};

        for (const id of teamIDs) {
            databaseUpdates[`/teams/${id}/lastAccessedSeason`] = CURRENT_SEASON_STR;
        }

        this.database.ref(this.PREFIX).update(databaseUpdates);
    }

    // #endregion

    // #region Organizations

    public async getOrganizationList(
        listener?: Listener<DBResult<Record<string, IOrganization>>>
    ): AsyncDBResult<Record<string, IOrganization>> {
        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (Object.keys(this.organizations).length) {
                    found = true;
                    res(new DBSuccess(this.organizations));
                }
                this.database.ref(`${this.PREFIX}/organizations`).on("value", v => {
                    const orgs: Record<string, IOrganization> = v.val() || {};
                    for (const i in orgs) {
                        const val = { ...DEFAULT_ORGANIZATION, ...orgs[i] };
                        orgs[i] = val;
                        this.organizations[i] = val;
                    }
                    const response = new DBSuccess(orgs);
                    listener(response);
                    if (!found) {
                        res(response);
                    }
                });
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/organizations`).once("value");
            const orgs = v.val() || ({} as Record<string, IOrganization>);
            for (const i in orgs) {
                const val = { ...DEFAULT_ORGANIZATION, ...orgs[i] };
                orgs[i] = val;
                this.organizations[i] = val;
            }
            return new DBSuccess(orgs);
        }
    }

    public stopListeningOrganizationList(listener?: (val: unknown) => void) {
        const l = (listener && this.listeners.get(listener)) || listener;
        removeRefListener(`${this.PREFIX}/organizations`, l);
    }

    public async getOrganization(orgID: string, listener?: Listener<DBResult<IOrganization>>): AsyncDBResult<IOrganization> {
        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (this.organizations[orgID]) {
                    found = true;
                    res(new DBSuccess(this.organizations[orgID]));
                }
                const l = v => {
                    const org = v.val();
                    if (!org) {
                        const error = new DBError(ERROR_SCHOOL_DOES_NOT_EXIST);
                        listener(error);
                        return res(error);
                    }
                    const val: IOrganization = { ...DEFAULT_ORGANIZATION, ...org };
                    this.organizations[orgID] = val;
                    listener(new DBSuccess(val));
                    if (!found) {
                        res(new DBSuccess(val));
                    }
                };
                this.database.ref(`${this.PREFIX}/organizations/${orgID}`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/organizations/${orgID}`).once("value");
            const org = v.val();
            if (!org) {
                return new DBError(ERROR_SCHOOL_DOES_NOT_EXIST);
            }
            return new DBSuccess({ ...DEFAULT_ORGANIZATION, ...org });
        }
    }

    public stopListeningOrganization(id: string, listener?: (val: unknown) => void) {
        const l = (listener && this.listeners.get(listener)) || listener;
        removeRefListener(`${this.PREFIX}/organizations/${id}`, l);
    }

    // TODO: refactor this
    public async createOrganization(
        name: string,
        countryCode: string,
        region: string,
        {
            boysTeam,
            girlsTeam,
            boysTeamJV,
            girlsTeamJV,
            boysTeamConference,
            girlsTeamConference
        }: {
            boysTeam: CollegeProgramType;
            girlsTeam: CollegeProgramType;
            boysTeamJV?: boolean;
            girlsTeamJV?: boolean;
            boysTeamConference?: string | string[];
            girlsTeamConference?: string | string[];
        },
        abbreviation: string,
        type: SchoolType,
        writeIn: boolean,
        district?: string
    ): AsyncDBResult<IOrganization> {
        const d = new Date();
        const userInfo = (await this.getCurrentUserInfo())!;
        if (userInfo.status === "fail") return userInfo;
        const user = userInfo.data;
        const newID = this.database.ref(`${this.PREFIX}/organizations/`).push().key!;

        const createTeamObj = (
            id: string,
            leName: string,
            gender: "boys" | "girls",
            leRegion: string,
            leType: SchoolType,
            abbreviation?: string,
            conference?: string | string[]
        ): ITeam => {
            const obj: ITeam = {
                id,
                name: leName,
                administrators: writeIn ? [] : [user.id],
                managers: [],
                boysAndGirlsTeam: gender,
                countryCode,
                region: leRegion,
                color: genRandomColor(),
                createdAt: Number(d),
                createdBy: user.id,
                dualMeets: {},
                fencers: [],
                published: writeIn,
                pin: getRandomInt(10000, 99999),
                roster: {},
                seasons: [CURRENT_SEASON_STR],
                type: leType,
                orgID: newID,
                writeIn
            };

            if (abbreviation) obj.abbreviation = abbreviation;
            if (conference) {
                obj.conference = typeof conference === "string" ? [conference] : conference;
            }

            return obj;
        };

        return new Promise(async res => {
            let boysTeamID, girlsTeamID, boysJVTeamID, girlsJVTeamID;
            const boysTeamConfAsList = boysTeamConference
                ? typeof boysTeamConference === "string"
                    ? [boysTeamConference]
                    : boysTeamConference
                : undefined;
            const girlsTeamConfAsList = girlsTeamConference
                ? typeof girlsTeamConference === "string"
                    ? [girlsTeamConference]
                    : girlsTeamConference
                : undefined;

            const databaseUpdates: Record<string, unknown> = {};

            if (boysTeam !== CollegeProgramType.None) {
                boysTeamID = this.database.ref(`${this.PREFIX}/teams/`).push().key;

                if (!writeIn) databaseUpdates[`${this.COMMON_PREFIX}/users/${user.id}/teams/${user.teams.length}`] = boysTeamID;
                user.teams.push(boysTeamID);

                const newTeam = createTeamObj(
                    boysTeamID,
                    `${name} Men's`,
                    "boys",
                    boysTeam === CollegeProgramType.Varsity ? region : "club",
                    type,
                    `${abbreviation} Men's`,
                    boysTeamConfAsList
                );

                this.database.ref(`${this.PREFIX}/teams/${boysTeamID}`).set(newTeam);
            }

            if (girlsTeam !== CollegeProgramType.None) {
                girlsTeamID = this.database.ref(`${this.PREFIX}/teams/`).push().key;

                if (!writeIn) databaseUpdates[`${this.COMMON_PREFIX}/users/${user.id}/teams/${user.teams.length}`] = girlsTeamID;
                user.teams.push(girlsTeamID);

                const newTeam = createTeamObj(
                    girlsTeamID,
                    `${name} Women's`,
                    "girls",
                    girlsTeam === CollegeProgramType.Varsity ? region : "club",
                    type,
                    `${abbreviation} Women's`,
                    girlsTeamConfAsList
                );

                this.database.ref(`${this.PREFIX}/teams/${girlsTeamID}`).set(newTeam);
            }

            if (boysTeamJV) {
                boysJVTeamID = this.database.ref(`${this.PREFIX}/teams/`).push().key;

                if (!writeIn) databaseUpdates[`${this.COMMON_PREFIX}/users/${user.id}/teams/${user.teams.length}`] = boysJVTeamID;
                user.teams.push(boysJVTeamID);

                const newTeam = createTeamObj(
                    boysJVTeamID,
                    `${name} Men's - JV`,
                    "boys",
                    region,
                    type,
                    `${abbreviation} Men's - JV`,
                    boysTeamConfAsList
                );

                this.database.ref(`${this.PREFIX}/teams/${boysJVTeamID}`).set(newTeam);
            }

            if (girlsTeamJV) {
                girlsJVTeamID = this.database.ref(`${this.PREFIX}/teams/`).push().key;

                if (!writeIn) databaseUpdates[`${this.COMMON_PREFIX}/users/${user.id}/teams/${user.teams.length}`] = girlsJVTeamID;
                user.teams.push(girlsJVTeamID);

                const newTeam = createTeamObj(
                    girlsJVTeamID,
                    `${name} Women's - JV`,
                    "girls",
                    region,
                    type,
                    `${abbreviation} Womens's - JV`,
                    girlsTeamConfAsList
                );

                this.database.ref(`${this.PREFIX}/teams/${girlsJVTeamID}`).set(newTeam);
            }

            const newOrganization: IOrganization = {
                id: newID,
                name,
                administrators: writeIn ? [] : [user.id],
                boysTeam: boysTeamID || null,
                girlsTeam: girlsTeamID || null,
                boysJVTeam: boysJVTeamID || null,
                girlsJVTeam: girlsJVTeamID || null,
                countryCode,
                region,
                color: `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`,
                createdAt: Number(d),
                createdBy: user.id,
                published: writeIn,
                publishedAt: Number(d),
                type,
                writeIn
            };

            if (district) newOrganization.district = district;
            if (abbreviation) newOrganization.abbreviation = abbreviation;

            this.database.ref(`${this.PREFIX}/organizations/${newID}`).set(newOrganization);
            this.database.ref().update(databaseUpdates);

            this.organizations[newOrganization.id] = newOrganization;

            res(new DBSuccess(newOrganization));
        });
    }

    public async editOrganization(edits: IOrganizationEdit): AsyncDBResult<boolean> {
        const user = this.auth.currentUser;
        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const organizationUpdates: Record<string, unknown> = {};

        if (edits.district) {
            organizationUpdates[`${this.PREFIX}/organizations/${edits.id}/district`] = edits.district;
        }

        if (edits.logoPath || edits.logoPath === "") {
            organizationUpdates[`${this.PREFIX}/organizations/${edits.id}/logoPath`] = edits.logoPath;
        }

        await this.database.ref().update(organizationUpdates);

        return new DBSuccess(true);
    }

    public async requestOrganizationPublication(
        takeover: boolean,
        orgId: string,
        orgName: string,
        name: string,
        school: string,
        additionalInfo?: string
    ): AsyncDBResult<boolean> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);
        if (!name) return new DBError("No name provided.");
        if (!school) return new DBError("No school provided.");

        const applicationID = this.database.ref(`${this.PREFIX}/publicationRequests`).push().key!;

        const application: IPublicationApplication = {
            id: applicationID,
            name,
            orgName,
            orgId,
            school,
            submittedAt: Date.now(),
            userId: user.uid,
            additionalInfo: additionalInfo || null,
            takeover
        };

        try {
            const sendApplicationNotice = this.functions.httpsCallable("sendApplicationNotice");
            await sendApplicationNotice({
                appId: applicationID,
                orgName,
                name,
                school,
                additionalInfo,
                takeover
            });
            await this.database.ref(`${this.PREFIX}/publicationRequests/${applicationID}`).set(application);
            return new DBSuccess(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            const code = e.code;
            const message = e.message;
            const details = e.details;
            return new DBError(`Error sending email: ${code} - ${message} ${details}`);
        }
    }

    public async acceptOrganizationPublication(
        id: string,
        orgId: string,
        orgName: string,
        email: string,
        userId: string,
        takeover: boolean
    ): AsyncDBResult<boolean> {
        const userInfo = await this.getUserInfo(userId);
        if (userInfo.status === "fail") return userInfo;
        const user = userInfo.data;
        const d = Date.now();

        const databaseUpdates = {};

        const orgRequest = await this.getOrganization(orgId);
        if (orgRequest.status === "fail") return new DBError(ERROR_SCHOOL_DOES_NOT_EXIST);
        const org = orgRequest.data;

        databaseUpdates[`${this.PREFIX}/publicationRequests/${id}`] = null;

        if (takeover) {
            const teams = user.teams;

            if (org.boysTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.boysTeam}/administrators`] = [userId];
                databaseUpdates[`${this.PREFIX}/teams/${org.boysTeam}/writeIn`] = false;
                teams.push(org.boysTeam);
            }
            if (org.girlsTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsTeam}/administrators`] = [userId];
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsTeam}/writeIn`] = false;
                teams.push(org.girlsTeam);
            }
            if (org.boysJVTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.boysJVTeam}/administrators`] = [userId];
                databaseUpdates[`${this.PREFIX}/teams/${org.boysJVTeam}/writeIn`] = false;
                teams.push(org.boysJVTeam);
            }
            if (org.girlsJVTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsJVTeam}/administrators`] = [userId];
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsJVTeam}/writeIn`] = false;
                teams.push(org.girlsJVTeam);
            }

            databaseUpdates[`${this.PREFIX}/organizations/${orgId}/administrators`] = [userId];
            databaseUpdates[`${this.PREFIX}/organizations/${orgId}/writeIn`] = false;
            databaseUpdates[`${this.COMMON_PREFIX}/users/${userId}/teams`] = teams;
        } else {
            databaseUpdates[`${this.PREFIX}/organizations/${orgId}/published`] = true;
            databaseUpdates[`${this.PREFIX}/organizations/${orgId}/publishedAt`] = d;
            databaseUpdates[`${this.PREFIX}/organizations/${orgId}/publishedBy`] = user.id;

            if (org.boysTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.boysTeam}/published`] = true;
                databaseUpdates[`${this.PREFIX}/teams/${org.boysTeam}/publishedAt`] = d;
                databaseUpdates[`${this.PREFIX}/teams/${org.boysTeam}/publishedBy`] = user.id;
            }
            if (org.girlsTeam) {
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsTeam}/published`] = true;
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsTeam}/publishedAt`] = d;
                databaseUpdates[`${this.PREFIX}/teams/${org.girlsTeam}/publishedBy`] = user.id;
            }
        }

        this.database.ref().update(databaseUpdates);

        try {
            this.database.ref().update(databaseUpdates);

            const sendOrganizationAcceptance = this.functions.httpsCallable("sendOrganizationAcceptance");
            await sendOrganizationAcceptance({
                orgName,
                email,
                orgId,
                takeover
            });
            return new DBSuccess(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            const code = e.code;
            const message = e.message;
            const details = e.details;
            return new DBError(`Error sending email: ${code} - ${message} ${details}`);
        }
    }

    public async rejectOrganizationPublication(
        id: string,
        orgName: string,
        email: string,
        orgId: string,
        takeover: boolean,
        reason?: string
    ): AsyncDBResult<boolean> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const databaseUpdates = {
            [`${this.PREFIX}/publicationRequests/${id}`]: null
        };

        try {
            this.database.ref().update(databaseUpdates);

            const sendOrganizationRejection = this.functions.httpsCallable("sendOrganizationRejection");
            await sendOrganizationRejection({
                orgName,
                email,
                orgId,
                reason,
                takeover
            });
            return new DBSuccess(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            const code = e.code;
            const message = e.message;
            const details = e.details;
            return new DBError(`Error sending email: ${code} - ${message} ${details}`);
        }
    }

    public async unpublishOrganization(id: string): AsyncDBResult<boolean> {
        const orgResult = await this.getOrganization(id);
        if (orgResult.status === "fail") return orgResult;
        const org = orgResult.data;

        const databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/organizations/${id}/published`]: false,
            [`${this.PREFIX}/organizations/${id}/publishedAt`]: null,
            [`${this.PREFIX}/organizations/${id}/publishedBy`]: null
        };

        const subteams = [org.boysTeam, org.girlsTeam, org.boysJVTeam, org.girlsJVTeam];
        for (const team of subteams) {
            if (team) {
                databaseUpdates[`${this.PREFIX}/teams/${team}/published`] = false;
                databaseUpdates[`${this.PREFIX}/teams/${team}/publishedAt`] = null;
                databaseUpdates[`${this.PREFIX}/teams/${team}/publishedBy`] = null;
            }
        }

        try {
            await this.database.ref().update(databaseUpdates);
            return new DBSuccess(true);
        } catch (e: unknown) {
            if (e instanceof Error) {
                return new DBError(e.message);
            } else {
                return new DBError(ERROR_UNKNOWN);
            }
        }
    }

    public getOrganizationRequests(
        listener?: Listener<DBResult<Record<string, IPublicationApplication>>>
    ): AsyncDBResult<Record<string, IPublicationApplication>> {
        return new Promise(res => {
            this.database.ref(`${this.PREFIX}/publicationRequests`).on("value", v => {
                const val: Record<string, IPublicationApplication> = v.val();

                if (!val) {
                    const response = new DBSuccess({});
                    listener && listener(response);
                    res(response);
                } else {
                    const response = new DBSuccess(val);
                    listener && listener(response);
                    res(response);
                }
            });
        });
    }

    public stopListeningOrganizationRequests() {
        this.database.ref(`${this.PREFIX}/publicationRequests`).off();
    }

    public async makeOrganizationUnmanaged(org: string | IOrganization): AsyncDBResult<boolean> {
        let data = org;
        if (typeof data === "string") {
            const org2 = await this.getOrganization(data);
            if (org2.status === "fail") return org2;
            data = org2.data;
        }

        const databaseUpdates: Record<string, unknown> = {};

        databaseUpdates[`${this.PREFIX}/organizations/${data.id}/administrators`] = [];
        if (data.boysTeam) databaseUpdates[`${this.PREFIX}/teams/${data.boysTeam}/administrators`] = [];
        if (data.girlsTeam) databaseUpdates[`${this.PREFIX}/teams/${data.girlsTeam}/administrators`] = [];
        if (data.boysJVTeam) databaseUpdates[`${this.PREFIX}/teams/${data.boysJVTeam}/administrators`] = [];
        if (data.girlsJVTeam) databaseUpdates[`${this.PREFIX}/teams/${data.girlsJVTeam}/administrators`] = [];

        const teamIDs = [data.boysTeam, data.girlsTeam, data.boysJVTeam, data.girlsJVTeam];

        const users = await this.getUserList();
        for (const id in users) {
            const filteredTeams = users[id].teams.filter(l => teamIDs.includes(l));
            const filteredManagingTeams = users[id].managingTeams.filter(l => teamIDs.includes(l));

            if (users[id].teams.length !== filteredTeams.length) {
                databaseUpdates[`${this.PREFIX}/users/${id}/teams`] = filteredTeams;
            }
            if (users[id].managingTeams.length !== filteredManagingTeams.length) {
                databaseUpdates[`${this.PREFIX}/users/${id}/managingTeams`] = filteredManagingTeams;
            }
        }

        await this.database.ref().update(databaseUpdates);
        return new DBSuccess(true);
    }

    // #endregion

    // #region Fencers

    /**
     * Get fencer by ID
     * @param fencerID
     * @returns
     */
    public async getFencer(fencerID: string): AsyncDBResult<IExistingFencer> {
        if (fencerID.match(/(\.|#|\$|\[|\])/g)) return new DBError(ERROR_INVALID_ID);
        const cached = this.fencers[fencerID];
        if (cached && Object.keys(cached).length) {
            return new DBSuccess(cached);
        }

        const data = await refOnce<IExistingFencer>(`${this.PREFIX}/fencers/${fencerID}`);
        if (data === null) return new DBError(ERROR_FENCER_DOES_NOT_EXIST);
        this.fencers[fencerID] = data;
        return new DBSuccess(data);
    }

    /**
     * Get all fencers by a query
     * @param param0
     */
    public async getFencersByQuery({
        id,
        firstName,
        lastName,
        limit
    }: Partial<{ id: string; firstName: string; lastName: string; limit: number }>): AsyncDBResult<IExistingFencer[]> {
        const trueLimit = limit || 20;
        if (id) {
            const fencer = await this.getFencer(id);
            if (fencer.status === "fail") return fencer;
            return new DBSuccess([fencer.data]);
        } else if (lastName) {
            const incremented = incrementString(lastName);
            const v = await fire
                .database()
                .ref(`${this.PREFIX}/fencers`)
                .orderByChild("lastName")
                .startAt(lastName)
                .endBefore(incremented)
                .limitToFirst(trueLimit)
                .once("value");
            const fencersObj = v.val() as Record<string, IExistingFencer> | null;
            if (!fencersObj) return new DBError(ERROR_FENCER_DOES_NOT_EXIST);
            let fencers = Object.values(fencersObj);
            if (firstName) {
                fencers = fencers.filter(l => l.firstName.includes(firstName));
            }
            return new DBSuccess(fencers);
        } else if (firstName) {
            const incremented = incrementString(firstName);
            const v = await fire
                .database()
                .ref(`${this.PREFIX}/fencers`)
                .orderByChild("firstName")
                .startAt(firstName)
                .endBefore(incremented)
                .limitToFirst(trueLimit)
                .once("value");
            const fencersObj = v.val() as Record<string, IExistingFencer> | null;
            if (!fencersObj) return new DBError(ERROR_FENCER_DOES_NOT_EXIST);
            const fencers = Object.values(fencersObj);
            return new DBSuccess(Object.values(fencers));
        } else {
            return new DBError(ERROR_UNKNOWN);
        }
    }

    public async createNewFencerInTeamDatabaseObj(
        teamId: string,
        firstName: string,
        lastName: string,
        gradYear?: number,
        season?: string,
        weapon?: Weapon
    ): AsyncDBResult<{
        databaseAdditions: Record<string, unknown>;
        newFencer: IExistingFencer;
    }> {
        const user = this.auth.currentUser;

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const d = new Date();
        const databaseAdditions: Record<string, unknown> = {};
        const fencerPIN = Math.floor(Math.random() * 90000) + 10000;
        const fencerID = this.database.ref(`${this.PREFIX}/fencers/`).push().key!;

        const team = await this.getTeam(teamId);
        if (team.status === "fail") return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        const teamData = team.data;

        const newFencer: IExistingFencer = {
            id: fencerID,
            firstName,
            lastName,
            gradYear: gradYear,
            createdBy: user.uid,
            createdAt: Number(d),
            updatedAt: Number(d),
            pin: fencerPIN,
            teams: [teamId],
            bouts: []
        };

        for (const i in newFencer) {
            databaseAdditions[`${this.PREFIX}/fencers/${fencerID}/${i}`] = newFencer[i] || null;
        }

        databaseAdditions[`${this.PREFIX}/teams/${teamId}/fencers`] = [...(teamData.fencers || []), fencerID];

        if (season && !teamData.seasons.includes(season)) {
            databaseAdditions[`${this.PREFIX}/teams/${teamId}/seasons`] = [...(teamData.seasons || []), season];
        }

        if (season && weapon) {
            databaseAdditions[`${this.PREFIX}/teams/${teamId}/roster/${season}/${weapon}/${fencerID}/home`] = 0;
            databaseAdditions[`${this.PREFIX}/teams/${teamId}/roster/${season}/${weapon}/${fencerID}/away`] = 0;
        }

        return new DBSuccess({ databaseAdditions, newFencer });
    }

    public async createNewFencerInTeam(
        teamId: string,
        firstName: string,
        lastName: string,
        gradYear: number,
        season?: string,
        weapon?: Weapon
    ): AsyncDBResult<string> {
        try {
            const dbResult = await this.createNewFencerInTeamDatabaseObj(teamId, firstName, lastName, gradYear, season, weapon);
            if (dbResult.status === "fail") return dbResult;
            const { databaseAdditions, newFencer } = dbResult.data;

            await this.database.ref().update(databaseAdditions);

            return new DBSuccess(newFencer.id);
        } catch (e: unknown) {
            if (e instanceof DBError) {
                throw e;
            } else {
                console.error(e);
                return new DBError(ERROR_UNKNOWN);
            }
        }
    }

    public async editFencer(fencerID: string, firstName: string, lastName: string, gradYear: number): AsyncDBResult<boolean> {
        const user = this.auth.currentUser;
        const d = new Date();

        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const fencerUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/fencers/${fencerID}/firstName`]: firstName,
            [`${this.PREFIX}/fencers/${fencerID}/lastName`]: lastName,
            [`${this.PREFIX}/fencers/${fencerID}/gradYear`]: gradYear,
            [`${this.PREFIX}/fencers/${fencerID}/updatedAt`]: Number(d)
        };

        await this.database.ref().update(fencerUpdates);
        return new DBSuccess(true);
    }

    public async runActionQueue(teamId: string, queue: ActionQueue): AsyncDBResult<boolean> {
        const d = new Date();

        const idRedefs: Record<string, string> = {};
        const databaseModifications: Record<string, unknown> = {};
        const stripUpdates: Record<string, { home: number; away: number }> = {};

        for (const action of queue) {
            if (action.type === "updateStrip") {
                if (action.id in stripUpdates) {
                    stripUpdates[action.id][action.home ? "home" : "away"] = action.strip;
                } else {
                    stripUpdates[action.id] = {
                        home: action.home ? action.strip : 0,
                        away: !action.home ? action.strip : 0
                    };
                }
            }
        }

        for (const action of queue) {
            if (action.type === "cloneSeason") {
                await this.createNewClonedSeason(teamId, action.seasonToClone, action.newSeason);
            } else {
                const id = action.id in idRedefs ? idRedefs[action.id] : action?.id;
                switch (action.type) {
                    case "addFencer": {
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/home`] = 0;
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/away`] = 0;
                        const cachedTeam = this.teams[id];
                        if (cachedTeam) {
                            cachedTeam.roster[action.season][action.weapon][id] = { home: 0, away: 0 };
                        }
                        break;
                    }
                    case "createFencer": {
                        const dbResult = await this.createNewFencerInTeamDatabaseObj(
                            teamId,
                            action.firstName,
                            action.lastName,
                            action.graduationYear,
                            action.season,
                            action.weapon
                        );
                        if (dbResult.status === "fail") return dbResult;
                        const { databaseAdditions, newFencer } = dbResult.data;
                        idRedefs[action.id] = newFencer.id;
                        if (action.id in stripUpdates) {
                            stripUpdates[newFencer.id] = stripUpdates[action.id];
                            delete stripUpdates[action.id];
                        }
                        const fencersStr = `${this.PREFIX}/teams/${teamId}/fencers`;
                        for (const i in databaseAdditions) {
                            if (i === fencersStr && fencersStr in databaseModifications) {
                                databaseModifications[fencersStr] = [
                                    ...new Set([...(databaseAdditions[i] as unknown[]), ...(databaseModifications[i] as unknown[])])
                                ];
                            } else {
                                databaseModifications[i] = databaseAdditions[i];
                            }
                        }
                        break;
                    }
                    case "deleteFencer": {
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/home`] = null;
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/away`] = null;
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/power`] = null;
                        const cachedTeam = this.teams[id];
                        if (cachedTeam) {
                            delete cachedTeam.roster[action.season][action.weapon][id];
                        }
                        break;
                    }
                    case "editFencer": {
                        databaseModifications[`${this.PREFIX}/fencers/${id}/firstName`] = action.firstName;
                        databaseModifications[`${this.PREFIX}/fencers/${id}/lastName`] = action.lastName;
                        databaseModifications[`${this.PREFIX}/fencers/${id}/gradYear`] = action.graduationYear;
                        databaseModifications[`${this.PREFIX}/fencers/${id}/updatedAt`] = Number(d);
                        if (action.power !== undefined) {
                            databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/power`] =
                                action.power;
                        }
                        const cachedFencer = this.fencers[id];
                        if (cachedFencer) {
                            cachedFencer.firstName = action.firstName;
                            cachedFencer.lastName = action.lastName;
                            cachedFencer.gradYear = action.graduationYear;
                        }
                        const cachedTeam = this.teams[id];
                        if (action.power && cachedTeam) {
                            cachedTeam.roster[action.season][action.weapon][id].power = action.power;
                        }
                        break;
                    }
                    case "updateStrip": {
                        const homeStr = action.home ? "home" : "away";
                        databaseModifications[`${this.PREFIX}/teams/${teamId}/roster/${action.season}/${action.weapon}/${id}/${homeStr}`] =
                            stripUpdates[id][homeStr];
                        const cachedTeam = this.teams[id];
                        if (cachedTeam) {
                            cachedTeam.roster[action.season][action.weapon][id][homeStr] = stripUpdates[id][homeStr];
                        }
                        break;
                    }
                }
            }
        }

        await this.database.ref().update(databaseModifications);
        return new DBSuccess(true);
    }

    /**
     * Combines two fencers, merging their statistics and deleting the original fencer
     * @param originId Fencer that will be deleted
     * @param targetId Fencer that receives new statistics
     * @param teamId Team of both fencers
     * @returns Success
     */
    public async mergeFencer(originId: string, targetId: string, teamId: string | ITeam): AsyncDBResult<boolean> {
        let team = teamId;
        if (typeof team === "string") {
            const dbteam = await this.getTeam(team);
            if (dbteam.status === "fail") return dbteam;
            team = dbteam.data;
        }

        const [originalFencerResult, targetFencerResult] = await Promise.all([this.getFencer(originId), this.getFencer(targetId)]);
        if (originalFencerResult.status === "fail") return originalFencerResult;
        if (targetFencerResult.status === "fail") return targetFencerResult;
        const originalFencer = originalFencerResult.data;
        const targetFencer = targetFencerResult.data;

        const bouts: string[] = [];
        const meetIds = Object.values(team.dualMeets).flat();
        for (const meet of meetIds) {
            const dualMeetData = await this.getDualMeetRaw(meet);
            if (dualMeetData.status === "fail") continue;
            bouts.push(...dualMeetData.data.bouts.map(l => l.id));
        }
        const boutData = await Promise.all(bouts.map(l => this.getBout(l)));

        const databaseUpdates: Record<string, unknown> = {};

        for (const bout of boutData) {
            if (bout.status === "fail") continue;
            if (bout.data.fencer1.fencerInfo.id === originId) {
                databaseUpdates[`${this.PREFIX}/bouts/${bout.data.id}/fencer1/fencerInfo/id`] = targetId;
            }
            if (bout.data.fencer2.fencerInfo.id === originId) {
                databaseUpdates[`${this.PREFIX}/bouts/${bout.data.id}/fencer2/fencerInfo/id`] = targetId;
            }
        }

        for (const season of team.seasons) {
            databaseUpdates[`${this.PREFIX}/teams/${team.id}/roster/${season}/Sabre/${originId}`] = null;
            databaseUpdates[`${this.PREFIX}/teams/${team.id}/roster/${season}/Foil/${originId}`] = null;
            databaseUpdates[`${this.PREFIX}/teams/${team.id}/roster/${season}/Epee/${originId}`] = null;
        }

        databaseUpdates[`${this.PREFIX}/fencers/${originId}/bouts`] = [];
        databaseUpdates[`${this.PREFIX}/fencers/${targetId}/bouts`] = [...(targetFencer.bouts || []), ...(originalFencer.bouts || [])];

        const originRecordsRes = await this.getFencerRecord(originId);
        if (originRecordsRes.status === "fail") return originRecordsRes;
        const originRecords = originRecordsRes.data;

        for (const boutId in originRecords.bouts) {
            databaseUpdates[`${this.PREFIX}/fencerRecords/${originId}/bouts/${boutId}`] = originRecords.bouts[boutId];
        }

        await this.database.ref().update(databaseUpdates);
        return new DBSuccess(true);
    }

    public async createNewClonedSeason(
        teamID: string,
        oldSeason: string,
        newSeason: string,
        cloneFromOtherTeam?: string
    ): AsyncDBResult<boolean> {
        const [teamData, otherTeamData] = await Promise.all([
            this.getTeam(teamID),
            cloneFromOtherTeam ? this.getTeam(cloneFromOtherTeam) : null
        ]);
        if (teamData.status === "fail") return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        if (otherTeamData?.status === "fail") return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        const team = teamData.data;
        const otherTeam = otherTeamData?.data;

        const oldSeasonRoster = (cloneFromOtherTeam ? otherTeam?.roster[oldSeason] : team.roster[oldSeason]) || {
            Sabre: {},
            Foil: {},
            Epee: {}
        };
        const oldTeamSeasons = (team.seasons || []).filter(Boolean);

        const databaseAdditions = {};

        databaseAdditions[`${this.PREFIX}/teams/${teamID}/seasons`] = [...oldTeamSeasons, newSeason];

        databaseAdditions[`${this.PREFIX}/teams/${teamID}/roster/${newSeason}`] = {
            Sabre: oldSeasonRoster.Sabre ? { ...oldSeasonRoster.Sabre } : {},
            Foil: oldSeasonRoster.Foil ? { ...oldSeasonRoster.Foil } : {},
            Epee: oldSeasonRoster.Epee ? { ...oldSeasonRoster.Epee } : {}
        };

        await this.database.ref().update(databaseAdditions);
        return new DBSuccess(true);
    }

    public async importSeason(teamID: string, season: string, roster: RosterCSV): AsyncDBResult<boolean> {
        if (!teamID || !season) return new DBError(ERROR_UNKNOWN);

        const user = this.auth.currentUser!;
        const d = new Date();

        const newFencers: (IExistingFencer & {
            weapon?: Weapon;
            homeStrip?: number;
            awayStrip?: number;
        })[] = [];

        const teamData = await this.getTeam(teamID);
        if (teamData.status === "fail") return new DBError(ERROR_TEAM_DOES_NOT_EXIST);
        const team = teamData.data;

        const fencers = Object.values(team.fencers);

        for (const fencer of roster) {
            const fencerID = this.database.ref(`${this.PREFIX}/fencers/`).push().key!;

            const newFencer: IExistingFencer & {
                weapon?: Weapon;
                homeStrip?: number;
                awayStrip?: number;
            } = {
                id: fencerID,
                firstName: fencer.firstName,
                lastName: fencer.lastName,
                createdBy: user.uid,
                createdAt: Number(d),
                updatedAt: Number(d),
                pin: getRandomInt(10000, 99999),
                teams: [teamID],
                bouts: [],
                weapon: fencer.weapon,
                homeStrip: fencer.homeStrip,
                awayStrip: fencer.awayStrip
            };

            if (fencer.gradYear) {
                newFencer.gradYear = fencer.gradYear;
            }

            newFencers.push(newFencer);
        }

        const databaseAdditions = {};

        databaseAdditions[`${this.PREFIX}/teams/${teamID}/fencers`] = [...fencers, ...newFencers.map(l => l.id)];

        for (const fencer of newFencers) {
            databaseAdditions[`${this.PREFIX}/fencers/${fencer.id}`] = fencer;

            const weapon = fencer.weapon;
            const home = fencer.homeStrip;
            const away = fencer.awayStrip;

            delete fencer.weapon;
            delete fencer.homeStrip;
            delete fencer.awayStrip;

            databaseAdditions[`${this.PREFIX}/teams/${teamID}/roster/${season}/${weapon}/${fencer.id}`] = {
                home,
                away
            };
        }

        await this.database.ref().update(databaseAdditions);
        return new DBSuccess(true);
    }

    public async addManager(teamID: string, userID: string): AsyncDBResult<boolean> {
        const user = await this.getUserInfo(userID);
        if (user.status === "fail") return user;
        const managers = (await refOnce<ITeam["managers"]>(`${this.PREFIX}/teams/${teamID}/managers`)) || [];
        if (managers.includes(userID)) return new DBSuccess(true);
        await this.database.ref(`${this.PREFIX}/teams/${teamID}/managers`).update({ [managers.length]: userID });
        await this.database.ref(`${this.COMMON_PREFIX}/users/${userID}`).update({
            managingTeams: [...user.data.managingTeams.filter(l => l !== teamID), teamID]
        });
        return new DBSuccess(true);
    }

    public async removeManager(teamID: string, userID: string): AsyncDBResult<boolean> {
        const user = await this.getUserInfo(userID);
        if (user.status === "fail") return user;
        const managers = (await refOnce<ITeam["managers"]>(`${this.PREFIX}/teams/${teamID}/managers`)) || [];
        managers.splice(managers.indexOf(userID), 1);
        await this.database.ref(`${this.PREFIX}/teams/${teamID}`).update({ managers });
        await this.database.ref(`${this.COMMON_PREFIX}/users/${userID}`).update({
            managingTeams: [...user.data.managingTeams.filter(l => l !== teamID)]
        });
        return new DBSuccess(true);
    }

    public async addAdministrator(teamID: string, userID: string): AsyncDBResult<boolean> {
        const user = await this.getUserInfo(userID);
        if (user.status === "fail") return user;
        const administrators = (await refOnce<ITeam["administrators"]>(`${this.PREFIX}/teams/${teamID}/administrators`)) || [];
        if (administrators.includes(userID)) return new DBSuccess(true);
        await this.database.ref(`${this.PREFIX}/teams/${teamID}/administrators`).update({ [administrators.length]: userID });
        await this.database.ref(`${this.COMMON_PREFIX}/users/${userID}`).update({
            teams: [...user.data.teams.filter(l => l !== teamID), teamID]
        });
        return new DBSuccess(true);
    }

    public async removeAdministrator(teamID: string, userID: string): AsyncDBResult<boolean> {
        const user = await this.getUserInfo(userID);
        if (user.status === "fail") return user;
        const administrators = (await refOnce<ITeam["administrators"]>(`${this.PREFIX}/teams/${teamID}/administrators`)) || [];
        administrators.splice(administrators.indexOf(userID), 1);
        await this.database.ref(`${this.PREFIX}/teams/${teamID}`).update({ administrators });
        await this.database.ref(`${this.COMMON_PREFIX}/users/${userID}`).update({
            teams: [...user.data.teams.filter(l => l !== teamID)]
        });
        return new DBSuccess(true);
    }

    // #endregion

    // #region Fencer records

    /**
     * Get fencer records by fencer ID
     * @param id
     * @param options
     * @returns
     */
    public async getFencerRecord(id: string, options?: { listener?: Listener<DBResult<IFencerRecord>> }): AsyncDBResult<IFencerRecord> {
        if (options?.listener) {
            return new Promise(res => {
                this.database.ref(`${this.PREFIX}/fencerRecords/${id}`).on("value", v => {
                    const records = v.val();
                    const result: DBResult<IFencerRecord> = records
                        ? new DBSuccess(records)
                        : new DBError(ERROR_FENCER_RECORDS_DOES_NOT_EXIST);
                    options.listener!(result);
                    res(result);
                });
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/fencerRecords/${id}`).once("value");
            const records = v.val();
            const result: DBResult<IFencerRecord> = records ? new DBSuccess(records) : new DBError(ERROR_FENCER_RECORDS_DOES_NOT_EXIST);
            return result;
        }
    }

    public async getDbUpdatesForAddBoutToFencerRecord(
        fencerId: string,
        boutId: string | IDualMeetBout,
        dualMeetData?: IDualMeet_Base
    ): AsyncDBResult<Record<string, unknown>> {
        let bout = boutId;
        if (typeof bout === "string") {
            const boutRequest = await this.getBout(bout);
            if (boutRequest.status === "fail") return boutRequest;
            bout = boutRequest.data;
        }

        let meet = dualMeetData;
        if (!meet) {
            const meetResult = await this.getDualMeetRaw(bout.dualMeetId);
            if (meetResult.status === "fail") return meetResult;
            meet = meetResult.data;
        }

        const winner = boutWinner(bout, { team: false, requireEnded: false });

        let won = false;
        let lost = false;
        if (winner === BoutSide.Fencer1) {
            if (bout.fencer1.fencerInfo.id === fencerId) won = true;
            if (bout.fencer2.fencerInfo.id === fencerId) lost = true;
        }
        if (winner === BoutSide.Fencer2) {
            if (bout.fencer1.fencerInfo.id === fencerId) lost = true;
            if (bout.fencer2.fencerInfo.id === fencerId) won = true;
        }

        const databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${bout.id}/won`]: won,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${bout.id}/lost`]: lost,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${bout.id}/season`]: meet.season,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${bout.id}/weapon`]: bout.weapon
        };

        return new DBSuccess(databaseUpdates);
    }

    public async getUpdatesToRemoveBoutFromFencerRecord(
        fencerId: string,
        boutId: string | IDualMeetBout,
        dualMeetData?: IDualMeet_Base
    ): AsyncDBResult<Record<string, unknown>> {
        let bout = boutId;
        if (typeof bout === "string") {
            const boutRequest = await this.getBout(bout);
            if (boutRequest.status === "fail") return boutRequest;
            bout = boutRequest.data;
        }

        let meet = dualMeetData;
        if (!meet) {
            const meetResult = await this.getDualMeetRaw(bout.dualMeetId);
            if (meetResult.status === "fail") return meetResult;
            meet = meetResult.data;
        }

        const databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${boutId}/won`]: null,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${boutId}/lost`]: null,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${boutId}/weapon`]: null,
            [`${this.PREFIX}/fencerRecords/${fencerId}/bouts/${boutId}/season`]: null
        };

        return new DBSuccess(databaseUpdates);
    }

    /**
     * Manually searches through all of a fencer's bouts to update their records.
     * @param id Fencer ID
     * @returns
     */
    public async migrateFencerRecords(id: string): AsyncDBResult<boolean> {
        const fencerInfo = await this.getFencer(id);
        if (fencerInfo.status === "fail") return fencerInfo;
        const fencer = fencerInfo.data;

        const databaseUpdates: Record<string, unknown> = {};

        const existingRecords = await this.getFencerRecord(id);
        if (existingRecords.status === "success") {
            for (const boutId in existingRecords.data.bouts) {
                databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${boutId}/won`] = null;
                databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${boutId}/lost`] = null;
                databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${boutId}/season`] = null;
                databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${boutId}/weapon`] = null;
            }
        }

        for (const teamID of fencer.teams) {
            const teamData = await this.getTeam(teamID);
            if (teamData.status === "fail") continue;
            const team = teamData.data;

            for (const season in team.dualMeets) {
                for (const meet of team.dualMeets[season]) {
                    try {
                        const meetData = await this.getDualMeetRaw(meet, undefined, true);
                        if (meetData.status === "fail") continue;
                        const bouts = await Promise.all(meetData.data.bouts.map(l => this.getBout(l.id, undefined, true)));

                        for (const boutRequest of bouts) {
                            if (boutRequest.status === "fail") continue;
                            const bout = boutRequest.data;
                            if (!(bout.fencer1.fencerInfo.id === id) && !(bout.fencer2.fencerInfo.id === id)) continue;
                            const winner = boutWinner(bout);

                            let won = false;
                            let lost = false;
                            if (winner === BoutSide.Fencer1) {
                                if (bout.fencer1.fencerInfo.id === id) won = true;
                                if (bout.fencer2.fencerInfo.id === id) lost = true;
                            }
                            if (winner === BoutSide.Fencer2) {
                                if (bout.fencer1.fencerInfo.id === id) lost = true;
                                if (bout.fencer2.fencerInfo.id === id) won = true;
                            }

                            databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/id`] = id;
                            databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${bout.id}/won`] = won;
                            databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${bout.id}/lost`] = lost;
                            databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${bout.id}/season`] = season;
                            databaseUpdates[`${this.PREFIX}/fencerRecords/${id}/bouts/${bout.id}/weapon`] = bout.weapon;
                        }
                    } catch (e: unknown) {
                        // asdf
                    }
                }
            }
        }

        await this.database.ref().update(databaseUpdates);
        return new DBSuccess(true);
    }

    // TODO: remove DBResult maybe?
    public async getUpdatesToSubBoutRecord(
        boutId: string | IDualMeetBout,
        side: BoutSide,
        newId: string | null
    ): AsyncDBResult<Record<string, unknown>> {
        let bout = boutId;
        if (typeof bout === "string") {
            const response = await this.getBout(bout);
            if (response.status === "fail") return response;
            bout = response.data;
        }

        const oldFencer = bout[`fencer${side}`].fencerInfo.id;
        const oldRecord = (await this.database.ref(`${this.PREFIX}/fencerRecords/${oldFencer}/bouts/${bout.id}`).once("value")).val();
        if (!oldRecord) return new DBSuccess({});

        const databaseUpdates: Record<string, unknown> = {};

        databaseUpdates[`${this.PREFIX}/fencerRecords/${oldFencer}/bouts/${bout.id}`] = null;
        if (newId) {
            databaseUpdates[`${this.PREFIX}/fencerRecords/${newId}/bouts/${bout.id}`] = oldRecord;
        }

        return new DBSuccess(databaseUpdates);
    }

    public async subBoutRecord(boutId: string | IDualMeetBout, side: BoutSide, newId: string | null): AsyncDBResult<boolean> {
        const updates = this.getUpdatesToSubBoutRecord(boutId, side, newId);
        await this.database.ref().update(updates);
        return new DBSuccess(true);
    }

    // #endregion

    // #region Invites

    public async inviteAdmin(team: string): AsyncDBResult<boolean> {
        const userRequest = await this.getCurrentUserInfo();
        if (userRequest.status === "fail") return userRequest;
        const user = userRequest.data;
        const newInviteRef = this.database.ref(`${this.PREFIX}/invites/`).push();
        const code = genRandomStr().slice(0, 6).toUpperCase();
        const invite: ITeamInvite = {
            id: newInviteRef.key!,
            type: "team",
            team,
            code,
            createdAt: Date.now(),
            createdBy: user.id,
            expiresAt: Date.now() + 1000 * 60 * 60 * 24,
            claimed: false
        };

        await newInviteRef.set(invite);
        return new DBSuccess(true);
    }

    public async deleteInvite(id: string): AsyncDBResult<boolean> {
        await this.database.ref(`${this.PREFIX}/invites/${id}`).set(null);
        return new DBSuccess(true);
    }

    public async joinTeamUsingInvite(code: string): AsyncDBResult<boolean> {
        const user = await this.getCurrentUserInfo();
        if (user.status === "fail") return new DBError(ERROR_NOT_LOGGED_IN);

        const matchingInvite = await this.getInviteFromCode(code);
        if (matchingInvite.status === "fail") return matchingInvite;
        if (matchingInvite.data.claimed) return new DBError(ERROR_INVITE_CLAIMED);

        await this.database.ref(`${this.PREFIX}/invites/${matchingInvite.data.id}`).update({
            "/claimed": true,
            "/claimedAt": Date.now()
        });
        const success = await this.addAdministrator(matchingInvite.data.team, user.data.id);
        return success;
    }

    public async getInviteFromCode(code: string): AsyncDBResult<ITeamInvite> {
        const v = await this.database.ref(`${this.PREFIX}/invites`).orderByChild("code").equalTo(code.toUpperCase()).once("value");
        if (!v.val()) return new DBError(ERROR_INVALID_INVITE);
        const invite = Object.values(v.val())[0] as ITeamInvite;
        if (!invite) return new DBError(ERROR_INVALID_INVITE);
        return new DBSuccess(invite);
    }

    public async getInvite(id: string, listener?: (v: ITeamInvite | null) => void): Promise<ITeamInvite | null> {
        if (listener) {
            return new Promise(res => {
                this.database.ref(`${this.PREFIX}/invites/${id}`).on("value", v => {
                    const val = v.val();
                    listener(val);
                    res(val);
                });
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/invites/${id}`).once("value");
            return v.val();
        }
    }

    public async getInvitesForTeam(
        team: string,
        listener?: (v: Record<string, ITeamInvite>) => void
    ): Promise<Record<string, ITeamInvite>> {
        if (listener) {
            return new Promise(res => {
                this.database
                    .ref(`${this.PREFIX}/invites`)
                    .orderByChild("team")
                    .equalTo(team)
                    .on("value", v => {
                        const val = v.val() || {};
                        listener(val);
                        res(val);
                    });
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/invites`).orderByChild("team").equalTo(team).once("value");
            return v.val() || {};
        }
    }

    // #endregion

    // #region Dual Meets

    /**
     * Get raw dual meet list
     */
    public async getDualMeetsRaw(listener?: (val: unknown) => void) {
        if (listener) {
            return new Promise(async res => {
                const l = async v => {
                    const meets: Record<string, IDualMeet_DB> = v.val() || {};
                    const response = new DBSuccess(meets);
                    listener(response);
                    res(response);
                };
                this.database.ref(`${this.PREFIX}/dualMeets`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/dualMeets`).once("value");
            const meets = (v.val() || {}) as Record<string, IDualMeet_DB>;
            for (const meetID in meets) {
                this.dualMeets[meetID] = meets[meetID];
            }
            return new DBSuccess(meets);
        }
    }

    /**
     * Get dual meet list (with caching)
     * @param listener Listener function
     */
    public async getDualMeets(listener?: Listener<DBResult<Record<string, IDualMeet>>>): AsyncDBResult<Record<string, IDualMeet>> {
        if (listener) {
            return new Promise(async res => {
                // let found = false;
                // if (Object.keys(this.dualMeets).length) {
                //     found = true;
                //     const processedArr = await Promise.all(Object.values(this.dualMeets).map(this.processMeet));
                //     const filtered = processedArr.filter(isSuccess).map(l => l.data);
                //     const processed = Object.fromEntries(filtered.map(l => [l.id, l]));
                //     const response = new DBSuccess(processed);
                //     listener(response);
                //     res(response);
                // }
                const l = async v => {
                    const meets: Record<string, IDualMeet_DB> = v.val() || {};
                    const processedMeetPromises = await Promise.all(Object.values(meets).map(this.processMeet));
                    const result: Record<string, IDualMeet> = {};
                    for (const meet of processedMeetPromises) {
                        if (meet.status === "fail") continue;
                        this.dualMeets[meet.data.id] = meet.data;
                        result[meet.data.id] = meet.data;
                    }
                    const response = new DBSuccess(result);
                    listener(response);
                    res(response);
                };
                this.database.ref(`${this.PREFIX}/dualMeets`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/dualMeets`).once("value");
            const meets = (v.val() || {}) as Record<string, IDualMeet_DB>;
            const processedMeetPromises = await Promise.all(Object.values(meets).map(l => this.processMeet(l)));
            const result: Record<string, IDualMeet> = {};
            for (const meet of processedMeetPromises) {
                if (meet.status === "fail") continue;
                this.dualMeets[meet.data.id] = meet.data;
                result[meet.data.id] = meet.data;
            }
            return new DBSuccess(result);
        }
    }

    public getLiveDualMeets(listener?: Listener<DBResult<IDualMeet[]>>): AsyncDBResult<IDualMeet[]> {
        return new Promise(res => {
            const todaysDate = new Date();
            todaysDate.setHours(0, 0, 0, 0);
            const startTimeStamp = todaysDate.getTime(); // yesterday
            const endTimeStamp = startTimeStamp + 86400000; // tomorrow
            this.database
                .ref(`${this.PREFIX}/dualMeets`)
                .orderByChild("startedAt")
                .startAt(startTimeStamp)
                .endAt(endTimeStamp)
                .on("value", async queryResults => {
                    const val: Record<string, IDualMeet> | null = queryResults.val();

                    const resolved = val ? Object.values(val).filter(l => l.published) : [];

                    listener && listener(new DBSuccess(resolved));
                    res(new DBSuccess(resolved));
                });
        });
    }

    public stopListeningLiveDualMeets() {
        removeRefListener(`${this.PREFIX}/dualMeets`);
    }

    /**
     * Get dual meet (with caching)
     * @param id Dual meet id
     * @param listener Listener function
     */
    public async getDualMeet(id: string, listener?: Listener<DBResult<IDualMeet>>): AsyncDBResult<IDualMeet> {
        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (this.dualMeets[id]) {
                    found = true;
                    const processed = await this.processMeet(this.dualMeets[id]);
                    listener(processed);
                    res(processed);
                }
                const l = async v => {
                    const meet = v.val();
                    if (meet) {
                        this.dualMeets[id] = meet;
                        const realMeet = await this.processMeet(meet);
                        listener(realMeet);
                        if (!found) {
                            res(realMeet);
                        }
                    } else {
                        const error = new DBError(ERROR_DUAL_MEET_DOES_NOT_EXIST);
                        listener(error);
                        res(error);
                    }
                };
                this.database.ref(`${this.PREFIX}/dualMeets/${id}`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            const meet = await refOnce<IDualMeet_DB>(`${this.PREFIX}/dualMeets/${id}`);
            if (meet) {
                const realMeet = await this.processMeet(meet);
                if (realMeet.status === "success") {
                    this.dualMeets[id] = realMeet.data;
                }
                return realMeet;
            } else {
                return new DBError(ERROR_DUAL_MEET_DOES_NOT_EXIST);
            }
        }
    }

    /**
     * Stops listening to a dual meet (both processed and raw)
     * @param id Dual meet id
     * @param listener Listener function
     */
    public stopListeningDualMeet(id: string, listener?: (val: unknown) => void) {
        const l = (listener && this.listeners.get(listener)) || listener;
        removeRefListener(`${this.PREFIX}/dualMeets/${id}`, l);
    }

    /**
     * Get dual meet score only
     * @param id Dual meet id
     * @param listener Listener function
     */
    public async getDualMeetRaw(id: string, listener?: Listener<DBResult<IDualMeet_DB>>, forceCache = false): AsyncDBResult<IDualMeet_DB> {
        if (listener) {
            return new Promise(async res => {
                let found = false;
                if (this.dualMeets[id]) {
                    found = true;
                    const processed = new DBSuccess(this.dualMeets[id]);
                    listener(processed);
                    res(processed);
                }
                const l = async v => {
                    const meet = v.val() as IDualMeet_DB;
                    if (meet) {
                        const equalToOldValue = meetsRawEqual(this.dualMeets[id], meet);
                        if (equalToOldValue) this.dualMeets[id] = meet;
                        const response = new DBSuccess(meet);
                        listener(response);
                        if (!found) {
                            res(response);
                        }
                    } else {
                        res(new DBError(ERROR_DUAL_MEET_DOES_NOT_EXIST));
                    }
                };
                this.database.ref(`${this.PREFIX}/dualMeets/${id}`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            if (forceCache) {
                if (id in this.dualMeets) {
                    return new DBSuccess(this.dualMeets[id]);
                }
            }
            const meet = await refOnce<IDualMeet_DB>(`${this.PREFIX}/dualMeets/${id}`);
            if (meet) {
                this.dualMeets[id] = meet;
                return new DBSuccess(meet);
            } else {
                return new DBError(ERROR_DUAL_MEET_DOES_NOT_EXIST);
            }
        }
    }

    private async getDbUpdatesForDualMeet(
        id: string,
        team1:
            | ITeam
            | {
                  name: string;
                  abbreviation: string;
                  fencers: IDualMeetTeam["fencers"];
              },
        team2:
            | ITeam
            | {
                  name: string;
                  abbreviation: string;
                  fencers: IDualMeetTeam["fencers"];
              },
        type: DualMeetType,
        season: string,
        startDate: Date,
        customName?: string,
        complementId?: string,
        eventId?: string
    ) {
        const databaseAdditions: Record<string, unknown> = {};
        const pin1 = getRandomInt(10000, 99999);
        const pin2 = getRandomInt(10000, 99999);
        const d = new Date();
        const user = this.auth.currentUser!;

        if ("id" in team1) {
            databaseAdditions[`${this.PREFIX}/teams/${team1.id}/dualMeets/${season}`] = [...(team1.dualMeets?.[season] || []), id];
        }

        if ("id" in team2) {
            databaseAdditions[`${this.PREFIX}/teams/${team2.id}/dualMeets/${season}`] = [...(team2.dualMeets?.[season] || []), id];
        }

        const team1Name = team1.name;
        const team2Name = team2.name;
        const team1Abbreviation = team1.abbreviation;
        const team2Abbreviation = team2.abbreviation;

        const [team1Roster, team2Roster] = [
            ("id" in team1 ? team1?.roster?.[season] : team1.fencers)!,
            ("id" in team2 ? team2?.roster?.[season] : team2.fencers)!
        ];

        const fencerBouts: Record<string, string[]> = {};

        let writeInIdx = 0;
        const bouts: IDualMeetBout[] = [];
        const weapons: Weapon[] = ["Sabre", "Foil", "Epee"];
        for (const weapon of weapons) {
            const stripOrder: [number, number][] = [
                [3, 6] as [number, number],
                [1, 5] as [number, number],
                [2, 4] as [number, number],
                [1, 6] as [number, number],
                [3, 4] as [number, number],
                [2, 5] as [number, number],
                [1, 4] as [number, number],
                [2, 6] as [number, number],
                [3, 5] as [number, number]
            ];
            let boutOrder = 0;

            for (const strips of stripOrder) {
                const boutID = this.database.ref(`${this.PREFIX}/bouts/`).push().key!;
                let fencer1Obj: IDualMeetBoutFencer["fencerInfo"] | undefined;
                if ("id" in team1) {
                    const fencer1ID = team1Roster?.[weapon]
                        ? Object.entries(team1Roster[weapon]).find(l => l[1].home === strips[0])?.[0]
                        : undefined;
                    const fencer1Info = fencer1ID ? await refOnce<IExistingFencer>(`${this.PREFIX}/fencers/${fencer1ID}`) : undefined;
                    if (fencer1ID && !fencerBouts[fencer1ID]) {
                        fencerBouts[fencer1ID] = fencer1Info?.bouts || [];
                    }
                    if (fencer1ID) {
                        fencerBouts[fencer1ID] = [...fencerBouts[fencer1ID], boutID];
                    }
                    // We have to do this stuff because firebase hates undefined
                    fencer1Obj = {
                        firstName: fencer1Info?.firstName || "Unknown",
                        lastName: fencer1Info?.lastName || "fencer",
                        teamName: team1.name || "Unknown team",
                        teamAbbreviation: team1.abbreviation || genAbbreviationFromName(team1.name || "") || "",
                        id: fencer1Info?.id || `writeIn${writeInIdx++}`
                    };

                    if (fencer1Info?.gradYear) fencer1Obj.gradYear = fencer1Info.gradYear;
                } else {
                    const fencer1 = team1.fencers[weapon].find(l => l.home === strips[0]);
                    fencer1Obj = {
                        firstName: fencer1?.firstName || "Unknown",
                        lastName: fencer1?.lastName || "fencer",
                        teamAbbreviation: team1Abbreviation,
                        teamName: team1Name
                    };
                }

                let fencer2Obj: IDualMeetBoutFencer["fencerInfo"] | undefined;
                if ("id" in team2) {
                    const fencer2ID = team2Roster?.[weapon]
                        ? Object.entries(team2Roster[weapon]).find(l => l[1].away === strips[1])?.[0]
                        : undefined;
                    // Potential for perf improvements here by getting all the fencers used in one batch to avoid duplicates but w/e
                    const fencer2Info = fencer2ID ? await refOnce<IExistingFencer>(`${this.PREFIX}/fencers/${fencer2ID}`) : undefined;

                    if (fencer2ID && !fencerBouts[fencer2ID]) {
                        fencerBouts[fencer2ID] = fencer2Info?.bouts || [];
                    }

                    if (fencer2ID) {
                        fencerBouts[fencer2ID] = [...fencerBouts[fencer2ID], boutID];
                    }

                    fencer2Obj = {
                        firstName: fencer2Info?.firstName || "Unknown",
                        lastName: fencer2Info?.lastName || "fencer",
                        teamName: team2.name || "Unknown team",
                        teamAbbreviation: team2.abbreviation || genAbbreviationFromName(team2.name || "") || "",
                        id: fencer2Info?.id || `writeIn${writeInIdx++}`
                    };

                    if (fencer2Info?.gradYear) fencer2Obj.gradYear = fencer2Info.gradYear;
                } else {
                    const fencer2 = team2.fencers[weapon].find(l => l.away === strips[1]);
                    fencer2Obj = {
                        firstName: fencer2?.firstName || "Unknown",
                        lastName: fencer2?.lastName || "fencer",
                        teamAbbreviation: team2Abbreviation,
                        teamName: team2Name
                    };
                }

                const bout: IDualMeetBout = {
                    id: boutID,
                    dualMeetId: id,
                    color: genRandomColor(),
                    currentSpectatorsNum: 0,
                    log: [],
                    order: boutOrder++,
                    pin: getRandomInt(10000, 99999),
                    weapon,
                    type: BoutType.DualMeet,
                    priority: null,
                    fencer1: {
                        fencerInfo: fencer1Obj!,
                        cardInfo: { yellow: false, red: 0, black: false },
                        score: 0,
                        timeout: false,
                        strip: strips[0],
                        forfeit: false
                    },
                    fencer2: {
                        fencerInfo: fencer2Obj!,
                        cardInfo: { yellow: false, red: 0, black: false },
                        score: 0,
                        timeout: false,
                        strip: strips[1],
                        forfeit: false
                    },
                    switchedSides: false,
                    boutTime: 180000
                };

                bouts.push(bout);
                databaseAdditions[`${this.PREFIX}/bouts/${bout.id}`] = bout;
            }
        }

        for (const fencerID in fencerBouts) {
            databaseAdditions[`${this.PREFIX}/fencers/${fencerID}/bouts`] = fencerBouts[fencerID];
        }

        const reorderedBouts: IDualMeetBout[] = [];

        // For each round
        for (let i = 0; i < 3; i++) {
            // For each weapon
            for (let j = 0; j < 3; j++) {
                // For each bout
                for (let k = 0; k < 3; k++) {
                    reorderedBouts.push(bouts[j * 9 + i * 3 + k]);
                }
            }
        }

        const nameStrA = type === DualMeetType.CollegeEvent ? "A" : "H";
        const nameStrB = type === DualMeetType.CollegeEvent ? "B" : "A";

        const newDualMeet: IDualMeet_DB = {
            id,
            type,
            pin1,
            pin2,
            color: `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`,
            createdAt: Number(d),
            createdBy: user.uid,
            published: false,
            season,
            name: customName || `${team1Name} (${nameStrA}) vs. ${team2Name} (${nameStrB})`,
            bouts: reorderedBouts.map(l => ({ id: l.id, winner: 0 })),
            startedAt: Number(startDate)
        };

        if ("id" in team1) {
            newDualMeet.team1ID = team1.id;
        } else {
            newDualMeet.team1WriteInData = {
                name: team1.name,
                administrators: [],
                managers: [],
                fencers: team1.fencers,
                abbreviation: team1.abbreviation || genAbbreviationFromName(team1.name)
            };
        }

        if ("id" in team2) {
            newDualMeet.team2ID = team2.id;
        } else {
            newDualMeet.team2WriteInData = {
                name: team2.name,
                administrators: [],
                managers: [],
                fencers: team2.fencers,
                abbreviation: team2.abbreviation || genAbbreviationFromName(team2.name)
            };
        }

        if (complementId) {
            newDualMeet.correspondingMeet = complementId;
            databaseAdditions[`${this.PREFIX}/dualMeets/${complementId}/correspondingMeet`] = id;
        }

        if (eventId) {
            newDualMeet.eventID = eventId;
        }

        databaseAdditions[`${this.PREFIX}/dualMeets/${id}`] = newDualMeet;

        return databaseAdditions;
    }

    public async createNewDualMeet(
        team1:
            | string
            | {
                  name: string;
                  abbreviation: string;
                  fencers: IDualMeetTeam["fencers"];
              },
        team2:
            | string
            | {
                  name: string;
                  abbreviation: string;
                  fencers: IDualMeetTeam["fencers"];
              },
        type: DualMeetType,
        season: string,
        startDate: Date,
        customName?: string,
        complementId?: string,
        eventId?: string
    ): AsyncDBResult<string> {
        const user = this.auth.currentUser!;
        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        const dualMeetId = this.database.ref(`${this.PREFIX}/dualMeets/`).push().key!;

        let team1Data: string | ITeam | { name: string; abbreviation: string; fencers: IDualMeetTeam["fencers"] } = team1;
        if (typeof team1Data === "string") {
            const response = await this.getTeam(team1Data);
            if (response.status === "fail") return response;
            team1Data = response.data;
        }
        let team2Data: string | ITeam | { name: string; abbreviation: string; fencers: IDualMeetTeam["fencers"] } = team2;
        if (typeof team2Data === "string") {
            const response = await this.getTeam(team2Data);
            if (response.status === "fail") return response;
            team2Data = response.data;
        }

        const databaseAdditions = await this.getDbUpdatesForDualMeet(
            dualMeetId,
            team1Data,
            team2Data,
            type,
            season,
            startDate,
            customName,
            complementId
        );

        await this.database.ref().update(databaseAdditions);
        return new DBSuccess(dualMeetId);
    }

    public async getDbUpdatesForDeletingDualMeet(meetID: string, teams?: Record<string, ITeam>): AsyncDBResult<Record<string, unknown>> {
        const databaseUpdates: Record<string, unknown> = {};

        const meetRequest = await this.getDualMeetRaw(meetID);
        if (meetRequest.status === "fail") return meetRequest;
        const meetData = meetRequest.data;

        // Delete all the bouts and the meet itself
        for (const bout of meetData.bouts) {
            databaseUpdates[`${this.PREFIX}/bouts/${bout.id}`] = null;
        }
        databaseUpdates[`${this.PREFIX}/dualMeets/${meetID}`] = null;

        if (meetData.team1ID) {
            const team1 = teams?.[meetData.team1ID] || (await refOnce<ITeam>(`${this.PREFIX}/teams/${meetData.team1ID}`));
            const team1Meets = [...(team1?.dualMeets?.[meetData.season] || [])];
            if (team1Meets.includes(meetID)) {
                team1Meets.splice(team1Meets.indexOf(meetID), 1);
            }
            databaseUpdates[`${this.PREFIX}/teams/${meetData.team1ID}/dualMeets/${meetData.season}`] = team1Meets;
        }
        if (meetData.team2ID) {
            const team2 = teams?.[meetData.team2ID] || (await refOnce<ITeam>(`${this.PREFIX}/teams/${meetData.team2ID}`));
            const team2Meets = [...(team2?.dualMeets?.[meetData.season] || [])];
            if (team2Meets.includes(meetID)) {
                team2Meets.splice(team2Meets.indexOf(meetID), 1);
            }
            databaseUpdates[`${this.PREFIX}/teams/${meetData.team2ID}/dualMeets/${meetData.season}`] = team2Meets;
        }

        // Get all the bouts
        const boutsData = await Promise.all(meetData.bouts.map(l => this.getBout(l.id)));

        // Remove all the events
        for (const bout of boutsData) {
            if (bout.status === "fail") continue;
            for (const event of bout.data.log || []) {
                databaseUpdates[`${this.PREFIX}/boutEvents/${event}`] = null;
            }
        }

        // Get all the participating fencers
        // We also do fencer record updates here
        const fencers: string[] = [];

        for (const bout of boutsData) {
            if (bout.status === "fail") continue;
            const fencer1ID = bout.data.fencer1.fencerInfo.id;
            const fencer2ID = bout.data.fencer2.fencerInfo.id;
            if (fencer1ID && !fencer1ID.startsWith("writeIn") && !fencers.includes(fencer1ID)) {
                fencers.push(fencer1ID);
            }
            if (fencer2ID && !fencer2ID.startsWith("writeIn") && !fencers.includes(fencer2ID)) {
                fencers.push(fencer2ID);
            }
            databaseUpdates[`${this.PREFIX}/fencerRecords/${fencer1ID}/bouts/${bout.data.id}`] = null;
            databaseUpdates[`${this.PREFIX}/fencerRecords/${fencer2ID}/bouts/${bout.data.id}`] = null;
        }

        const fencersData = await Promise.all(fencers.map(l => this.getFencer(l)));

        // Filter out every fencer's bouts and their records
        for (const fencer of fencersData) {
            if (fencer.status === "fail") continue;
            const modifiedBouts = (fencer.data.bouts || []).filter(l => !meetData.bouts.some(j => j.id === l));
            databaseUpdates[`${this.PREFIX}/fencers/${fencer.data.id}/bouts`] = modifiedBouts;
        }

        // Are we in an event?
        eventHandler: if (meetData.eventID) {
            const eventRequest = await this.getCollegeEvent(meetData.eventID);
            if (eventRequest.status === "fail") break eventHandler;
            const event = eventRequest.data;

            const mens = event.mensRounds.some(l => l.meets.some(j => j.id === meetData.id));

            if (mens) {
                const roundIdx = event.mensRounds.findIndex(l => l.meets.some(j => j.id === meetData.id));
                const filtered = event.mensRounds[roundIdx].meets.filter(l => l.id !== meetData.id);
                event.mensRounds[roundIdx].meets = filtered;
                databaseUpdates[`${this.PREFIX}/events/${event.id}/mensRounds/${roundIdx}/meets`] = filtered;

                const byeUpdates = await this.getCollegeEventByeUpdates(event, "mens");
                for (const i in byeUpdates) {
                    databaseUpdates[i] = byeUpdates[i];
                }
            }

            const womens = event.womensRounds.some(l => l.meets.some(j => j.id === meetData.id));

            if (womens) {
                const roundIdx = event.womensRounds.findIndex(l => l.meets.some(j => j.id === meetData.id));
                const filtered = event.womensRounds[roundIdx].meets.filter(l => l.id !== meetData.id);
                event.womensRounds[roundIdx].meets = filtered;
                databaseUpdates[`${this.PREFIX}/events/${event.id}/womensRounds/${roundIdx}/meets`] = filtered;

                const byeUpdates = await this.getCollegeEventByeUpdates(event, "womens");
                for (const i in byeUpdates) {
                    databaseUpdates[i] = byeUpdates[i];
                }
            }
        }

        return new DBSuccess(databaseUpdates);
    }

    public async deleteDualMeet(meetID: string): AsyncDBResult<boolean> {
        delete this.dualMeets[meetID];
        const databaseUpdates = await this.getDbUpdatesForDeletingDualMeet(meetID);
        if (databaseUpdates.status === "fail") return databaseUpdates;
        await this.database.ref().update(databaseUpdates.data);
        return new DBSuccess(true);
    }

    public setDualMeetDate(meetID: string, date: Date) {
        return new Promise(async (res, rej) => {
            const databaseUpdates = {
                [`${this.PREFIX}/dualMeets/${meetID}/startedAt`]: Number(date)
            };

            await this.database.ref().update(databaseUpdates);
            res(true);
        });
    }

    public publishDualMeet(dualMeetID: string) {
        return this.database.ref(`${this.PREFIX}/dualMeets/${dualMeetID}`).update({ published: true });
    }

    /**
     * Ends a dual meet, either prematurely or after all bouts have concluded.
     * `force` forces the dual meet to end, no matter what, without forfeitting (admin purposes or when meet has concluded).
     * `left` and `right` forfeit the side for the side provided in the argument
     */
    public async endDualMeet(id: string, forfeit: "force" | "left" | "right") {
        const databaseAdditions: Record<string, unknown> = {};

        if (forfeit !== "force") {
            const meetInfo = await refOnce<IDualMeet_DB>(`${this.PREFIX}/dualMeets/${id}`);

            const bouts = await Promise.all(meetInfo.bouts.map(l => refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${l}`)));

            for (const bout of bouts.filter(l => !l.endedAt)) {
                databaseAdditions[`${this.PREFIX}/bouts/${bout.id}/fencer${forfeit === "left" ? "1" : "2"}/forfeit`] = true;
            }
        }

        databaseAdditions[`${this.PREFIX}/dualMeets/${id}/endedAt`] = Date.now();

        this.database.ref().update(databaseAdditions);
    }

    public processMeet = async (meet: IDualMeet_DB): AsyncDBResult<IDualMeet> => {
        try {
            const realMeet: Partial<IDualMeet & IDualMeet_DB> = { ...meet };
            delete realMeet.team1Attendance;
            delete realMeet.team1AttendanceSignature;
            delete realMeet.team1ID;
            delete realMeet.team1WriteInData;
            delete realMeet.team2Attendance;
            delete realMeet.team2AttendanceSignature;
            delete realMeet.team2ID;
            delete realMeet.team2WriteInData;
            team1: if (meet.team1ID) {
                const teamRequest = await this.getTeam(meet.team1ID);
                if (teamRequest.status === "fail") break team1;
                const team = teamRequest.data;
                const seasonRoster = team.roster[meet.season];
                const meetTeamFencers: IDualMeetTeam["fencers"] = {
                    Sabre: [],
                    Foil: [],
                    Epee: []
                };
                for (const weapon in seasonRoster) {
                    const arr: IDualMeetTeam["fencers"]["Sabre"] = [];
                    for (const id in seasonRoster[weapon]) {
                        const fencer = await this.getFencer(id);
                        if (fencer.status === "fail") continue;
                        arr.push({
                            ...fencer.data,
                            home: seasonRoster[weapon][id],
                            away: 0,
                            teamName: team.name
                        });
                    }
                    meetTeamFencers[weapon] = arr;
                }
                const meetTeam: IDualMeetTeam = {
                    id: meet.team1ID,
                    administrators: team.administrators,
                    managers: team.managers,
                    name: team.name,
                    fencers: meetTeamFencers,
                    abbreviation: team.abbreviation || genAbbreviationFromName(team.name),
                    attendance: meet.team1Attendance,
                    attendanceSignature: meet.team1AttendanceSignature
                };
                realMeet.team1 = meetTeam;
            } else if (meet.team1WriteInData) {
                realMeet.team1 = meet.team1WriteInData;
            }
            team2: if (meet.team2ID) {
                const teamRequest = await this.getTeam(meet.team2ID);
                if (teamRequest.status === "fail") break team2;
                const team = teamRequest.data;
                const seasonRoster = team.roster[meet.season];
                const meetTeamFencers: IDualMeetTeam["fencers"] = {
                    Sabre: [],
                    Foil: [],
                    Epee: []
                };
                for (const weapon in seasonRoster) {
                    const arr: IDualMeetTeam["fencers"]["Sabre"] = [];
                    for (const id in seasonRoster[weapon]) {
                        const fencer = await this.getFencer(id);
                        if (fencer.status === "fail") continue;
                        arr.push({
                            ...fencer.data,
                            away: seasonRoster[weapon][id],
                            home: 0,
                            teamName: team.name
                        });
                    }
                    meetTeamFencers[weapon] = arr;
                }
                const meetTeam: IDualMeetTeam = {
                    id: meet.team2ID,
                    administrators: team.administrators,
                    managers: team.managers,
                    name: team.name,
                    fencers: meetTeamFencers,
                    abbreviation: team.abbreviation || genAbbreviationFromName(team.name),
                    attendance: meet.team2Attendance,
                    attendanceSignature: meet.team2AttendanceSignature
                };
                realMeet.team2 = meetTeam;
            } else if (meet.team2WriteInData) {
                realMeet.team2 = meet.team2WriteInData;
            }
            return new DBSuccess(realMeet as IDualMeet);
        } catch {
            return new DBError(ERROR_PROCESSING_MEET);
        }
    };

    public stopListeningDualMeetsRaw(listener?: (val: unknown) => void) {
        const l = this.listeners.get(listener);
        if (!l) return;
        removeRefListener(`${this.PREFIX}/dualMeets`, l);
    }

    public stopListeningDualMeets(listener?: (val: unknown) => void) {
        const l = this.listeners.get(listener);
        if (!l) return;
        removeRefListener(`${this.PREFIX}/dualMeets`, l);
    }

    public async signMeet(meetID: string, category: DualMeetSignatureKey | "allRefs", signature: string): AsyncDBResult<boolean> {
        if (!signature) return new DBError("No signature provided.");
        if (category === "allRefs") {
            await this.database.ref(`${this.PREFIX}/dualMeets/${meetID}/signatures`).update({
                sabreRef: signature,
                foilRef: signature,
                epeeRef: signature
            });
        } else {
            await this.database.ref(`${this.PREFIX}/dualMeets/${meetID}/signatures/${category}`).set(signature);
        }
        return new DBSuccess(true);
    }

    public async setMeetReferee(id: string, names: Partial<Record<Weapon, string>>) {
        const databaseAdditions = {};

        for (const i in names) {
            databaseAdditions[`${this.PREFIX}/dualMeets/${id}/${i.toLowerCase()}Referee`] = names[i];
        }

        await this.database.ref().update(databaseAdditions);

        return true;
    }

    public async setSingleReferee(id: string, value: boolean) {
        await this.database.ref(`${this.PREFIX}/dualMeets/${id}/singleReferee`).set(value);
        return true;
    }

    public async setAttendance(id: string, team: "team1" | "team2", fencers: ID[], signature: string) {
        await this.database.ref(`${this.PREFIX}/dualMeets/${id}/${team}Attendance`).set(fencers);
        await this.database.ref(`${this.PREFIX}/dualMeets/${id}/${team}AttendanceSignature`).set(signature);
        return true;
    }

    /**
     * In case the meet's cached score is out of sync, it checks each bout and repairs the cache score to the proper score
     * @param id
     */
    public async repairMeetScore(id: string): AsyncDBResult<boolean>;
    public async repairMeetScore(meet: IDualMeet): AsyncDBResult<boolean>;
    public async repairMeetScore(idOrMeet: string | IDualMeet_DB): AsyncDBResult<boolean> {
        let meet = idOrMeet;
        if (typeof meet === "string") {
            const meetResult = await this.getDualMeetRaw(meet);
            if (meetResult.status === "fail") return meetResult;
            meet = meetResult.data;
        }
        const databaseUpdates: Record<string, unknown> = {};
        const boutResults = await Promise.all(meet.bouts.map(l => this.getBout(l.id)));
        for (let i = 0; i < meet.bouts.length; i++) {
            const result = boutResults[i];
            if (result.status === "fail") return result;
            const winner = boutWinner(result.data, { team: true }) ?? 0;
            if (winner !== meet.bouts[i].winner) {
                databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/bouts/${i}/winner`] = winner;
            }
        }

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    // #endregion

    // #region Bouts

    public getBout(id: string, listener?: Listener<DBResult<IDualMeetBout>>, forceCache = true): AsyncDBResult<IDualMeetBout> {
        if (listener) {
            return new Promise(async (res, rej) => {
                let found = false;
                if (this.dualMeetBouts[id]) {
                    found = true;
                    res(new DBSuccess(this.dualMeetBouts[id]));
                }
                const l = v => {
                    const val: IDualMeetBout = v.val();
                    const bout: IDualMeetBout = { ...DEFAULT_BOUT, ...val };
                    if (bout) {
                        this.dualMeetBouts[id] = bout;
                        const response = new DBSuccess(bout);
                        listener(response);
                        if (!found) {
                            res(response);
                        }
                    } else {
                        return res(new DBError(ERROR_BOUT_DOES_NOT_EXIST));
                    }
                };
                this.listeners.set(listener, l);
                this.database.ref(`${this.PREFIX}/bouts/${id}`).on("value", l);
            });
        } else {
            return new Promise(async (res, rej) => {
                if (forceCache) {
                    if (id in this.dualMeetBouts) {
                        return res(new DBSuccess(this.dualMeetBouts[id]));
                    }
                }
                const bout = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${id}`);
                if (bout) {
                    this.dualMeetBouts[id] = bout;
                    res(new DBSuccess(bout));
                } else {
                    return res(new DBError(ERROR_BOUT_DOES_NOT_EXIST));
                }
            });
        }
    }

    public stopListeningBout(id: string, listener?: (val: unknown) => void) {
        const l = (listener && this.listeners.get(listener)) || listener;
        removeRefListener(`${this.PREFIX}/bouts/${id}`, l);
    }

    public async getBoutEvent(id: string): AsyncDBResult<IBoutEvent> {
        if (id in this.boutEvents) {
            return new DBSuccess(this.boutEvents[id]);
        } else {
            const event = await refOnce<IBoutEvent>(`${this.PREFIX}/boutEvents/${id}`);
            this.boutEvents[id] = event;
            if (event) {
                return new DBSuccess(event);
            } else {
                return new DBError(ERROR_BOUT_EVENT_DOES_NOT_EXIST);
            }
        }
    }

    public getBoutEventsFromArray(ids: string[]) {
        return Promise.all(ids.map(l => this.getBoutEvent(l)));
    }

    public updateBout(boutID: string, attributes: Record<string, unknown>, time?: number) {
        const d = Date.now();
        const updates: Record<string, unknown> = {
            ...attributes,
            updatedAt: d
        };
        if (time !== undefined) {
            updates.boutTime = time;
        }
        this.database.ref(`${this.PREFIX}/bouts/${boutID}`).update(updates);
    }

    public async endBout(boutData: string | IBoutCore, meetID: string, winner: BoutSide | 0): AsyncDBResult<boolean> {
        let bout = boutData;
        if (typeof bout === "string") {
            const boutResult = await this.getBout(bout);
            if (boutResult.status === "fail") return boutResult;
            bout = boutResult.data;
        }

        const d = Date.now();
        const meet = await this.getDualMeet(meetID);
        if (meet.status === "fail") return meet;
        const boutIdx = meet.data.bouts.findIndex(l => l.id === bout.id);

        let databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/bouts/${bout.id}/updatedAt`]: d,
            [`${this.PREFIX}/bouts/${bout.id}/endedAt`]: d,
            [`${this.PREFIX}/dualMeets/${meetID}/bouts/${boutIdx}/winner`]: winner
        };

        if ("dualMeetId" in bout) {
            if ("id" in bout.fencer1.fencerInfo) {
                const fencerId = bout.fencer1.fencerInfo.id;
                if (fencerId) {
                    const fencer1Updates = await this.getDbUpdatesForAddBoutToFencerRecord(fencerId, bout as IDualMeetBout, meet.data);
                    if (fencer1Updates.status === "fail") return fencer1Updates;
                    databaseUpdates = { ...databaseUpdates, ...fencer1Updates.data };
                }
            }

            if ("id" in bout.fencer2.fencerInfo) {
                const fencerId = bout.fencer2.fencerInfo.id;
                if (fencerId) {
                    const fencer2Updates = await this.getDbUpdatesForAddBoutToFencerRecord(fencerId, bout as IDualMeetBout, meet.data);
                    if (fencer2Updates.status === "fail") return fencer2Updates;
                    databaseUpdates = { ...databaseUpdates, ...fencer2Updates.data };
                }
            }
        }

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async unendBout(boutData: string | IBoutCore, meetID: string, winner: BoutSide | 0): AsyncDBResult<boolean> {
        let bout = boutData;
        if (typeof bout === "string") {
            const boutResult = await this.getBout(bout);
            if (boutResult.status === "fail") return boutResult;
            bout = boutResult.data;
        }

        const d = Date.now();
        const meet = await this.getDualMeet(meetID);
        if (meet.status === "fail") return meet;
        const boutIdx = meet.data.bouts.findIndex(l => l.id === bout.id);

        let databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/bouts/${bout.id}/updatedAt`]: d,
            [`${this.PREFIX}/dualMeets/${meetID}/bouts/${boutIdx}/winner`]: winner
        };

        if ("dualMeetId" in bout) {
            if ("id" in bout.fencer1.fencerInfo) {
                const fencerId = bout.fencer1.fencerInfo.id;
                if (fencerId) {
                    const fencer1Updates = this.getDbUpdatesForAddBoutToFencerRecord(fencerId, bout as IDualMeetBout, meet.data);
                    databaseUpdates = { ...databaseUpdates, ...fencer1Updates };
                }
            }

            if ("id" in bout.fencer2.fencerInfo) {
                const fencerId = bout.fencer2.fencerInfo.id;
                if (fencerId) {
                    const fencer2Updates = this.getDbUpdatesForAddBoutToFencerRecord(fencerId, bout as IDualMeetBout, meet.data);
                    databaseUpdates = { ...databaseUpdates, ...fencer2Updates };
                }
            }
        }

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async createBoutEvent(boutID: string, event: Omit<IBoutEvent, "id">) {
        const eventID = this.database.ref(`${this.PREFIX}/boutEvents`).push().key;

        // TODO: put revision calcuation in the actual scorer instead of getting bout data again
        const boutData = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${boutID}`);
        const eventIdx = boutData.log?.length || 0;

        const copiedEvent = { ...event, id: eventID };

        await this.database.ref(`${this.PREFIX}/boutEvents/${eventID}`).set(copiedEvent);
        this.database.ref(`${this.PREFIX}/bouts/${boutID}/log/${eventIdx}`).set(eventID);
    }

    public async reviseBoutEvent(boutID: string, eventIdx: number, event: Omit<IBoutEvent, "id">) {
        const eventID = this.database.ref(`${this.PREFIX}/boutEvents`).push().key;

        // TODO: put revision calcuation in the actual scorer instead of getting bout data again
        const boutData = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${boutID}`);

        const copiedEvent = { ...event, id: eventID };

        if (boutData?.endedAt) {
            copiedEvent.description = "Revision: " + copiedEvent.description;
        }

        await this.database.ref(`${this.PREFIX}/boutEvents/${eventID}`).set(copiedEvent);
        this.database.ref(`${this.PREFIX}/bouts/${boutID}/log/${eventIdx}`).set(eventID);
    }

    /**
     * Get database updates for bout events when substitute a fencer in for another fencer. Only replaces the old fencer's name with the new names
     * @param boutID ID for the bout whose events will be replaced
     * @param oldName Name that will be replaced
     * @param newName Name that will show in the new descriptions
     * @param options Function configuration options
     */
    public async getUpdatesToChangeNamesForEvents(
        boutID: string,
        oldName: string,
        newName: string,
        options?: { updateCache: boolean }
    ): AsyncDBResult<Record<string, unknown>> {
        const databaseUpdates: Record<string, unknown> = {};

        const bout = await this.getBout(boutID);
        if (bout.status === "fail") return bout;

        const boutEventsRaw = await this.getBoutEventsFromArray(bout.data.log);
        const boutEvents = boutEventsRaw.filter(isSuccess).map(l => l.data);

        for (const event of boutEvents) {
            const newDesc = event.description.replaceAll(oldName, newName);
            databaseUpdates[`${this.PREFIX}/boutEvents/${event.id}/description`] = newDesc;

            if (options?.updateCache) {
                if (event.id in this.boutEvents) {
                    this.boutEvents[event.id].description = newDesc;
                }
            }
        }

        return new DBSuccess(databaseUpdates);
    }

    /**
     * Changes the descriptions for all bout events to substitute a fencer in for another fencer. Only replaces the old fencer's name with the new names
     * @param boutID ID for the bout whose events will be replaced
     * @param oldName Name that will be replaced
     * @param newName Name that will show in the new descriptions
     * @param options Function configuration options
     */
    public async changeNamesForEvents(boutID: string, oldName: string, newName: string): AsyncDBResult<boolean> {
        const updates = await this.getUpdatesToChangeNamesForEvents(boutID, oldName, newName, { updateCache: true });
        if (updates.status === "fail") return updates;

        await this.database.ref().update(updates.data);
        return new DBSuccess(true);
    }

    public async subBoutFencer(boutID: string, side: "left" | "right", fencerID: string, medical: boolean): AsyncDBResult<boolean> {
        const fencerRequest = await this.getFencer(fencerID);
        if (fencerRequest.status === "fail") return fencerRequest;
        const fencer = fencerRequest.data;
        const boutData = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${boutID}`);
        const oldFencer = await refOnce<IExistingFencer>(
            `${this.PREFIX}/fencers/${(side === "left" ? boutData.fencer1 : boutData.fencer2).fencerInfo.id}`
        );

        let databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/substitution`]: true,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/firstName`]: fencer.firstName,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/lastName`]: fencer.lastName,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/gradYear`]: fencer.gradYear || null,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/id`]: fencerID,
            [`${this.PREFIX}/fencers/${fencerID}/bouts`]: [...(fencer.bouts || []), boutID]
        };

        if (medical && !boutData[side === "left" ? "fencer1" : "fencer2"].medicalFencerInfo) {
            const oldFencerData = side === "left" ? boutData.fencer1 : boutData.fencer2;
            databaseUpdates[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/medicalFencerInfo`] = oldFencerData.fencerInfo;
        }

        if (oldFencer?.bouts) {
            databaseUpdates[`${this.PREFIX}/fencers/${oldFencer.id}/bouts`] = oldFencer.bouts.filter(l => l !== boutID);
        }

        if (!medical) {
            const oldFencer = side === "left" ? boutData.fencer1 : boutData.fencer2;
            const otherFencer = side === "left" ? boutData.fencer2 : boutData.fencer1;
            let oldFencerName = `${oldFencer.fencerInfo.firstName} ${oldFencer.fencerInfo.lastName}`;
            const otherFencerName = `${otherFencer.fencerInfo.firstName} ${otherFencer.fencerInfo.lastName}`;
            if (oldFencerName === otherFencerName) {
                oldFencerName += ` `;
            }
            const eventUpdates = await this.getUpdatesToChangeNamesForEvents(
                boutID,
                oldFencerName,
                `${fencer.firstName} ${fencer.lastName}`,
                {
                    updateCache: true
                }
            );
            if (eventUpdates.status === "fail") return eventUpdates;
            for (const i in eventUpdates.data) {
                databaseUpdates[i] = eventUpdates.data[i];
            }
        }

        const recordUpdates = await this.getUpdatesToSubBoutRecord(boutID, side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2, fencerID);
        if (recordUpdates.status === "fail") return recordUpdates;
        databaseUpdates = { ...databaseUpdates, ...recordUpdates.data };
        // await this.database.ref().update(databaseUpdates);
        return new DBSuccess(true);
    }

    /**
     * @deprecated Use `subBoutWriteIn` instead
     */
    public async subBoutWriteInFencer(boutID: string, side: "left" | "right", fencer: IDualMeetBoutFencer["fencerInfo"], medical: boolean) {
        const boutData = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${boutID}`);
        const oldFencer = await refOnce<IExistingFencer>(
            `${this.PREFIX}/fencers/${(side === "left" ? boutData.fencer1 : boutData.fencer2).fencerInfo.id}`
        );
        const boutEvents = await Promise.all((boutData.log || []).map(l => refOnce<IBoutEvent>(`${this.PREFIX}/boutEvents/${l}`)));

        const databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/firstName`]: fencer.firstName,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/lastName`]: fencer.lastName,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/gradYear`]: fencer.gradYear || null,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/id`]: fencer.id || null
        };

        if (medical && !boutData[side === "left" ? "fencer1" : "fencer2"].medicalFencerInfo) {
            const oldFencerData = side === "left" ? boutData.fencer1 : boutData.fencer2;
            databaseUpdates[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/medicalFencerInfo`] = oldFencerData.fencerInfo;
        }

        if (oldFencer) {
            databaseUpdates[`${this.PREFIX}/fencers/${oldFencer.id}/bouts`] = oldFencer.bouts.filter(l => l !== boutID);
        }

        if (!medical) {
            for (const event of boutEvents) {
                const oldFencer = side === "left" ? boutData.fencer1 : boutData.fencer2;
                const modifiedDescription = event.description.replaceAll(
                    `${oldFencer.fencerInfo.firstName} ${oldFencer.fencerInfo.lastName}`,
                    `${fencer.firstName} ${fencer.lastName}`
                );

                databaseUpdates[`${this.PREFIX}/boutEvents/${event.id}/description`] = modifiedDescription;
            }
        }

        this.database.ref().update(databaseUpdates);
    }

    public async subBoutWriteIn(
        boutIDs: string[],
        teamID: string,
        season: string,
        weapon: Weapon,
        side: "left" | "right",
        info: { firstName: string; lastName: string; gradYear?: number },
        medical: boolean
    ): AsyncDBResult<IExistingFencer> {
        const dbResponse = await this.createNewFencerInTeamDatabaseObj(
            teamID,
            info.firstName,
            info.lastName,
            info.gradYear,
            season,
            weapon
        );
        if (dbResponse.status === "fail") return dbResponse;
        const { databaseAdditions, newFencer } = dbResponse.data;

        let firstBout = true;
        for (const boutID of boutIDs) {
            const boutData = await refOnce<IDualMeetBout>(`${this.PREFIX}/bouts/${boutID}`);
            const oldFencer = await refOnce<IExistingFencer>(
                `${this.PREFIX}/fencers/${(side === "left" ? boutData.fencer1 : boutData.fencer2).fencerInfo.id}`
            );
            const boutEvents = await Promise.all((boutData.log || []).map(l => refOnce<IBoutEvent>(`${this.PREFIX}/boutEvents/${l}`)));

            databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/firstName`] = info.firstName;
            databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/lastName`] = info.lastName;
            databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/gradYear`] = null;
            databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/id`] = newFencer.id;
            databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/substitution`] = true;

            if (medical && firstBout && !boutData[side === "left" ? "fencer1" : "fencer2"].medicalFencerInfo) {
                const oldFencerData = side === "left" ? boutData.fencer1 : boutData.fencer2;
                databaseAdditions[`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/medicalFencerInfo`] =
                    oldFencerData.fencerInfo;
            }

            if (oldFencer) {
                databaseAdditions[`${this.PREFIX}/fencers/${oldFencer.id}/bouts`] = (oldFencer.bouts || []).filter(l => l !== boutID);
            }

            if (!medical) {
                for (const event of boutEvents) {
                    if (!event) continue;
                    const oldFencer = side === "left" ? boutData.fencer1 : boutData.fencer2;
                    const otherFencer = side === "left" ? boutData.fencer2 : boutData.fencer1;

                    let name = `${oldFencer.fencerInfo.firstName} ${oldFencer.fencerInfo.lastName}`;
                    if (
                        oldFencer.fencerInfo.firstName === otherFencer.fencerInfo.firstName &&
                        oldFencer.fencerInfo.lastName === otherFencer.fencerInfo.lastName
                    ) {
                        name += ` (${boutData[`fencer${side === "left" ? 1 : 2}`].fencerInfo.teamName})`;
                    }
                    let newName = `${info.firstName} ${info.lastName}`;
                    if (newName === `${otherFencer.fencerInfo.firstName} ${otherFencer.fencerInfo.lastName}`) {
                        newName += ` (${boutData[`fencer${side === "left" ? 1 : 2}`].fencerInfo.teamName})`;
                    }
                    const modifiedDescription = event.description.replaceAll(name, newName);

                    databaseAdditions[`${this.PREFIX}/boutEvents/${event.id}/description`] = modifiedDescription;
                }
            }

            databaseAdditions[`${this.PREFIX}/fencers/${newFencer.id}/bouts`] = boutIDs;

            firstBout = false;

            const updates = await this.getUpdatesToSubBoutRecord(
                boutID,
                side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2,
                newFencer.id
            );
            if (updates.status === "fail") return updates;
            for (const i in updates.data) {
                databaseAdditions[i] = updates.data[i];
            }
        }

        this.database.ref().update(databaseAdditions);

        return new DBSuccess(newFencer);
    }

    public async getUpdatesToRemoveBoutFencer(
        boutID: string | IDualMeetBout,
        side: "left" | "right",
        noFencer: boolean
    ): AsyncDBResult<Record<string, unknown>> {
        let boutData = boutID;
        if (typeof boutData === "string") {
            const response = await this.getBout(boutID as string);
            if (response.status === "fail") return response;
            boutData = response.data;
        }

        const oldFencer = await refOnce<IExistingFencer>(
            `${this.PREFIX}/fencers/${(side === "left" ? boutData.fencer1 : boutData.fencer2).fencerInfo.id}`
        );
        const boutEvents = await Promise.all((boutData.log || []).map(l => refOnce<IBoutEvent>(`${this.PREFIX}/boutEvents/${l}`)));

        const databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/firstName`]: noFencer ? "No" : "Unknown",
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/lastName`]: "fencer",
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/gradYear`]: null,
            [`${this.PREFIX}/bouts/${boutID}/fencer${side === "left" ? 1 : 2}/fencerInfo/id`]: null
        };

        if (oldFencer?.bouts) {
            databaseUpdates[`${this.PREFIX}/fencers/${oldFencer.id}/bouts`] = oldFencer.bouts.filter(l => l !== boutID);
        }

        for (const event of boutEvents) {
            if (!event) continue;
            const oldFencer = side === "left" ? boutData.fencer1 : boutData.fencer2;
            const modifiedDescription = event.description.replaceAll(
                `${oldFencer.fencerInfo.firstName} ${oldFencer.fencerInfo.lastName}`,
                noFencer ? "No fencer" : "Unknown fencer"
            );

            databaseUpdates[`${this.PREFIX}/boutEvents/${event.id}/description`] = modifiedDescription;
            if (event.id in this.boutEvents) {
                this.boutEvents[event.id].description = modifiedDescription;
            }
        }

        return new DBSuccess(databaseUpdates);
    }

    public async removeBoutFencer(boutID: string | IDualMeetBout, side: "left" | "right", noFencer: boolean) {
        const updates = await this.getUpdatesToRemoveBoutFencer(boutID, side, noFencer);
        await this.database.ref().update(updates);
        return true;
    }

    public async setCurrentBoutEditor(id: string, key: string | null) {
        const databaseUpdates = {};
        databaseUpdates[`${this.PREFIX}/bouts/${id}/currentlyEditingUser`] = key;
        this.database.ref().update(databaseUpdates);
    }

    // #endregion

    // #region Events

    private async getDbUpdatesForRounds(
        rounds: ICollegeEventRound[],
        eventId: string,
        gender: "mens" | "womens",
        season: string,
        startDate: Date
    ) {
        const databaseUpdates: Record<string, unknown> = {};

        const teamIDs: Set<string> = new Set();

        for (const round of rounds) {
            for (const meet of round.meets) {
                teamIDs.add(meet.idA);
                teamIDs.add(meet.idB);
            }
            for (const bye of round.byes) {
                teamIDs.add(bye.id);
            }
        }

        const teamsArr = await Promise.all([...teamIDs].map(l => this.getTeam(l)));
        const teams: Record<string, ITeam> = {};

        for (const team of teamsArr) {
            if (team.status === "success") {
                teams[team.data.id] = team.data;
            }
        }

        const allDbUpdates = await Promise.all(
            rounds.flatMap(l =>
                l.meets.map(j => {
                    const id = this.database.ref(`${this.PREFIX}/dualMeets/`).push().key!;
                    j.id = id;
                    let teamA:
                        | ITeam
                        | {
                              name: string;
                              abbreviation: string;
                              fencers: IDualMeetTeam["fencers"];
                          } = teams[j.idA];
                    if (!teamA)
                        teamA = {
                            name: j.nameA,
                            abbreviation: j.abbA,
                            fencers: { Sabre: [], Foil: [], Epee: [] }
                        };
                    let teamB:
                        | ITeam
                        | {
                              name: string;
                              abbreviation: string;
                              fencers: IDualMeetTeam["fencers"];
                          } = teams[j.idB];
                    if (!teamB)
                        teamB = {
                            name: j.nameB,
                            abbreviation: j.abbB,
                            fencers: { Sabre: [], Foil: [], Epee: [] }
                        };
                    return this.getDbUpdatesForDualMeet(
                        id,
                        teamA,
                        teamB,
                        DualMeetType.CollegeEvent,
                        season,
                        startDate,
                        undefined,
                        undefined,
                        eventId
                    );
                })
            )
        );

        for (let i = 0; i < rounds.length; i++) {
            databaseUpdates[`${this.PREFIX}/events/${eventId}/${gender}Rounds/${i}`] = rounds[i];
        }

        for (const databaseAdditions of allDbUpdates) {
            for (const i in databaseAdditions) {
                if (i.startsWith(`${this.PREFIX}/teams/`) && i in databaseUpdates) {
                    const merged = new Set([...((databaseUpdates[i] as unknown[]) || []), ...(databaseAdditions[i] as unknown[])]);
                    databaseUpdates[i] = [...merged];
                } else if (i.startsWith(`${this.PREFIX}/fencers`) && i in databaseUpdates) {
                    const merged = new Set([...((databaseUpdates[i] as unknown[]) || []), ...(databaseAdditions[i] as unknown[])]);
                    databaseUpdates[i] = [...merged];
                } else {
                    databaseUpdates[i] = databaseAdditions[i];
                }
            }
        }

        return databaseUpdates;
    }

    public async createNewCollegeEvent(
        mensRounds: ICollegeEventRound[],
        womensRounds: ICollegeEventRound[],
        mensTeams: ICollegeEventTeam[],
        womensTeams: ICollegeEventTeam[],
        name: string,
        location: string,
        address: string,
        startDate: Date,
        hostName: string
    ): AsyncDBResult<string> {
        const user = this.auth.currentUser;
        if (!user) return new DBError(ERROR_NOT_LOGGED_IN);

        if (!name) return new DBError("No name provided.");
        if (!location) return new DBError("No location provided.");
        if (!address) return new DBError("No address provided.");
        if (!startDate) return new DBError("No start date provided.");

        const id = this.database.ref(`${this.PREFIX}/events`).push().key!;

        const seasonBase = new Date().getMonth() >= 7 ? new Date().getFullYear() : new Date().getFullYear() - 1;
        const season = `${seasonBase}-${seasonBase + 1}`;

        let databaseUpdates: Record<string, unknown> = {};
        databaseUpdates[`${this.PREFIX}/events/${id}/id`] = id;
        databaseUpdates[`${this.PREFIX}/events/${id}/name`] = name;
        databaseUpdates[`${this.PREFIX}/events/${id}/address`] = address;
        databaseUpdates[`${this.PREFIX}/events/${id}/location`] = location;
        databaseUpdates[`${this.PREFIX}/events/${id}/hostName`] = hostName;
        databaseUpdates[`${this.PREFIX}/events/${id}/createdBy`] = this.auth.currentUser!.uid;
        databaseUpdates[`${this.PREFIX}/events/${id}/createdAt`] = Date.now();
        databaseUpdates[`${this.PREFIX}/events/${id}/startedAt`] = Number(startDate);
        databaseUpdates[`${this.PREFIX}/events/${id}/refereePin`] = getRandomInt(10000, 99999);
        databaseUpdates[`${this.PREFIX}/events/${id}/season`] = season;
        databaseUpdates[`${this.PREFIX}/events/${id}/published`] = false;
        databaseUpdates[`${this.PREFIX}/events/${id}/mensTeams`] = mensTeams;
        databaseUpdates[`${this.PREFIX}/events/${id}/womensTeams`] = womensTeams;

        const [mensUpdates, womensUpdates] = await Promise.all([
            mensRounds.length ? this.getDbUpdatesForRounds(mensRounds, id, "mens", season, startDate) : undefined,
            womensRounds.length ? this.getDbUpdatesForRounds(womensRounds, id, "womens", season, startDate) : undefined
        ]);

        if (mensRounds.length) databaseUpdates = { ...databaseUpdates, ...mensUpdates };
        if (womensRounds.length) databaseUpdates = { ...databaseUpdates, ...womensUpdates };

        await this.database.ref().update(databaseUpdates);
        return new DBSuccess(id);
    }

    public getLiveCollegeEvents(listener?: Listener<DBResult<ICollegeEvent[]>>): AsyncDBResult<ICollegeEvent[]> {
        return new Promise(res => {
            const todaysDate = new Date();
            todaysDate.setHours(0, 0, 0, 0);
            const startTimeStamp = todaysDate.getTime(); // yesterday
            const endTimeStamp = startTimeStamp + 86400000; // tomorrow
            this.database
                .ref(`${this.PREFIX}/events`)
                .orderByChild("startedAt")
                .startAt(startTimeStamp)
                .endAt(endTimeStamp)
                .on("value", async queryResults => {
                    const val: Record<string, ICollegeEvent> | null = queryResults.val();

                    const resolved = val
                        ? Object.values(val)
                              .filter(l => l.published)
                              .map(val => {
                                  const event = { ...DEFAULT_EVENT, ...val };
                                  //@ts-ignore
                                  event.mensRounds = event.mensRounds.map(l => ({ byes: [], meets: [], ...l }));
                                  //@ts-ignore
                                  event.womensRounds = event.womensRounds.map(l => ({ byes: [], meets: [], ...l }));
                                  return event;
                              })
                        : [];

                    resolved.sort((a, b) => Number(b.startedAt) - Number(a.startedAt));

                    listener && listener(new DBSuccess(resolved));
                    res(new DBSuccess(resolved));
                });
        });
    }

    public getCollegeEventsRaw(listener?: Listener<DBResult<Record<string, ICollegeEvent>>>): AsyncDBResult<Record<string, ICollegeEvent>> {
        if (listener) {
            return new Promise(res => {
                this.database.ref(`${this.PREFIX}/events`).on("value", v => {
                    const val = v.val() || {};
                    for (const id in val) {
                        const event = { ...DEFAULT_EVENT, ...val[id] };
                        event.mensRounds = event.mensRounds.map(l => ({
                            byes: [],
                            meets: [],
                            ...l
                        }));
                        event.womensRounds = event.womensRounds.map(l => ({
                            byes: [],
                            meets: [],
                            ...l
                        }));
                        val[id] = event;
                    }
                    listener(new DBSuccess(val));
                    res(new DBSuccess(val));
                });
            });
        } else {
            return new Promise(res => {
                this.database.ref(`${this.PREFIX}/events`).once("value", v => {
                    const val = v.val() || {};
                    for (const id in val) {
                        const event = { ...DEFAULT_EVENT, ...val[id] };
                        event.mensRounds = event.mensRounds.map(l => ({
                            byes: [],
                            meets: [],
                            ...l
                        }));
                        event.womensRounds = event.womensRounds.map(l => ({
                            byes: [],
                            meets: [],
                            ...l
                        }));
                        val[id] = event;
                    }
                    res(new DBSuccess(val));
                });
            });
        }
    }

    public stopListeningCollegeEventsRaw(listener?: (v: Record<string, ICollegeEvent>) => void) {
        removeRefListener(`${this.PREFIX}/events`, listener);
    }

    public async getCollegeEvent(id: string, listener?: Listener<DBResult<ICollegeEvent>>): AsyncDBResult<ICollegeEvent> {
        const processEvent = (event: ICollegeEvent) => {
            const val = { ...DEFAULT_EVENT, ...event };
            // @ts-ignore
            val.mensRounds = val.mensRounds.filter(Boolean).map(j => ({ byes: [], meets: [], ...j }));
            // @ts-ignore
            val.womensRounds = val.womensRounds.filter(Boolean).map(j => ({ byes: [], meets: [], ...j }));
            val.createdAt = new Date(val.createdAt);
            val.startedAt = new Date(val.startedAt);
            return val;
        };

        if (listener) {
            return new Promise(async res => {
                const l = async v => {
                    const val = v.val() as ICollegeEvent;
                    const response = new DBSuccess(processEvent(val));
                    listener && listener(response);
                    res(response);
                };

                this.database.ref(`${this.PREFIX}/events/${id}`).on("value", l);
                this.listeners.set(listener, l);
            });
        } else {
            const val = await refOnce<ICollegeEvent>(`${this.PREFIX}/events/${id}`);
            if (val) {
                return new DBSuccess(processEvent(val));
            } else {
                return new DBError(ERROR_EVENT_DOES_NOT_EXIST);
            }
        }
    }

    public stopListeningCollegeEvent(id: string, listener?: Listener<DBResult<ICollegeEvent>>) {
        removeRefListener(`${this.PREFIX}/events/${id}`, listener);
    }

    public async publishCollegeEvent(id: string): AsyncDBResult<boolean>;
    public async publishCollegeEvent(eventData: ICollegeEvent): AsyncDBResult<boolean>;
    public async publishCollegeEvent(eventDataOrId: string | ICollegeEvent): AsyncDBResult<boolean> {
        let eventData = eventDataOrId;
        if (typeof eventData === "string") {
            const eventRequest = await this.getCollegeEvent(eventData);
            if (eventRequest.status === "fail") return eventRequest;
            eventData = eventRequest.data;
        }
        const currentUser = await this.getCurrentUserInfo();
        if (currentUser.status === "fail") return currentUser;

        const date = Date.now();
        const databaseUpdates: Record<string, unknown> = {};

        const meetIDs: Set<string> = new Set();

        const allRounds = [...eventData.mensRounds, ...eventData.womensRounds];

        for (const round of allRounds) {
            for (const meet of round.meets) {
                meetIDs.add(meet.id);
            }
        }

        databaseUpdates[`${this.PREFIX}/events/${eventData.id}/published`] = true;

        for (const meet of meetIDs) {
            databaseUpdates[`${this.PREFIX}/dualMeets/${meet}/published`] = true;
            databaseUpdates[`${this.PREFIX}/dualMeets/${meet}/publishedAt`] = date;
            databaseUpdates[`${this.PREFIX}/dualMeets/${meet}/publishedBy`] = currentUser.data.id;
        }

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async deleteCollegeEvent(id: string): AsyncDBResult<boolean>;
    public async deleteCollegeEvent(eventData: ICollegeEvent): AsyncDBResult<boolean>;
    public async deleteCollegeEvent(eventDataOrId: string | ICollegeEvent): AsyncDBResult<boolean> {
        let eventData = eventDataOrId;
        if (typeof eventData === "string") {
            const eventRequest = await this.getCollegeEvent(eventData);
            if (eventRequest.status === "fail") return eventRequest;
            eventData = eventRequest.data;
        }

        const allRounds = [...eventData.mensRounds, ...eventData.womensRounds];

        const databaseUpdates: Record<string, unknown> = {};

        let teamIDs: Set<string> = new Set();

        for (const round of allRounds) {
            for (const meet of round.meets) {
                if (!teamIDs.has(meet.idA)) teamIDs.add(meet.idA);
                if (!teamIDs.has(meet.idB)) teamIDs.add(meet.idB);
            }
            for (const bye of round.byes) {
                teamIDs.add(bye.id);
            }
        }

        teamIDs = new Set([...teamIDs].filter(l => !l.startsWith("writeIn")));

        const teamsData = await Promise.all([...teamIDs].map(l => this.getTeam(l)));
        const teams: Record<string, ITeam> = {};
        for (const team of teamsData) {
            if (team.status === "fail") continue;
            teams[team.data.id] = team.data;
        }

        const meetIDs = allRounds.flatMap(l => l.meets.map(j => j.id));
        const allMeetUpdatesPromises = await Promise.allResolved(meetIDs.map(l => this.getDbUpdatesForDeletingDualMeet(l)));
        const allMeetUpdates = allMeetUpdatesPromises
            .filter(l => l.status === "success")
            .map(l => (l as DBSuccess<Record<string, unknown>>).data);
        for (const meetUpdates of allMeetUpdates) {
            for (const ref in meetUpdates) {
                if (ref.startsWith(`${this.PREFIX}/fencers`)) {
                    const fencerArr = databaseUpdates[ref] as string[];
                    if (fencerArr) {
                        databaseUpdates[ref] = fencerArr.filter(l => (meetUpdates[ref] as string[]).includes(l));
                        continue;
                    }
                }
                if (ref.startsWith(`${this.PREFIX}/teams`)) {
                    const teamArr = databaseUpdates[ref] as string[];
                    if (teamArr) {
                        databaseUpdates[ref] = teamArr.filter(l => (meetUpdates[ref] as string[]).includes(l));
                        continue;
                    }
                }
                if (ref.endsWith("/byes") || ref.endsWith("/meets")) {
                    continue;
                }
                databaseUpdates[ref] = meetUpdates[ref];
            }
        }

        databaseUpdates[`${this.PREFIX}/events/${eventData.id}`] = null;

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async addMeetToCollegeEvent(eventId: string, meetId: string, gender: "mens" | "womens", round: number): AsyncDBResult<boolean> {
        const event = await this.getCollegeEvent(eventId);
        if (event.status === "fail") return event;

        const meetRequest = await this.getDualMeet(meetId);
        if (meetRequest.status === "fail") return meetRequest;
        const meet = meetRequest.data;
        if (!meet.team1.id || !meet.team2.id) {
            return new DBError(ERROR_UNKNOWN);
        }

        const [team1Request, team2Request] = await Promise.all([this.getTeam(meet.team1.id), this.getTeam(meet.team2.id)]);
        if (team1Request.status === "fail") return team1Request;
        if (team2Request.status === "fail") return team2Request;
        const team1 = team1Request.data;
        const team2 = team2Request.data;

        const newMeet: ICollegeEventRoundMeet = {
            abbA: team1.abbreviation || team1.name,
            abbB: team2.abbreviation || team2.name,
            id: meet.id,
            idA: team1.id,
            idB: team2.id,
            nameA: team1.name,
            nameB: team2.name,
            regionA: team1.region,
            regionB: team1.region
        };

        const existingMeetCount = event[gender === "mens" ? "mensRounds" : "womensRounds"][round].meets.length;
        event[gender === "mens" ? "mensRounds" : "womensRounds"][round].meets.push(newMeet);

        const byeUpdates = await this.getCollegeEventByeUpdates(event.data, gender);
        const databaseUpdates = {
            [`${this.PREFIX}/events/${eventId}/${gender}Rounds/${round}/meets/${existingMeetCount}`]: newMeet,
            [`${this.PREFIX}/dualMeets/${meetId}/eventID`]: eventId,
            ...byeUpdates
        };

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async getCollegeEventByeUpdates(
        eventId: string | ICollegeEvent,
        gender: "mens" | "womens"
    ): AsyncDBResult<Record<string, unknown>> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        const rounds = gender === "mens" ? event.mensRounds : event.womensRounds;
        const relevantTeams = gender === "mens" ? event.mensTeams : event.womensTeams;

        const changesNeeded: number[] = [];

        for (let i = 0; i < rounds.length; i++) {
            const round = rounds[i];

            const oldByes = round.byes.map(l => l.id);

            const teamsInRound = round.meets.flatMap(l => (l ? [l.idA, l.idB] : []));
            const noDuplicates = [...new Set(teamsInRound)];
            const otherTeams = relevantTeams.filter(l => !noDuplicates.includes(l.id));

            if (oldByes.every(l => otherTeams.some(j => j.id === l)) && otherTeams.every(l => oldByes.includes(l.id))) {
                continue;
            }

            changesNeeded.push(i);

            round.byes = await Promise.all(
                otherTeams.map(async l => ({
                    id: l.id,
                    abb: l.abbreviation,
                    name: l.name,
                    // TODO: make this type safe but fr
                    region: ((await this.getTeam(l.id)) as DBSuccess<ITeam>)!.data.region
                }))
            );
        }

        const databaseUpdates: Record<string, unknown> = {};

        for (const change of changesNeeded) {
            databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${change}/byes`] = rounds[change].byes;
        }

        return new DBSuccess(databaseUpdates);
    }

    public async moveCollegeEventMeetUp(
        eventId: string | ICollegeEvent,
        gender: "mens" | "womens",
        meet: ICollegeEventRoundMeet
    ): AsyncDBResult<boolean> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        const rounds = gender === "mens" ? event.mensRounds : event.womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.some(j => j.id === meet.id));
        const meetIdx = rounds[roundIdx].meets.findIndex(j => j.id === meet.id);

        if (roundIdx <= 0) return new DBError(ERROR_CANNOT_MOVE_MEET);

        const aboveMeet = rounds[roundIdx - 1].meets[meetIdx];

        rounds[roundIdx].meets[meetIdx] = aboveMeet;
        rounds[roundIdx - 1].meets[meetIdx] = meet;

        rounds[roundIdx].meets = rounds[roundIdx].meets.filter(l => Boolean(l));
        rounds[roundIdx - 1].meets = rounds[roundIdx - 1].meets.filter(l => Boolean(l));

        let databaseUpdates: Record<string, unknown> = {
            [`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx}/meets`]: rounds[roundIdx].meets,
            [`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx - 1}/meets`]: rounds[roundIdx - 1].meets
        };

        const byeUpdates = await this.getCollegeEventByeUpdates(event, gender);
        databaseUpdates = { ...databaseUpdates, ...byeUpdates };
        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async moveCollegeEventMeetDown(
        eventId: string | ICollegeEvent,
        gender: "mens" | "womens",
        meet: ICollegeEventRoundMeet
    ): AsyncDBResult<boolean> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        const rounds = gender === "mens" ? event.mensRounds : event.womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.some(j => j.id === meet.id));
        const meetIdx = rounds[roundIdx].meets.findIndex(j => j.id === meet.id);

        if (roundIdx === -1 || roundIdx >= rounds.length - 1) return new DBError(ERROR_CANNOT_MOVE_MEET);

        const belowMeet = rounds[roundIdx + 1].meets[meetIdx];

        rounds[roundIdx].meets[meetIdx] = belowMeet;
        rounds[roundIdx + 1].meets[meetIdx] = meet;

        rounds[roundIdx].meets = rounds[roundIdx].meets.filter(l => Boolean(l));
        rounds[roundIdx + 1].meets = rounds[roundIdx + 1].meets.filter(l => Boolean(l));

        let databaseUpdates: Record<string, unknown> = {};

        databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx}/meets`] = rounds[roundIdx].meets;
        databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx + 1}/meets`] = rounds[roundIdx + 1].meets;

        const byeUpdates = await this.getCollegeEventByeUpdates(event, gender);

        databaseUpdates = { ...databaseUpdates, ...byeUpdates };

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async moveCollegeEventMeetLeft(
        eventId: string | ICollegeEvent,
        gender: "mens" | "womens",
        meet: ICollegeEventRoundMeet
    ): AsyncDBResult<boolean> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        const rounds = gender === "mens" ? event.mensRounds : event.womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.some(j => j.id === meet.id));
        const round = rounds[roundIdx];
        const meetIdx = rounds[roundIdx].meets.findIndex(j => j.id === meet.id);

        if (meetIdx <= 0) return new DBError(ERROR_CANNOT_MOVE_MEET);

        const leftMeet = round.meets[meetIdx - 1];

        round.meets[meetIdx] = leftMeet;
        round.meets[meetIdx - 1] = meet;

        let databaseUpdates: Record<string, unknown> = {};

        databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx}/meets`] = rounds[roundIdx].meets;

        const byeUpdates = await this.getCollegeEventByeUpdates(event, gender);

        databaseUpdates = { ...databaseUpdates, ...byeUpdates };

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async moveCollegeEventMeetRight(
        eventId: string | ICollegeEvent,
        gender: "mens" | "womens",
        meet: ICollegeEventRoundMeet
    ): AsyncDBResult<boolean> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        const rounds = gender === "mens" ? event.mensRounds : event.womensRounds;
        const roundIdx = rounds.findIndex(l => l.meets.some(j => j.id === meet.id));
        const round = rounds[roundIdx];
        const meetIdx = rounds[roundIdx].meets.findIndex(j => j.id === meet.id);

        if (meetIdx >= round.meets.length - 1) return new DBError(ERROR_CANNOT_MOVE_MEET);

        const rightMeet = round.meets[meetIdx + 1];

        round.meets[meetIdx] = rightMeet;
        round.meets[meetIdx + 1] = meet;

        let databaseUpdates: Record<string, unknown> = {};

        databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx}/meets`] = rounds[roundIdx].meets;

        const byeUpdates = await this.getCollegeEventByeUpdates(event, gender);

        databaseUpdates = { ...databaseUpdates, ...byeUpdates };

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async switchSidesOfEventMeet(eventId: string | ICollegeEvent, eventMeet: ICollegeEventRoundMeet): AsyncDBResult<boolean> {
        let event = eventId;
        if (typeof event === "string") {
            const eventRequest = await this.getCollegeEvent(event);
            if (eventRequest.status === "fail") return eventRequest;
            event = eventRequest.data;
        }

        let gender: "mens" | "womens" | null = null;
        let roundIdx = -1;
        let meetIdx = -1;
        {
            roundIdx = event.mensRounds.findIndex(l => l.meets.some(j => j.id === eventMeet.id));
            if (roundIdx !== -1) {
                meetIdx = event.mensRounds[roundIdx].meets.findIndex(j => j.id === eventMeet.id);
                gender = "mens";
            }
        }
        if (!gender) {
            roundIdx = event.womensRounds.findIndex(l => l.meets.some(j => j.id === eventMeet.id));
            if (roundIdx !== -1) {
                meetIdx = event.womensRounds[roundIdx].meets.findIndex(j => j.id === eventMeet.id);
                gender = "womens";
            }
        }

        if (!gender) {
            return new DBError(ERROR_MEET_NOT_IN_SCHEDULE);
        }

        const databaseUpdates = {};

        const swappedEventMeet: ICollegeEventRoundMeet = {
            id: eventMeet.id,
            abbA: eventMeet.abbB,
            abbB: eventMeet.abbA,
            idA: eventMeet.idB,
            idB: eventMeet.idA,
            nameA: eventMeet.nameB,
            nameB: eventMeet.nameA,
            regionA: eventMeet.regionB,
            regionB: eventMeet.regionA
        };

        databaseUpdates[`${this.PREFIX}/events/${event.id}/${gender}Rounds/${roundIdx}/meets/${meetIdx}`] = swappedEventMeet;

        const meetRequest = await this.getDualMeetRaw(eventMeet.id);
        if (meetRequest.status === "fail") return meetRequest;
        const meet = meetRequest.data;

        const splitName = meet.name.split(" vs. ");
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/name`] = `${splitName[1]} vs. ${splitName[0]}`;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team1ID`] = meet.team2ID || null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team2ID`] = meet.team1ID || null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team1WriteInData`] = meet.team2WriteInData || null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team2WriteInData`] = meet.team1WriteInData || null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team1Lineup`] = null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/team2Lineup`] = null;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/lineupsVisibleSabre`] = false;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/lineupsVisibleFoil`] = false;
        databaseUpdates[`${this.PREFIX}/dualMeets/${meet.id}/lineupsVisibleEpee`] = false;

        const boutRequests = await Promise.all(meet.bouts.map(l => this.getBout(l.id)));
        const bouts = boutRequests.filter(isSuccess).map(l => l.data);
        const boutUpdates = await Promise.all([
            ...bouts.map(l => this.getUpdatesToRemoveBoutFencer(l, "left", false)),
            ...bouts.map(l => this.getUpdatesToRemoveBoutFencer(l, "right", false))
        ]);

        for (const updates of boutUpdates) {
            for (const ref in updates) {
                if (ref in databaseUpdates && ref.endsWith("bouts")) {
                    const prev = databaseUpdates[ref] as string[];
                    const intersection = prev.filter(l => (updates[ref] as string[]).includes(l));
                    databaseUpdates[ref] = intersection;
                } else {
                    databaseUpdates[ref] = updates[ref];
                }
            }
        }

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async processLineups(meetId: string): AsyncDBResult<boolean> {
        const lineupsObj = {
            1: [1, 9, 18],
            2: [2, 11, 19],
            3: [0, 10, 20],
            4: [2, 10, 18],
            5: [1, 11, 20],
            6: [0, 9, 19]
        };

        const weaponOffsets = {
            Sabre: 0,
            Foil: 3,
            Epee: 6
        };

        const meetData = await this.getDualMeet(meetId);
        if (meetData.status === "fail") return meetData;
        const meet = meetData.data;

        if (!meet.team1Lineup || !meet.team2Lineup) return new DBError("No lineups were provided.");

        const databaseUpdates: Record<string, unknown> = {};

        const bouts = meet.bouts.map(l => l.id);

        const processWeapon = (weapon: Weapon) => {
            const lineup1 = meet.team1Lineup?.[weapon as Weapon];
            const lineup2 = meet.team2Lineup?.[weapon as Weapon];
            if (!lineup1 && !lineup2) return;

            const forfeits1 = new Set<number>();
            const forfeits2 = new Set<number>();
            const doubleForfeits = new Set<number>();

            for (const id in lineup1) {
                const fencer = lineup1[Number(id) as 1 | 2 | 3 | 4 | 5 | 6];
                if (!fencer) continue;
                if ("type" in fencer) {
                    for (let i = 0; i < 3; i++) {
                        const properIdx = lineupsObj[Number(id)][i] + weaponOffsets[weapon];
                        this.updateBout(bouts[properIdx], {
                            [`fencer1/forfeit`]: true
                        });
                        forfeits1.add(properIdx);
                    }
                } else if ("id" in fencer) {
                    for (let i = 0; i < 3; i++) {
                        const properIdx = lineupsObj[Number(id)][i] + weaponOffsets[weapon];
                        this.subBoutFencer(bouts[properIdx], "left", fencer.id!, false);
                    }
                } else {
                    const indices = [0, 1, 2].map(i => lineupsObj[Number(id)][i] + weaponOffsets[weapon]);
                    const boutsToSub = indices.map(l => bouts[l]);
                    this.subBoutWriteIn(boutsToSub, meet.team1.id!, meet.season, weapon, "left", fencer, false);
                }
            }
            for (const id in lineup2) {
                const fencer = lineup2[Number(id) as 1 | 2 | 3 | 4 | 5 | 6];
                if (!fencer) continue;
                if ("type" in fencer) {
                    for (let i = 0; i < 3; i++) {
                        const properIdx = lineupsObj[Number(id)][i] + weaponOffsets[weapon];
                        this.updateBout(bouts[properIdx], {
                            [`fencer2/forfeit`]: true
                        });
                        if (forfeits1.has(properIdx)) {
                            forfeits1.delete(properIdx);
                            doubleForfeits.add(properIdx);
                        } else {
                            forfeits2.add(properIdx);
                        }
                    }
                } else if ("id" in fencer) {
                    for (let i = 0; i < 3; i++) {
                        const properIdx = lineupsObj[Number(id)][i] + weaponOffsets[weapon];
                        this.subBoutFencer(bouts[properIdx], "right", fencer.id!, false);
                    }
                } else {
                    const indices = [0, 1, 2].map(i => lineupsObj[Number(id)][i] + weaponOffsets[weapon]);
                    const boutsToSub = indices.map(l => bouts[l]);
                    this.subBoutWriteIn(boutsToSub, meet.team2.id!, meet.season, weapon, "right", fencer, false);
                }
            }

            for (const id of forfeits1) {
                this.endBout(bouts[id], meetId, BoutSide.Fencer2);
            }
            for (const id of forfeits2) {
                this.endBout(bouts[id], meetId, BoutSide.Fencer1);
            }
            for (const id of doubleForfeits) {
                this.endBout(bouts[id], meetId, 0);
            }

            databaseUpdates[`${this.PREFIX}/dualMeets/${meetId}/lineupsVisible${weapon}`] = true;
        };

        if (meet.team1Lineup.Sabre && meet.team2Lineup.Sabre && !meet.lineupsVisibleSabre) processWeapon("Sabre");
        if (meet.team1Lineup.Foil && meet.team2Lineup.Foil && !meet.lineupsVisibleFoil) processWeapon("Foil");
        if (meet.team1Lineup.Epee && meet.team2Lineup.Epee && !meet.lineupsVisibleEpee) processWeapon("Epee");

        await this.database.ref().update(databaseUpdates);

        return new DBSuccess(true);
    }

    public async addLineupToMeet(meetId: string, side: 1 | 2, weapon: Weapon, lineup: Lineup): AsyncDBResult<boolean> {
        try {
            const databaseUpdates: Record<string, unknown> = {};

            for (const i in lineup[weapon]) {
                if (lineup[weapon][i] && !lineup[weapon][i].gradYear) {
                    // In case of undefined
                    delete lineup[weapon][i].gradYear;
                }
                databaseUpdates[`${this.PREFIX}/dualMeets/${meetId}/team${side}Lineup/${weapon}/${i}`] = lineup[weapon][i];
            }

            await this.database.ref().update(databaseUpdates);
            await this.processLineups(meetId);
            return new DBSuccess(true);
        } catch (e: unknown) {
            console.error("Error adding lineups", e);
            return new DBError(ERROR_ADDING_LINEUPS);
        }
    }

    // #endregion

    // #region Misc

    public getOnline(listener?: Listener<boolean>): Promise<boolean> {
        return new Promise(async res => {
            if (listener) {
                const l = v => {
                    const val = v.val();
                    listener && listener(val);
                    res(val);
                };
                this.listeners.set(listener, l);
                this.database.ref(".info/connected").on("value", l);
            } else {
                this.database.ref(".info/connected").once("value", v => void res(v.val()));
            }
        });
    }

    public stopListeningOnline(listener: Listener<boolean>) {
        if (this.listeners.has(listener)) {
            this.database.ref(".info/connected").off("value", this.listeners.get(listener));
        }
    }

    // #endregion
}

class COMMON_DB_BASE {
    public PREFIX = "/common";
    private database = fire.database();
    private auth = fire.auth();

    public async createUser(uid: string, firstName: string, lastName: string, email: string): AsyncDBResult<IUser> {
        try {
            const now = Date.now();
            const newUser: IUser = {
                id: uid,
                firstName,
                lastName,
                email,
                createdAt: now,
                updatedAt: now,
                verificationPin: getRandomInt(10000, 99999),
                flags: 1,
                teams: [],
                managingTeams: [],
                linkedFencerIds: []
            };
            await this.database.ref(`${this.PREFIX}/users/${uid}`).set(newUser);
            return new DBSuccess(newUser);
        } catch (e: unknown) {
            return new DBError(ERROR_UNKNOWN);
        }
    }

    public async getCurrentUserInfo(listener?: Listener<DBResult<IUser>>): AsyncDBResult<IUser> {
        if (listener) {
            return new Promise(async res => {
                if (this.auth.currentUser?.uid) {
                    this.database.ref(`${this.PREFIX}/users/${this.auth.currentUser!.uid}`).on("value", queryResults => {
                        const val = queryResults.val();
                        const defaultedVal: IUser = { ...DEFAULT_USER, ...val };
                        defaultedVal.managingTeams = defaultedVal.managingTeams.filter(Boolean);
                        defaultedVal.teams = defaultedVal.teams.filter(Boolean);
                        const response = new DBSuccess(defaultedVal);
                        listener && listener(response);
                        res(response);
                    });
                } else {
                    const err = new DBError(ERROR_NOT_LOGGED_IN);
                    listener && listener(err);
                    res(err);
                }
            });
        } else {
            const v = await this.database.ref(`${this.PREFIX}/users/${this.auth.currentUser!.uid}`).once("value");
            const val = v.val();
            const defaultedVal: IUser = { ...DEFAULT_USER, ...val };
            defaultedVal.managingTeams = defaultedVal.managingTeams.filter(Boolean);
            defaultedVal.teams = defaultedVal.teams.filter(Boolean);
            return new DBSuccess(defaultedVal);
        }
    }

    public getUserInfo(userId: string): AsyncDBResult<IUser> {
        return new Promise(async res => {
            this.database.ref(`${this.PREFIX}/users/${userId}`).once("value", queryResults => {
                const dbVal = queryResults.val();
                if (!dbVal) {
                    return res(new DBError(ERROR_USER_DOES_NOT_EXIST));
                }
                const val: IUser = { ...DEFAULT_USER, ...dbVal };
                val.teams = val.teams.filter(Boolean);
                res(new DBSuccess(val));
            });
        });
    }

    // TODO: implement this
    public linkUserToFencer() {}

    public getUserList(listener?: Listener<DBResult<Record<string, IUser>>>): AsyncDBResult<Record<string, IUser>> {
        return new Promise(async res => {
            try {
                this.database
                    .ref(`${this.PREFIX}/users`)
                    .orderByChild("lastName")
                    .once("value", queryResults => {
                        const val = queryResults.val();
                        for (const i in val) {
                            val[i] = { ...DEFAULT_USER, ...val[i] };
                        }
                        const response = new DBSuccess(val);
                        res(response);
                        listener && listener(response);
                    });
            } catch {
                res(new DBError(ERROR_UNKNOWN));
            }
        });
    }

    public stopListeningUserList() {
        removeRefListener(`${this.PREFIX}/users`);
    }
}

const COMMON_DB = new COMMON_DB_BASE();
