import { mdiSwapHorizontal, mdiChevronUp, mdiChevronDown, mdiStop, mdiPlay, mdiSquare, mdiSquareOutline, mdiMedicalBag } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../hooks/window";
import { BoutSide, Division, IBoutCore, IBoutEvent, IDualMeet, IDualMeetBout, IExistingFencer, IWriteInFencer } from "../../types";
import BoutScorer from "../../utils/scorer";
import {
    Box,
    Button,
    FormControl,
    Typography,
    TextField,
    Modal,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@mui/material";
import { modalBoxStyle } from "../..";

import "./BoutScorerComponent.css";
import useDatabase from "../../hooks/database";
import useDivision from "../../hooks/divison";
import StyledDropdown from "../StyledDropdown/StyledDropdown";
import { MedicalIcon } from "../MedicalIcon";
import { MedicalDialog } from "../MedicalDialog";
import { isSuccess } from "../../utils/database";

const timeoutLength = 30;

type BoutScorerComponentFencers = Record<string, Map<IExistingFencer | IWriteInFencer, number>>;

type BoutScorerComponentProps = {
    id: string;
    bout: IDualMeetBout;
    bouts: string[];
    team1Fencers: BoutScorerComponentFencers;
    team2Fencers: BoutScorerComponentFencers;
    header?: ReactElement;
    light?: boolean;
    dualMeetData: IDualMeet;
    setTeam1Fencers: React.Dispatch<React.SetStateAction<BoutScorerComponentFencers>>;
    setTeam2Fencers: React.Dispatch<React.SetStateAction<BoutScorerComponentFencers>>;
};

interface CenterColProps {
    mobile: boolean;
    boutRunning: boolean;
    timerRunning: boolean;
    bout: IBoutCore;
    boutLog: IBoutEvent[];
    boutTime: number;
    switchSides: () => void;
    addTime: (v: number) => void;
    startTimer: () => void;
    stopTimer: () => void;
    addScore: (v: "left" | "right" | "double") => void;
    setPriority: (v: "left" | "right" | "random") => void;
}

const CenterCol = ({
    mobile,
    boutRunning,
    timerRunning,
    bout,
    boutLog,
    boutTime,
    switchSides,
    addTime,
    startTimer,
    stopTimer,
    addScore,
    setPriority
}: CenterColProps) => (
    <div
        className="dualMeetScorerCenterCol"
        style={{
            height: mobile ? (boutRunning ? 270 : 165) : boutRunning ? "calc(100% - 10px)" : "100%"
        }}
    >
        <div className="dualMeetScorerTimeControllerContainer">
            {boutRunning && (
                <div className="dualMeetScorerSwitchSides" onClick={switchSides}>
                    <Icon path={mdiSwapHorizontal} size="20px" />
                </div>
            )}
            <div className="dualMeetScorerTime">
                {boutRunning && (
                    <div className="dualMeetScorerTimeButtonBox">
                        <div className="dualMeetScorerTimeButton" onClick={() => addTime(60000)}>
                            <Icon className="dualMeetScorerTimeButtonIcon" path={mdiChevronUp} size="12px" color="#777" />
                        </div>
                        <div className="dualMeetScorerTimeButton" onClick={() => addTime(-60000)}>
                            <Icon className="dualMeetScorerTimeButtonIcon" path={mdiChevronDown} size="12px" color="#777" />
                        </div>
                    </div>
                )}
                <h3 style={{ userSelect: "none" }}>{Math.floor(boutTime / 60000)}:</h3>
                <h3 style={{ userSelect: "none" }}>
                    {Math.floor((boutTime % 60000) / 1000)
                        .toString()
                        .padStart(2, "0")}
                </h3>
                {boutRunning && (
                    <div className="dualMeetScorerTimeButtonBox">
                        <div className="dualMeetScorerTimeButton" onClick={() => addTime(1000)}>
                            <Icon className="dualMeetScorerTimeButtonIcon" path={mdiChevronUp} size="12px" color="#777" />
                        </div>
                        <div className="dualMeetScorerTimeButton" onClick={() => addTime(-1000)}>
                            <Icon className="dualMeetScorerTimeButtonIcon" path={mdiChevronDown} size="12px" color="#777" />
                        </div>
                    </div>
                )}
            </div>
            {boutRunning && (
                <div
                    className="dualMeetScorerTimeController"
                    onClick={() => {
                        if (timerRunning) {
                            stopTimer();
                        } else {
                            startTimer();
                        }
                    }}
                    style={{
                        backgroundColor: timerRunning ? "rgb(240,128,128)" : "rgb(100,255,160)",
                        borderColor: timerRunning ? "red" : "green"
                    }}
                >
                    <Icon path={timerRunning ? mdiStop : mdiPlay} size="20px" />
                </div>
            )}
        </div>
        <div className="dualMeetScorerBoutLog" style={{ height: `calc(100% - ${boutRunning ? 140 : 70}px)` }}>
            {[...boutLog]?.reverse()?.map(event => (
                <div className="dualMeetScorerBoutEvent" key={`boutEvent${event.id}`}>
                    {event.description}.
                    <br />
                    <strong>
                        Score: {bout.switchedSides ? event.score2 : event.score1} - {bout.switchedSides ? event.score1 : event.score2}
                    </strong>
                    <br />
                    Bout Clock: {Math.floor(event.boutTime / 60000)}:{(Math.floor(event.boutTime / 1000) % 60).toString().padStart(2, "0")}
                </div>
            ))}
            {boutLog.length === 0 && <h6 className="dualMeetScorerNoEvents">No bout events yet</h6>}
        </div>
        {boutRunning && (
            <div className="dualMeetScorerFencerActions">
                {bout!.weapon === "Epee" && (
                    <div className="dualMeetScorerFencerAction" onClick={() => addScore("double")}>
                        Double touch
                    </div>
                )}
                <div
                    className="dualMeetScorerFencerAction"
                    style={bout!.weapon !== "Epee" ? { width: "calc(100% - 20px)" } : {}}
                    onClick={() => setPriority("random")}
                >
                    Random priority
                </div>
            </div>
        )}
    </div>
);

const BoutScorerComponent = ({
    id,
    bout,
    bouts,
    team1Fencers,
    team2Fencers,
    header,
    dualMeetData,
    setTeam1Fencers,
    setTeam2Fencers
}: BoutScorerComponentProps) => {
    const size = useWindowSize();
    const DB = useDatabase();
    const division = useDivision();

    const [creatingWriteIn, setCreatingWriteIn] = useState(false);
    const [writeInSide, setWriteInSide] = useState<"left" | "right">("left");
    const [writeInFirstName, setWriteInFirstName] = useState("");
    const [writeInLastName, setWriteInLastName] = useState("");
    const [forfeitting, setForfeitting] = useState<"left" | "right" | null>(null);
    const [subbingOpen, setSubbingOpen] = useState(false);
    const [subbing, setSubbing] = useState<"left" | "right" | null>(null);
    const [subbingName, setSubbingName] = useState<string>("");
    const [medicalInfoOpen, setMedicalInfoOpen] = useState(false);
    const [medicalInfoSide, setMedicalInfoSide] = useState<"left" | "right">("left");

    const [scorer, setScorer] = useState<BoutScorer | null>(null);

    const [boutLog, setBoutLog] = useState<IBoutEvent[]>([]);
    const [timerRunning, setTimerRunning] = useState(false);
    const [revisingBout, setRevisingBout] = useState(false);
    const [boutTime, setBoutTime] = useState(180000);
    const [boutTimeCheckpoint, setBoutTimeCheckpoint] = useState<number | null>(null);
    // Timeout is to wait until the clock hits a whole number of seconds (so the number will change at the proper time)
    const [timerTimeout, setTimerTimeout] = useState<NodeJS.Timeout | null>(null);

    const [timeout1Taken, setTimeout1Taken] = useState(false);
    const [timeout2Taken, setTimeout2Taken] = useState(false);
    const [timeoutTimer1, setTimeoutTimer1] = useState<number | null>(null);
    const [timeoutTimer2, setTimeoutTimer2] = useState<number | null>(null);
    const [timeoutTimer1Interval, setTimeoutTimer1Interval] = useState<NodeJS.Timer | null>(null);
    const [timeoutTimer2Interval, setTimeoutTimer2Interval] = useState<NodeJS.Timer | null>(null);

    const [fencer1Score, setFencer1Score] = useState(0);
    const [fencer2Score, setFencer2Score] = useState(0);
    const [fencer1Cards, setFencer1Cards] = useState({
        yellow: false,
        red: 0,
        black: false
    });
    const [fencer2Cards, setFencer2Cards] = useState({
        yellow: false,
        red: 0,
        black: false
    });
    const [fencer1Forfeit, setFencer1Forfeit] = useState(false);
    const [fencer2Forfeit, setFencer2Forfeit] = useState(false);
    const [switchedSides, setSwitchedSides] = useState(false);
    const [boutPriority, setBoutPriority] = useState<BoutSide | null>(null);

    const leftScore = switchedSides ? fencer2Score : fencer1Score;
    const rightScore = !switchedSides ? fencer2Score : fencer1Score;
    const leftCards = switchedSides ? fencer2Cards : fencer1Cards;
    const rightCards = !switchedSides ? fencer2Cards : fencer1Cards;
    const leftForfeit = switchedSides ? fencer2Forfeit : fencer1Forfeit;
    const rightForfeit = !switchedSides ? fencer2Forfeit : fencer1Forfeit;
    const prioritySide = boutPriority === null ? null : (boutPriority === BoutSide.Fencer1) === !switchedSides ? "left" : "right";

    const leftFencer = switchedSides ? bout.fencer2! : bout.fencer1!;
    const rightFencer = switchedSides ? bout.fencer1! : bout.fencer2!;
    const leftFencerName = `${leftFencer.fencerInfo.firstName} ${leftFencer.fencerInfo.lastName}`;
    const rightFencerName = `${rightFencer.fencerInfo.firstName} ${rightFencer.fencerInfo.lastName}`;
    let leftFencerDisplayName = leftFencerName;
    if (leftFencerDisplayName === "Unknown Fencer") leftFencerDisplayName = "Select fencer";
    if (leftFencer.forfeit && !leftFencer.medicalForfeit) leftFencerDisplayName = "No fencer";
    let rightFencerDisplayName = rightFencerName;
    if (rightFencerDisplayName === "Unknown Fencer") rightFencerDisplayName = "Select fencer";
    if (rightFencer.forfeit && !rightFencer.medicalForfeit) rightFencerDisplayName = "No fencer";
    const leftTimeoutTaken = bout.switchedSides ? timeout2Taken : timeout1Taken;
    const rightTimeoutTaken = bout.switchedSides ? timeout1Taken : timeout2Taken;
    const leftTimeoutTimer = bout.switchedSides ? timeoutTimer2 : timeoutTimer1;
    const rightTimeoutTimer = bout.switchedSides ? timeoutTimer1 : timeoutTimer2;
    const leftTimeoutInterval = bout.switchedSides ? timeoutTimer2Interval : timeoutTimer1Interval;
    const rightTimeoutInterval = bout.switchedSides ? timeoutTimer1Interval : timeoutTimer2Interval;

    const medicalInfoFencer = medicalInfoSide === "left" ? leftFencer : rightFencer;

    const boutRunning = (Boolean(bout.startedAt) && Boolean(!bout.endedAt)) || revisingBout;

    const updateCurrentBout = (bout: IDualMeetBout, bouts: string[]) => {
        if (!bout) return;

        const left = !bout.switchedSides ? bout.fencer1 : bout.fencer2;
        const right = bout.switchedSides ? bout.fencer1 : bout.fencer2;

        setTimeout1Taken(left.timeout);
        setTimeout2Taken(right.timeout);
        setTimeoutTimer1Interval(null);
        setTimeoutTimer2Interval(null);
        setRevisingBout(false);
        setBoutTime(bout.boutTime);
        DB.getBoutEventsFromArray(bout.log || []).then(d => {
            if (bout.id !== id) return;
            setBoutLog(d.filter(isSuccess).map(l => l.data));
        });

        const priority = bout.priority === "left" ? BoutSide.Fencer1 : bout.priority === "right" ? BoutSide.Fencer2 : null;

        const newBoutScorer = new BoutScorer(
            DB,
            bout.id,
            bout.dualMeetId,
            bout.weapon,
            bout.order,
            bouts,
            bout.fencer1,
            bout.fencer2,
            bout.switchedSides,
            priority,
            setFencer1Score,
            setFencer2Score,
            setFencer1Cards,
            setFencer2Cards,
            setFencer1Forfeit,
            setFencer2Forfeit,
            setSwitchedSides,
            setBoutPriority
        );
        setScorer(newBoutScorer);
    };

    useEffect(() => {
        if (!bout) return;

        updateCurrentBout(bout, bouts);
    }, [id]);

    const boutEventRequestIndex = useRef(0);

    useEffect(() => {
        const currentRequestIndex = boutEventRequestIndex.current + 1;
        boutEventRequestIndex.current = currentRequestIndex;

        DB.getBoutEventsFromArray(bout.log || []).then(d => {
            if (boutEventRequestIndex.current === currentRequestIndex) {
                setBoutLog(d.filter(isSuccess).map(l => l.data));
            }
        });
    }, [bout.log]);

    useEffect(() => {
        if (timeoutTimer1 !== timeoutLength) return;
        const interval = setInterval(() => {
            setTimeoutTimer1(timeLeft => {
                if (timeLeft! - 1 === 0) {
                    endTimeout("1");
                }
                return timeLeft! - 1;
            });
        }, 1000);
        setTimeoutTimer1Interval(interval);
    }, [timeoutTimer1]);

    useEffect(() => {
        if (timeoutTimer2 !== timeoutLength) return;
        const interval = setInterval(() => {
            setTimeoutTimer2(timeLeft => {
                if (timeLeft! - 1 === 0) {
                    endTimeout("2");
                }
                return timeLeft! - 1;
            });
        }, 1000);
        setTimeoutTimer2Interval(interval);
    }, [timeoutTimer2]);

    //#region Scoring

    //#region Control
    const startBout = () => {
        if (!scorer) return;

        scorer.startBout(Boolean(bout.endedAt));
        if (bout.endedAt) {
            setRevisingBout(true);
        }
    };

    const endBout = () => {
        if (!scorer) return;

        scorer.endBout(bout.dualMeetId);
        if (bout.endedAt) {
            setRevisingBout(false);
        }
    };

    const switchSides = () => {
        if (!bout || !scorer) return;

        scorer.switchSides(boutTime);
    };

    const setPriority = (side: "left" | "right" | "random" | null) => {
        if (!bout || !scorer) return;

        if (side === "random") {
            scorer.randomPriority(boutTime);
            setBoutTime(60000);
            return;
        }

        let sideNum = 1;
        if (side === "left") sideNum = switchedSides ? 2 : 1;
        if (side === "right") sideNum = switchedSides ? 1 : 2;

        setBoutTime(60000);
        if (sideNum === null) {
            if (boutPriority) {
                scorer.togglePriority(boutTime, boutPriority);
            }
        } else {
            scorer.togglePriority(boutTime, sideNum);
        }
    };

    const forfeitNoFencer = (side: "left" | "right") => {
        if (!scorer) return;
        scorer.forfeit(boutTime, side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2, true, false);
    };

    const forfeitMedical = (side: "left" | "right") => {
        if (!scorer) return;
        scorer.forfeit(boutTime, side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2, true, true);
    };

    const unforfeit = (side: "left" | "right") => {
        if (!scorer) return;
        scorer.forfeit(boutTime, side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2, false, false);
    };

    const trySubFencer = (side: "left" | "right", name: string) => {
        setSubbingName(name);
        setSubbing(side);
        setSubbingOpen(true);
    };

    const subFencer = (side: "left" | "right", name: string, medical: boolean) => {
        if (!bout || !scorer) return;

        // Probably should do this nicer but whatever
        const modifiedSide = bout.switchedSides !== (side === "left") ? "left" : "right";

        const teamSide = modifiedSide === "left" ? team1Fencers : team2Fencers;
        const currentWeapon = bout.weapon;
        const matchingFencer = [...teamSide[currentWeapon].keys()].find(l => `${l.firstName} ${l.lastName}` === name);

        if (!matchingFencer) return;

        scorer.subFencer((side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2, matchingFencer, medical);
    };

    const submitWriteInFencer = async (medical: boolean) => {
        if (!bout || !scorer) return;

        const side = writeInSide;
        const teamID = (side === "left") === !switchedSides ? dualMeetData.team1.id : dualMeetData.team2.id;
        if (!teamID) return;
        // TODO: update to actually have a consistent system with write in teams and stuff

        const newFencer = await scorer.subWriteInFencer(
            (side === "left") === !switchedSides ? 1 : 2,
            teamID,
            dualMeetData.season,
            writeInFirstName,
            writeInLastName,
            medical
        );
        // TODO: handle this
        if (newFencer.status === "fail") return;

        const updateFunc = (side === "left") === !switchedSides ? setTeam1Fencers : setTeam2Fencers;
        updateFunc(u => {
            u[bout.weapon].set(newFencer.data, 0);
            return u;
        });

        setCreatingWriteIn(false);
        setWriteInFirstName("");
        setWriteInLastName("");
    };
    //#endregion Control

    //#region Touches
    const addScore = (side: "left" | "right" | "double") => {
        if (!bout || !scorer) return;
        if (side === "double") {
            if (leftScore >= 5 || rightScore >= 5) return;
            scorer.addDoubleTouch(boutTime);
        } else {
            const sideScore = side === "left" ? leftScore : rightScore;
            if (sideScore >= 5) return;
            scorer.addTouch(boutTime, (side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2);
        }
    };

    const subtractScore = (side: "left" | "right") => {
        if (!bout || !scorer) return;

        const sideScore = side === "left" ? leftScore : rightScore;
        if (sideScore <= 0) return;

        scorer.annulTouch(boutTime, (side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2);
    };
    //#endregion Touches

    //#region Cards
    const setFlag = (side: "left" | "right", color: "yellow" | "black", val: boolean) => {
        if (!bout || !boutRunning || !scorer) return;

        if (color === "yellow") {
            scorer.setYellowCard(boutTime, (side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2, val);
        } else if (color === "black") {
            scorer.setBlackCard(boutTime, (side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2, val);
        }
    };

    const setRedFlag = (side: "left" | "right", option: "add" | "sub") => {
        if (!bout || !boutRunning || !scorer) return;

        const newCardCount = (side === "left" ? leftCards.red : rightCards.red) + (option === "add" ? 1 : -1);
        scorer.setRedCard(boutTime, (side === "left") === !switchedSides ? BoutSide.Fencer1 : BoutSide.Fencer2, newCardCount);
    };
    //#endregion Cards

    //#region Timeouts
    const startTimeout = (side: "1" | "2") => {
        if (!bout || !scorer) return;

        scorer.startTimeout(boutTime, Number(side));

        if (timeoutTimer1 === null && side === "1") {
            setTimeoutTimer1(timeoutLength);
        }
        if (timeoutTimer2 === null && side === "2") {
            setTimeoutTimer2(timeoutLength);
        }
    };

    const runTimeout = (action: "start" | "stop", side: "left" | "right") => {
        if (action === "start") {
            if (side === "left") {
                if (!leftTimeoutTaken && !leftTimeoutInterval) {
                    startTimeout(bout.switchedSides ? "2" : "1");
                }
            } else {
                if (!rightTimeoutTaken && !rightTimeoutInterval) {
                    startTimeout(bout.switchedSides ? "1" : "2");
                }
            }
        } else {
            if (side === "left" && leftTimeoutInterval) {
                endTimeout(bout.switchedSides ? "2" : "1");
            } else if (side === "right" && rightTimeoutInterval) {
                endTimeout(bout.switchedSides ? "1" : "2");
            }
        }
    };

    const cancelTimeout = (side: "left" | "right") => {
        if (!bout || !scorer) return;

        scorer.cancelTimeout(boutTime, (side === "left") === !switchedSides ? 1 : 2);

        if ((side === "left" && !bout.switchedSides) || (side === "right" && bout.switchedSides)) {
            setTimeoutTimer1Interval(interval => {
                interval && clearInterval(interval);
                return null;
            });
        } else {
            setTimeoutTimer2Interval(interval => {
                interval && clearInterval(interval);
                return null;
            });
        }
        ((side === "left" && !bout.switchedSides) || (side === "right" && bout.switchedSides) ? setTimeoutTimer1 : setTimeoutTimer2)(null);
    };

    const endTimeout = (side: "1" | "2") => {
        if (!bout || !scorer) return;

        scorer.endTimeout(boutTime, Number(side));

        if (side === "1") {
            setTimeoutTimer1Interval(interval => {
                interval && clearInterval(interval);
                return null;
            });
        } else {
            setTimeoutTimer2Interval(interval => {
                interval && clearInterval(interval);
                return null;
            });
        }
        (side === "1" ? setTimeoutTimer1 : setTimeoutTimer2)(null);
        (side === "1" ? setTimeout1Taken : setTimeout2Taken)(true);
    };
    //#endregion Timeouts

    //#region Timer
    const startTimer = () => {
        const d = Date.now();
        setBoutTimeCheckpoint(d);
        const timeout = createTimerTimeout();
        setTimerTimeout(timeout);
        setTimerRunning(true);
    };

    const stopTimer = () => {
        if (timerTimeout) {
            setBoutTime(b => b - (Date.now() - boutTimeCheckpoint!));
            clearTimeout(timerTimeout);
            setTimerTimeout(null);
        }
        setTimerRunning(false);
    };

    const createTimerTimeout = () => {
        let trueBoutTime = 0;
        // Fuck react and its dumbass asynchronous state
        setBoutTime(b => {
            trueBoutTime = b;
            return b;
        });
        // const length = Date.now() - boutTimeCheckpoint!;
        const length = trueBoutTime % 1000;
        return setTimeout(() => {
            if (trueBoutTime <= 0) {
                timerTimeout && clearTimeout(timerTimeout);
                setBoutTime(0);
                setTimerRunning(false);
                scorer?.clockEnd();
            } else if (trueBoutTime - length >= 0) {
                setBoutTimeCheckpoint(checkpoint => {
                    const dat = Date.now();
                    setBoutTime(b => {
                        if (b! - (dat - checkpoint!) < 0) {
                            setTimerRunning(false);
                            return 0;
                        } else {
                            return b! - (dat - checkpoint!);
                        }
                    });
                    return dat;
                });
                setTimerTimeout(createTimerTimeout());
            }
        }, length);
    };

    const addTime = (time: number) => {
        setBoutTime(b => b + time);
    };
    //#endregion Timer

    //#endregion Scoring

    const mobile = size.width < 600;

    const StartStopButton = ({ absolute }: { absolute: boolean }) => {
        return (
            <div
                className={`${absolute ? "dualMeetScorerStartToggle" : "dualMeetScorerStartToggleMobile"}`}
                style={{
                    backgroundColor: boutRunning ? "rgb(240,128,128)" : "rgb(100,255,160)",
                    borderColor: boutRunning ? "red" : "green",
                    color: mobile ? "black" : "white"
                }}
                onClick={boutRunning ? endBout : startBout}
            >
                {boutRunning ? "End" : bout.endedAt ? "Revise" : "Start"} {mobile ? "Bout" : ""}
            </div>
        );
    };

    const leftMedicalIcon = leftFencer.medicalForfeit || leftFencer.medicalFencerInfo;
    const rightMedicalIcon = rightFencer.medicalForfeit || rightFencer.medicalFencerInfo;

    return (
        <div className="dualMeetScorerContainer">
            <div
                style={{
                    color: "white",
                    height: mobile ? 100 : 50,
                    fontSize: 24,
                    lineHeight: "35px",
                    display: mobile ? "flex" : "block",
                    flexDirection: mobile ? "column" : "initial"
                }}
                className="dualMeetScorerHeader rainbowBox rainbowBoxBG"
            >
                {header || <></>}
                {<StartStopButton absolute={!mobile} />}
            </div>
            <div
                className={`dualMeetScorerBox ${division === Division.NCAA ? "college" : "hs"}`}
                style={{
                    height: mobile ? (boutRunning ? 680 : 480) : boutRunning ? 440 : 360
                }}
            >
                {bout ? (
                    <div
                        style={{
                            flexShrink: 0,
                            display: "flex",
                            height: mobile ? (boutRunning ? 400 : 315) : boutRunning ? 440 : 360
                        }}
                        key={`bout${bout.order}`}
                    >
                        <div className="dualMeetScorerCol">
                            <div className="dualMeetScorerBoutPlayer">
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <StyledDropdown displayText={leftFencerDisplayName}>
                                        <Box>
                                            {Array.from((bout.switchedSides ? team2Fencers : team1Fencers)[bout.weapon].entries())
                                                .filter(
                                                    l =>
                                                        l[0].firstName !== leftFencer.fencerInfo.firstName ||
                                                        l[0].lastName !== leftFencer.fencerInfo.lastName
                                                )
                                                .map(l => (
                                                    <MenuItem
                                                        key={`substituteFencerLeft${(l[0] as IExistingFencer)?.id || `writeIn${l[0].firstName}${l[0].lastName}`}`}
                                                        onClick={() => trySubFencer("left", `${l[0].firstName} ${l[0].lastName}`)}
                                                    >
                                                        {l[0].firstName} {l[0].lastName}
                                                    </MenuItem>
                                                ))}
                                        </Box>
                                        <MenuItem
                                            key="writeInLeft"
                                            onClick={() => {
                                                setCreatingWriteIn(true);
                                                setWriteInSide("left");
                                            }}
                                        >
                                            Write-in Fencer
                                        </MenuItem>
                                        {leftFencer.forfeit ? (
                                            <MenuItem key="unforfeitFencerLeft" onClick={() => unforfeit("left")}>
                                                Unforfeit
                                            </MenuItem>
                                        ) : (
                                            <Box>
                                                <MenuItem key="removeFencerLeft" onClick={() => forfeitNoFencer("left")}>
                                                    No Fencer (Forfeit)
                                                </MenuItem>
                                                <MenuItem key="forfeitLeft" onClick={() => forfeitMedical("left")}>
                                                    Medical Forfeit
                                                </MenuItem>
                                            </Box>
                                        )}
                                    </StyledDropdown>
                                    {leftMedicalIcon && (
                                        <MedicalIcon
                                            side="left"
                                            setMedicalInfoOpen={setMedicalInfoOpen}
                                            setMedicalInfoSide={setMedicalInfoSide}
                                        />
                                    )}
                                </Box>
                                <h5
                                    style={{
                                        marginTop: 10,
                                        height: 20,
                                        fontSize: 14,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {leftFencer.fencerInfo.teamAbbreviation}
                                </h5>
                                {boutRunning && (
                                    <div className="dualMeetIncreaseCurrentBoutScore" onClick={() => addScore("left")}>
                                        <Icon path={mdiChevronUp} size="20px" />
                                    </div>
                                )}
                                <div
                                    className="dualMeetCurrentBoutScore"
                                    style={{
                                        backgroundColor: "rgb(240,128,128)",
                                        height: boutRunning ? (mobile ? 105 : 125) : `calc(100% - ${125}px)`
                                    }}
                                >
                                    {leftScore}
                                    {prioritySide === "left" && <span className="dualMeetCurrentBoutScorePriority">PRIORITY</span>}
                                    {leftForfeit && (
                                        <Box className="dualMeetCurrentBoutScorePriority">
                                            FORFEIT
                                            {leftFencer.medicalForfeit && (
                                                <MedicalIcon
                                                    side="left"
                                                    setMedicalInfoOpen={setMedicalInfoOpen}
                                                    setMedicalInfoSide={setMedicalInfoSide}
                                                />
                                            )}
                                        </Box>
                                    )}
                                </div>
                                {boutRunning && (
                                    <div className="dualMeetDecreaseCurrentBoutScore" onClick={() => subtractScore("left")}>
                                        <Icon path={mdiChevronDown} size="20px" />
                                    </div>
                                )}
                                <div
                                    style={{
                                        backgroundColor: "#999",
                                        borderRadius: 8,
                                        marginTop: 10,
                                        height: 40,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around"
                                    }}
                                >
                                    <div onClick={() => setFlag("left", "yellow", !leftCards.yellow)}>
                                        <Icon
                                            className="dualMeetScorerFlag"
                                            style={{
                                                cursor: boutRunning ? "pointer" : "initial"
                                            }}
                                            path={leftCards.yellow ? mdiSquare : mdiSquareOutline}
                                            size="36px"
                                            color="yellow"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={{ position: "relative" }}>
                                            {leftCards.red > 0 && (
                                                <span
                                                    style={{
                                                        fontSize: 20,
                                                        position: "absolute",
                                                        left: 0,
                                                        top: 3,
                                                        textAlign: "center",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {leftCards.red}
                                                </span>
                                            )}
                                            <Icon
                                                className="dualMeetScorerFlag"
                                                style={{
                                                    cursor: boutRunning ? "pointer" : "initial"
                                                }}
                                                path={leftCards.red > 0 ? mdiSquare : mdiSquareOutline}
                                                size="36px"
                                                color="red"
                                            />
                                        </div>
                                        {boutRunning && (
                                            <div className="dualMeetScorerRedFlagButtons">
                                                <div className="dualMeetScorerRedFlagButton" onClick={() => setRedFlag("left", "add")}>
                                                    <Icon
                                                        className="dualMeetScorerRedFlagButtonIcon"
                                                        path={mdiChevronUp}
                                                        size="12px"
                                                        color="#777"
                                                    />
                                                </div>
                                                <div
                                                    className="dualMeetScorerRedFlagButton"
                                                    onClick={() => {
                                                        leftCards.red > 0 && setRedFlag("left", "sub");
                                                    }}
                                                >
                                                    <Icon
                                                        className="dualMeetScorerRedFlagButtonIcon"
                                                        path={mdiChevronDown}
                                                        size="12px"
                                                        color="#777"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={() => setFlag("left", "black", !leftCards.black)}>
                                        <Icon
                                            className="dualMeetScorerFlag"
                                            style={{
                                                cursor: boutRunning ? "pointer" : "initial"
                                            }}
                                            path={leftCards.black ? mdiSquare : mdiSquareOutline}
                                            size="36px"
                                            color="black"
                                        />
                                    </div>
                                </div>
                            </div>
                            {boutRunning && (
                                <div className="dualMeetScorerFencerActions">
                                    {leftTimeoutInterval ? (
                                        <div
                                            className="dualMeetScorerFencerSplitActionContainer"
                                            style={
                                                bout.weapon === "Sabre"
                                                    ? {
                                                          width: "calc(100% - 20px)"
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div
                                                className="dualMeetScorerFencerSplitAction"
                                                onClick={() => runTimeout(leftTimeoutTimer ? "stop" : "start", "left")}
                                            >
                                                Stop ({leftTimeoutTimer}s)
                                            </div>
                                            <div className="dualMeetScorerFencerSplitAction" onClick={() => cancelTimeout("left")}>
                                                Cancel
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="dualMeetScorerFencerAction"
                                            style={{
                                                ...(bout.weapon === "Sabre"
                                                    ? {
                                                          width: "calc(100% - 20px)"
                                                      }
                                                    : {}),
                                                ...(leftTimeoutTaken
                                                    ? {
                                                          backgroundColor: "#888",
                                                          cursor: "not-allowed"
                                                      }
                                                    : {
                                                          backgroundColor: "rgb(240,128,128)"
                                                      })
                                            }}
                                            onClick={() => runTimeout(leftTimeoutTimer ? "stop" : "start", "left")}
                                        >
                                            {leftTimeoutTaken
                                                ? "Timeout taken"
                                                : leftTimeoutInterval
                                                  ? `Timeout (${leftTimeoutTimer}s)`
                                                  : "Timeout left"}
                                        </div>
                                    )}
                                    {bout.weapon !== "Sabre" && (
                                        <div
                                            className="dualMeetScorerFencerAction"
                                            style={{
                                                backgroundColor: "rgb(240,128,128)"
                                            }}
                                            onClick={() => setPriority("left")}
                                        >
                                            Priority left
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {!mobile && (
                            <CenterCol
                                addScore={addScore}
                                addTime={addTime}
                                bout={bout}
                                boutLog={boutLog}
                                boutRunning={boutRunning}
                                boutTime={boutTime}
                                mobile={mobile}
                                setPriority={setPriority}
                                startTimer={startTimer}
                                stopTimer={stopTimer}
                                switchSides={switchSides}
                                timerRunning={timerRunning}
                            />
                        )}
                        <div className="dualMeetScorerCol">
                            <div className="dualMeetScorerBoutPlayer">
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <StyledDropdown displayText={rightFencerDisplayName}>
                                        <Box>
                                            {Array.from((bout.switchedSides ? team1Fencers : team2Fencers)[bout.weapon].entries())
                                                .filter(
                                                    l =>
                                                        l[0].firstName !== rightFencer.fencerInfo.firstName ||
                                                        l[0].lastName !== rightFencer.fencerInfo.lastName
                                                )
                                                .map(l => (
                                                    <MenuItem
                                                        key={`substituteFencerRight${(l[0] as IExistingFencer)?.id || `writeIn${l[0].firstName}${l[0].lastName}`}`}
                                                        onClick={() => trySubFencer("right", `${l[0].firstName} ${l[0].lastName}`)}
                                                    >
                                                        {l[0].firstName} {l[0].lastName}
                                                    </MenuItem>
                                                ))}
                                        </Box>
                                        <MenuItem
                                            key="writeInRight"
                                            onClick={() => {
                                                setCreatingWriteIn(true);
                                                setWriteInSide("right");
                                            }}
                                        >
                                            Write-in Fencer
                                        </MenuItem>
                                        {rightFencer.forfeit ? (
                                            <MenuItem key="unforfeitFencerRight" onClick={() => unforfeit("right")}>
                                                Unforfeit
                                            </MenuItem>
                                        ) : (
                                            <Box>
                                                <MenuItem key="removeFencerRight" onClick={() => forfeitNoFencer("right")}>
                                                    No Fencer (Forfeit)
                                                </MenuItem>
                                                <MenuItem key="forfeitRight" onClick={() => forfeitMedical("right")}>
                                                    Medical Forfeit
                                                </MenuItem>
                                            </Box>
                                        )}
                                    </StyledDropdown>
                                    {rightMedicalIcon && (
                                        <MedicalIcon
                                            side="right"
                                            setMedicalInfoOpen={setMedicalInfoOpen}
                                            setMedicalInfoSide={setMedicalInfoSide}
                                        />
                                    )}
                                </Box>
                                <h5
                                    style={{
                                        marginTop: 10,
                                        height: 20,
                                        fontSize: 14,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {rightFencer.fencerInfo.teamAbbreviation}
                                </h5>
                                {boutRunning && (
                                    <div className="dualMeetIncreaseCurrentBoutScore" onClick={() => addScore("right")}>
                                        <Icon path={mdiChevronUp} size="20px" />
                                    </div>
                                )}
                                <div
                                    className="dualMeetCurrentBoutScore"
                                    style={{
                                        backgroundColor: "rgb(100,255,160)",
                                        borderColor: "green",
                                        height: boutRunning ? (mobile ? 105 : 125) : `calc(100% - ${125}px)`
                                    }}
                                >
                                    {rightScore}
                                    {prioritySide === "right" && <span className="dualMeetCurrentBoutScorePriority">PRIORITY</span>}
                                    {rightForfeit && (
                                        <Box className="dualMeetCurrentBoutScorePriority">
                                            FORFEIT
                                            {rightFencer.medicalForfeit && (
                                                <MedicalIcon
                                                    side="right"
                                                    setMedicalInfoOpen={setMedicalInfoOpen}
                                                    setMedicalInfoSide={setMedicalInfoSide}
                                                />
                                            )}
                                        </Box>
                                    )}
                                </div>
                                {boutRunning && (
                                    <div className="dualMeetDecreaseCurrentBoutScore" onClick={() => subtractScore("right")}>
                                        <Icon path={mdiChevronDown} size="20px" />
                                    </div>
                                )}
                                <div
                                    style={{
                                        backgroundColor: "#999",
                                        borderRadius: 8,
                                        marginTop: 10,
                                        height: 40,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around"
                                    }}
                                >
                                    <div onClick={() => setFlag("right", "yellow", !rightCards.yellow)}>
                                        <Icon
                                            className="dualMeetScorerFlag"
                                            style={{
                                                cursor: boutRunning ? "pointer" : "initial"
                                            }}
                                            path={rightCards.yellow ? mdiSquare : mdiSquareOutline}
                                            size="36px"
                                            color="yellow"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={{ position: "relative" }}>
                                            {rightCards.red > 0 && (
                                                <span
                                                    style={{
                                                        fontSize: 20,
                                                        position: "absolute",
                                                        left: 0,
                                                        top: 3,
                                                        textAlign: "center",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {rightCards.red}
                                                </span>
                                            )}
                                            <Icon
                                                className="dualMeetScorerFlag"
                                                style={{
                                                    cursor: boutRunning ? "pointer" : "initial"
                                                }}
                                                path={rightCards.red > 0 ? mdiSquare : mdiSquareOutline}
                                                size="36px"
                                                color="red"
                                            />
                                        </div>
                                        {boutRunning && (
                                            <div className="dualMeetScorerRedFlagButtons">
                                                <div className="dualMeetScorerRedFlagButton" onClick={() => setRedFlag("right", "add")}>
                                                    <Icon
                                                        className="dualMeetScorerRedFlagButtonIcon"
                                                        path={mdiChevronUp}
                                                        size="12px"
                                                        color="#777"
                                                    />
                                                </div>
                                                <div
                                                    className="dualMeetScorerRedFlagButton"
                                                    onClick={() => {
                                                        rightCards.red > 0 && setRedFlag("right", "sub");
                                                    }}
                                                >
                                                    <Icon
                                                        className="dualMeetScorerRedFlagButtonIcon"
                                                        path={mdiChevronDown}
                                                        size="12px"
                                                        color="#777"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={() => setFlag("right", "black", !rightCards.black)}>
                                        <Icon
                                            className="dualMeetScorerFlag"
                                            style={{
                                                cursor: boutRunning ? "pointer" : "initial"
                                            }}
                                            path={rightCards.black ? mdiSquare : mdiSquareOutline}
                                            size="36px"
                                            color="black"
                                        />
                                    </div>
                                </div>
                            </div>
                            {boutRunning && (
                                <div className="dualMeetScorerFencerActions">
                                    {rightTimeoutInterval ? (
                                        <div
                                            className="dualMeetScorerFencerSplitActionContainer"
                                            style={
                                                bout.weapon === "Sabre"
                                                    ? {
                                                          width: "calc(100% - 20px)"
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div
                                                className="dualMeetScorerFencerSplitAction"
                                                onClick={() => runTimeout(rightTimeoutTimer ? "stop" : "start", "right")}
                                            >
                                                Stop ({rightTimeoutTimer}s)
                                            </div>
                                            <div className="dualMeetScorerFencerSplitAction" onClick={() => cancelTimeout("right")}>
                                                Cancel
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="dualMeetScorerFencerAction"
                                            style={{
                                                ...(bout.weapon === "Sabre"
                                                    ? {
                                                          width: "calc(100% - 20px)"
                                                      }
                                                    : {}),
                                                ...(rightTimeoutTaken
                                                    ? {
                                                          backgroundColor: "#888",
                                                          cursor: "not-allowed"
                                                      }
                                                    : {
                                                          backgroundColor: "rgb(240,128,128)"
                                                      })
                                            }}
                                            onClick={() => runTimeout(rightTimeoutTimer ? "stop" : "start", "right")}
                                        >
                                            {rightTimeoutTaken
                                                ? "Timeout taken"
                                                : rightTimeoutInterval
                                                  ? `Timeout (${rightTimeoutTimer}s)`
                                                  : "Timeout right"}
                                        </div>
                                    )}
                                    {bout.weapon !== "Sabre" && (
                                        <div
                                            className="dualMeetScorerFencerAction"
                                            style={{
                                                backgroundColor: "rgb(100,255,160)"
                                            }}
                                            onClick={() => setPriority("right")}
                                        >
                                            Priority right
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>You have not selected any bouts!</p>
                )}
                {mobile && (
                    <CenterCol
                        addScore={addScore}
                        addTime={addTime}
                        bout={bout}
                        boutLog={boutLog}
                        boutRunning={boutRunning}
                        boutTime={boutTime}
                        mobile={mobile}
                        setPriority={setPriority}
                        startTimer={startTimer}
                        stopTimer={stopTimer}
                        switchSides={switchSides}
                        timerRunning={timerRunning}
                    />
                )}
            </div>

            <Modal open={creatingWriteIn} onClose={() => setCreatingWriteIn(false)}>
                <Box sx={modalBoxStyle}>
                    <Typography variant="h4" fontFamily="Lexend Deca">
                        Create write-in fencer
                    </Typography>
                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            value={writeInFirstName}
                            onChange={e => setWriteInFirstName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginTop: 20, width: "100%" }}>
                        <TextField
                            label="Last Name"
                            placeholder="Last Name"
                            value={writeInLastName}
                            onChange={e => setWriteInLastName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <Box style={{ display: "flex" }}>
                        <Button
                            variant="contained"
                            color="success"
                            className={`${!writeInFirstName.trim() || !writeInLastName.trim() ? "disabledButton" : ""}`}
                            disabled={!writeInFirstName.trim() || !writeInLastName.trim()}
                            style={{ marginTop: 15 }}
                            onClick={() => submitWriteInFencer(false)}
                        >
                            Normal substitution
                        </Button>
                        {(writeInSide === "left" ? leftFencerName : rightFencerName) !== "Unknown fencer" ? (
                            <Button
                                variant="contained"
                                color="warning"
                                className={`${!writeInFirstName.trim() || !writeInLastName.trim() ? "disabledButton" : ""}`}
                                disabled={!writeInFirstName.trim() || !writeInLastName.trim()}
                                style={{ marginTop: 15, marginLeft: 15 }}
                                onClick={() => submitWriteInFencer(true)}
                            >
                                Medical substitution
                            </Button>
                        ) : null}
                    </Box>
                </Box>
            </Modal>

            <Dialog open={Boolean(subbingOpen)} onClose={() => setSubbingOpen(false)}>
                <DialogTitle fontFamily="Lexend Deca">{bout.order <= 2 ? "Replace fencer" : "Substitute Fencer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {bout.order <= 2 ? "replace" : "substitute"} <strong>{subbingName}</strong> in for{" "}
                        <strong>{subbing === "left" ? leftFencerName : rightFencerName}</strong>? Select the appropriate reason for
                        {bout.order <= 2 ? "replacement" : "substitution"}, or choose Cancel.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            subFencer(subbing!, subbingName, false);
                            setSubbingOpen(false);
                        }}
                    >
                        {bout.order <= 2 ? "Replace fencer" : "Normal substitution"}
                    </Button>
                    {(subbing === "left" ? leftFencerName : rightFencerName) !== "Unknown fencer" ? (
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                subFencer(subbing!, subbingName, true);
                                setSubbingOpen(false);
                            }}
                        >
                            Medical substitution
                        </Button>
                    ) : null}
                    <Button variant="outlined" onClick={() => setSubbingOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MedicalDialog
                medicalInfoOpen={medicalInfoOpen}
                setMedicalInfoOpen={setMedicalInfoOpen}
                medicalInfoFencer={medicalInfoFencer}
            />
        </div>
    );
};

export default BoutScorerComponent;
