import "./Loading.css";

interface CommonLoadingProps {
    speed: number;
    color: string;
    size: "small" | "default" | "large";
}

export const CommonLoading = ({ speed, color = "#714FCA", size = "default" }: Partial<CommonLoadingProps>) => {
    const sizeItem: Record<CommonLoadingProps["size"], string> = {
        small: "10px",
        default: "12px",
        large: "14px"
    };

    const leSize = sizeItem[size] || sizeItem["default"];

    return (
        <div
            style={{
                width: 50,
                height: 50,
                margin: "auto",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }}
        >
            <div
                className="commonLoadingItemDiv"
                style={{
                    animation: `CommonLoading ${speed || 2}s linear infinite`,
                    animationDelay: "0.2s"
                }}
            >
                <span
                    className="commonLoadingItemSpan"
                    style={{
                        height: leSize,
                        width: leSize,
                        background: color || "#00adb5"
                    }}
                />
            </div>
            <div
                className="commonLoadingItemDiv"
                style={{
                    animation: `CommonLoading ${speed || 2}s linear infinite`,
                    animationDelay: "0.4s"
                }}
            >
                <span
                    className="commonLoadingItemSpan"
                    style={{
                        height: leSize,
                        width: leSize,
                        background: color || "#00adb5"
                    }}
                />
            </div>
            <div
                className="commonLoadingItemDiv"
                style={{
                    animation: `CommonLoading ${speed || 2}s linear infinite`,
                    animationDelay: "0.6s"
                }}
            >
                <span
                    className="commonLoadingItemSpan"
                    style={{
                        height: leSize,
                        width: leSize,
                        background: color || "#00adb5"
                    }}
                />
            </div>
            <div
                className="commonLoadingItemDiv"
                style={{
                    animation: `CommonLoading ${speed || 2}s linear infinite`,
                    animationDelay: "0.8s"
                }}
            >
                <span
                    className="commonLoadingItemSpan"
                    style={{
                        height: leSize,
                        width: leSize,
                        background: color || "#00adb5"
                    }}
                />
            </div>
        </div>
    );
};
