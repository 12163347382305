export enum Division {
    NJSIAA = "High School",
    NCAA = "College"
}
export enum SchoolType {
    College = "College",
    HS = "High School",
    MS = "Middle School"
}
export enum CollegeProgramType {
    Varsity = "Varsity",
    Club = "Club",
    JV = "JV",
    None = "None"
}
export type Weapon = "Sabre" | "Epee" | "Foil";
export type Strip = "A" | "B" | "C";
export type Gender = "boys" | "girls";
export type ID = string;
export type DateTime = number;
export enum BoutSide {
    Fencer1 = 1,
    Fencer2 = 2
}

export type Listener<T> = (val: T) => void;

export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export interface RosterCSVFencer {
    firstName: string;
    lastName: string;
    gradYear?: number;
    weapon: Weapon;
    homeStrip: number;
    awayStrip: number;
}

export type RosterCSV = RosterCSVFencer[];

export interface IFencerBase {
    firstName: string;
    lastName: string;
}

export interface IExistingFencer extends IFencerBase {
    // Foreign key to IUser
    id: ID;
    createdBy: ID;
    createdAt: DateTime;
    updatedAt: DateTime;
    pin: number;
    gradYear?: number;
    // Array of foreign keys to ITeam
    teams: ID[];
    // Array of foreign keys to IBout
    bouts: ID[];
}

export interface IWriteInFencer extends IFencerBase {
    teamName: string;
    teamAbbreviation?: string;
}

export interface ITeam {
    id: ID;
    abbreviation?: string;
    // Array of foreign keys to IUser
    administrators: ID[];
    // Array of foreign keys to IUser
    managers: ID[];
    // TODO: find a better way to do this maybe
    boysAndGirlsTeam: "boys" | "girls" | "both";
    countryCode: string;
    region: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    // Record of seasons to dual meet IDs
    dualMeets: Record<string, ID[]>;
    // All fencers that are part of the team, regardless if they're on the roster or not
    fencers: ID[];
    orgID?: ID;
    publishedAt?: DateTime;
    publishedBy?: ID;
    published: boolean;
    name: string;
    pin: number;
    // Record of seasons to record of weapons to records of fencer ids
    roster: Record<
        string,
        Record<
            Weapon,
            // Record of foreign keys to IFencer to their role
            Record<ID, { home: number; away: number; power?: number }>
        >
    >;
    seasons: string[];
    type: SchoolType;
    writeIn?: boolean;
    conference?: string[];

    /**
     * For automigration purposes. e.g. If `lastAccessedSeason` is 2022 while the current season is 2023, a warning popup will be shown.
     */
    lastAccessedSeason?: string;
}

export interface IOrganization {
    id: string;
    name: string;
    abbreviation?: string;
    administrators: ID[];
    boysTeam?: ID;
    girlsTeam?: ID;
    boysJVTeam?: ID;
    girlsJVTeam?: ID;
    countryCode: string;
    // For some reason, division is used here in college
    region: string;
    color: string;
    // Foreign key to IUser
    createdBy: ID;
    createdAt: DateTime;
    publishedAt?: DateTime;
    publishedBy?: ID;
    published: boolean;
    type: SchoolType;
    writeIn?: boolean;
    // District (i.e. 1-6) for HS, region (South, Midwest, etc.) for college
    district?: string;
    logoPath?: string;
}

export interface IOrganizationEdit {
    id: string;
    district?: string;
    logoPath?: string;
}

export enum UserFlag {
    // Verified account
    Verified = 1 << 0,
    // Disabled user
    Disabled = 1 << 1,
    // Can verify accounts/organizations
    SuperAdmin = 1 << 2,
    // Can manage other teams
    UberAdmin = 1 << 3,
    // Can manage tournaments (formerly matches)
    BoutCommittee = 1 << 4,
    // Can manage dual meets
    MeetManager = 1 << 5
}

export interface IUser {
    id: ID;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    // Only for use with migrated accounts (accounts that existed prior to v2)
    migratedAt?: DateTime;
    verifiedAt?: DateTime;
    verificationPin: number;
    // Bit flags for different roles/permissions
    // Refer to enum UserFlag to use
    flags: number;
    // Array of foreign keys to ITeam
    teams: ID[];
    // Array of foreign keys to ITeam
    managingTeams: ID[];
    // Links the user to a fencer
    linkedFencerIds: ID[];
}

export enum BoutEventType {
    End = "End",
    Card = "Card",
    Start = "Start",
    Touch = "Touch",
    Timeout = "Timeout",
    Forfeit = "Forfeit",
    Priority = "Priority",
    ClockEnd = "ClockEnd",
    TimeoutEnd = "TimeoutEnd",
    AnnulTouch = "AnnulTouch",
    DoubleTouch = "DoubleTouch",
    SwitchSides = "SwitchSides",
    TimeoutCancel = "TimeoutCancel",
    MedicalForfeit = "MedicalForfeit",
    RescindForfeit = "RescindForfeit",
    RandomPriority = "RandomPriority",
    PriorityRemoved = "PriorityRemoved",
    MedicalSubstitution = "MedicalSubstitution"
}

export interface ICardInfo {
    yellow: boolean;
    red: number;
    black: boolean;
}

export interface IBoutEvent {
    id: ID;
    description: string;
    // Maybe consider making IBoutEventCards into bit flags (number)
    fencer1Cards: ICardInfo;
    fencer2Cards: ICardInfo;
    score1: number;
    score2: number;
    boutTime: number;
    localTime: DateTime;
    type: BoutEventType;
}

export enum BoutType {
    DualMeet = "dualMeet",
    Quick = "quick"
}

export type IDualMeetBoutFencerInfo = IWriteInFencer & {
    id?: string;
    gradYear?: number;
};

export interface IBoutFencer_Base {
    /**
     * Status of the fencer's current cards
     */
    cardInfo: ICardInfo;
    /**
     * Fencer's current score
     */
    score: number;
    /**
     * Whether the fencer has taken a timeout
     */
    timeout: boolean;
    /**
     * Whether the fencer has forfeited the bout
     */
    forfeit: boolean;
    /**
     * Which strip the fencer is on (1-3 for home, 4-6 for away)
     */
    strip: number;
    /**
     * The fencer that was substituted out, if there was a medical substitution
     */
    medicalFencerInfo?: IDualMeetBoutFencerInfo;
    /**
     * Whether the bout was forfeited medically
     */
    medicalForfeit?: boolean;
    /**
     * Whether or not a substitution occurred
     */
    substitution?: boolean;
}

export interface IDualMeetBoutFencer extends IBoutFencer_Base {
    fencerInfo: IDualMeetBoutFencerInfo;
}

export interface IQuickBoutFencer extends IBoutFencer_Base {
    fencerInfo: IWriteInFencer;
}

export interface IBoutCore {
    id: ID;
    color: string;
    currentSpectatorsNum: number;
    // Foreign key to IBoutEvent
    log: ID[];
    order: number;
    pin: number;
    weapon: Weapon;
    type: BoutType;

    fencer1: IDualMeetBoutFencer | IQuickBoutFencer;
    fencer2: IDualMeetBoutFencer | IQuickBoutFencer;

    switchedSides: boolean;
    priority: "left" | "right" | null;

    currentlyEditingUser?: ID;

    boutTime: number;
    startedAt?: DateTime;
    endedAt?: DateTime;
    updatedAt?: DateTime;
}

export interface IDualMeetBout extends IBoutCore {
    fencer1: IDualMeetBoutFencer;
    fencer2: IDualMeetBoutFencer;

    /**
     * ID of the dual meet this bout is a part of
     */
    dualMeetId: ID;
    type: BoutType.DualMeet;
}

export interface IQuickBout extends IBoutCore {
    // Pretty sure these are name only, no IDs
    fencer1: IQuickBoutFencer;
    fencer2: IQuickBoutFencer;
    type: BoutType.Quick;
}

export enum DualMeetType {
    Varsity,
    Club,
    MiddleSchool,
    JV,
    CollegeEvent
}

export interface IDualMeetTeam {
    id?: ID;
    name: string;
    administrators: string[];
    managers: string[];
    abbreviation: string;
    fencers: Record<Weapon, ((IWriteInFencer | IExistingFencer) & { home: number; away: number })[]>;
    attendance?: ID[];
    attendanceSignature?: string;
}

export type LineupFencer = IDualMeetBoutFencerInfo | { type: "forfeit" };

export type Lineup = Record<Weapon, Partial<Record<1 | 2 | 3 | 4 | 5 | 6, LineupFencer>>>;

export type DualMeetSignatureKey =
    | "sabreRef"
    | "foilRef"
    | "epeeRef"
    | "team1Sabre"
    | "team1Foil"
    | "team1Epee"
    | "team2Sabre"
    | "team2Foil"
    | "team2Epee"
    | "team1"
    | "team2";

export interface IDualMeet_Base {
    /**
     * ID of the dual meet
     */
    id: ID;
    /**
     * ID of college event, if the dual meet is part of one
     */
    eventID?: ID;
    /**
     * Type of dual meet. Options are `Varsity`, `Club`, `MiddleSchool`, `JV`, and `CollegeEvent`.
     */
    type: DualMeetType;
    /**
     * Scorekeeping PIN for team 1
     */
    pin1: number;
    /**
     * Scorekeeping PIN for team 2
     */
    pin2: number;
    /**
     * Scorekeeping PIN for referees
     */
    refereePin?: number;
    /**
     * Display name of the dual meet
     */
    name: string;
    /**
     * Display color of the dual meet (unused)
     */
    color: string;
    /**
     * ID of user that created the meet
     */
    createdBy: ID;
    /**
     * Timestamp of when the meet was created
     */
    createdAt: DateTime;
    /**
     * Whether or not the meet has been published
     */
    published: boolean;
    /**
     * ID of user that published the meet
     */
    publishedBy?: ID;
    /**
     * Timestamp of when the meet was last published
     */
    publishedAt?: DateTime;
    /**
     * Which season the meet was a part of
     * @example "2022-2023"
     */
    season: string;
    /**
     * Timestamp of when the dual meet is scheduled to start
     */
    startedAt: DateTime;
    /**
     * Timestamp of when the dual meet was designated as ended (unused)
     */
    endedAt?: DateTime;
    /**
     * List of bout ID's and which side won (0 for no winner)
     */
    bouts: { id: ID; winner: BoutSide | 0 }[];
    /**
     * ID of the corresponding dual meet for the other gender
     */
    correspondingMeet?: ID;
    /**
     * @deprecated
     * Whether or not lineups are editable (unused)
     */
    lineupsVisible?: boolean;
    /**
     * Whether sabre lineups are visible for editing
     */
    lineupsVisibleSabre?: boolean;
    /**
     * Whether foil lineups are visible for editing
     */
    lineupsVisibleFoil?: boolean;
    /**
     * Whether epee lineups are visible for editing
     */
    lineupsVisibleEpee?: boolean;
    /**
     * Team 1's current lineup
     */
    team1Lineup?: Lineup;
    /**
     * Team 2's current lineup
     */
    team2Lineup?: Lineup;
    /**
     * Name of the sabre referee (typically used for college and NJ state finals)
     */
    sabreReferee?: string;
    /**
     * Name of the foil referee (typically used for college and NJ state finals)
     */
    foilReferee?: string;
    /**
     * Name of the epee referee (typically used for college and NJ state finals)
     */
    epeeReferee?: string;
    /**
     * Name of the referee for all 3 weapons (typically used for high school)
     */
    singleReferee?: boolean;
    /**
     * Signatures from referees and team members
     */
    signatures?: Partial<Record<DualMeetSignatureKey, string>>;
}

export interface IDualMeet_DB extends IDualMeet_Base {
    // Foreign key to ITeam
    team1ID?: ID;
    // Foreign key to ITeam
    team2ID?: ID;
    team1WriteInData?: IDualMeetTeam;
    team2WriteInData?: IDualMeetTeam;
    // Attendance information, present iff team is high school and provided in meet
    team1Attendance?: ID[];
    team1AttendanceSignature?: string;
    team2Attendance?: ID[];
    team2AttendanceSignature?: string;
}

export interface IDualMeet extends IDualMeet_Base {
    // Foreign key to ITeam
    team1: IDualMeetTeam;
    // Foreign key to ITeam
    team2: IDualMeetTeam;
}

export interface IPublicationApplication {
    id: ID;
    orgId: ID;
    // This is here just for convenience
    orgName: string;
    userId: ID;
    name: string;
    school: string;
    additionalInfo?: string | null;
    submittedAt: DateTime;
    takeover: boolean;
}

export interface ICollegeEventRoundMeet {
    id: ID;
    nameA: string;
    nameB: string;
    idA: string;
    idB: string;
    abbA: string;
    abbB: string;
    regionA: string;
    regionB: string;
}

export interface ICollegeEventRound {
    name: string;
    meets: ICollegeEventRoundMeet[];
    byes: { name: string; abb: string; region: string; id: ID }[];
}

export interface ICollegeEventTeam {
    name: string;
    id: string;
    abbreviation: string;
    orgID?: string;
    type: CollegeProgramType;
}

export interface ICollegeEvent {
    id: ID;
    season: string;
    published: boolean;
    name: string;
    location: string;
    hostName: string;
    address: string;
    createdBy: ID;
    createdAt: Date;
    startedAt: Date;
    refereePin: number;
    mensRounds: ICollegeEventRound[];
    womensRounds: ICollegeEventRound[];
    mensTeams: ICollegeEventTeam[];
    womensTeams: ICollegeEventTeam[];
}

export type CreateFencerAction = {
    type: "createFencer";
    id: string;
    firstName: string;
    lastName: string;
    graduationYear?: number;
    season: string;
    weapon: Weapon;
};

export type Action =
    | { type: "addFencer"; id: string; season: string; weapon: Weapon }
    | CreateFencerAction
    | { type: "deleteFencer"; id: string; season: string; weapon: Weapon }
    | {
          type: "editFencer";
          id: string;
          firstName: string;
          lastName: string;
          graduationYear?: number;
          season: string;
          weapon: Weapon;
          power?: number;
      }
    | {
          type: "updateStrip";
          id: string;
          season: string;
          weapon: Weapon;
          home: boolean;
          strip: number;
      }
    | { type: "cloneSeason"; seasonToClone: string; newSeason: string };

export type ActionQueue = Action[];

export type RosterRecords = Record<Weapon, Record<string, { wins: number; losses: number }>>;

export type BoutScorerTeamRoster = Record<string, (IExistingFencer | IWriteInFencer)[]>;

export interface ITeamInvite {
    id: ID;
    type: "team";
    team: ID;
    code: string;
    createdBy: ID;
    createdAt: DateTime;
    claimed: boolean;
    claimedAt?: DateTime;
    expiresAt: DateTime;
}

export interface IFencerRecord {
    id: string;
    bouts: Record<ID, { won: boolean; lost: boolean; weapon: Weapon; season: string }>;
}
