import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BoutSide, ICollegeEvent, IDualMeet, IDualMeetBout, IDualMeet_DB, IExistingFencer, ITeam, Weapon } from "../../../types";
import useDatabase from "../../../hooks/database";

import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Icon from "@mdi/react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { boutWinner } from "../../../utils/helpers";

import { mdiChevronDown } from "@mdi/js";


import TbTPage from "../../../components/TbTPage/TbTPage";
import ErrorPage from "../NotFound/NotFound";
import { CommonLoading } from "../../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { Link, Skeleton, Typography } from "@mui/material";

import "./FencerInfo.css";
import { MeetSection } from "../../../components/FencerInfo/FencerInfo";
import { DBResult, isSuccess } from "../../../utils/database";

import { useHistory } from "react-router-dom";

interface EventSectionProps {
    id: string;
    data: ICollegeEvent;
    meets: IDualMeet_DB[];
    meetBoutRec: Record<string, IDualMeetBout[]>;
}

const EventSection = ({ id, data, meets, meetBoutRec }: EventSectionProps) => {
    if (!data) {
        return <Skeleton sx={{ marginBottom: "20px" }} width={450} height={190} variant="rectangular" />;
    }

    const meetIDs = meets.filter(l => l.eventID === data.id && l.id in meetBoutRec).map(l => l.id);

    return (
        <Box style={{ marginBottom: 30 }}>
            <Link href={`/eventInfo/${data.id}`} target="_blank">
                <Typography sx={{ marginBottom: "20px" }} fontFamily="Lexend Deca" variant="h4" textAlign="left">
                    {data.name} - {new Date(data.startedAt).toDateString()}
                </Typography>
            </Link>
            {meetIDs.map((l, i) => (
                <MeetSection key={`meetSection${i}`} id={id} data={meets.find(j => j.id === l)!} bouts={meetBoutRec[l]} />
            ))}
        </Box>
    );
};

const FencerInfo = () => {

    const history = useHistory();
    const { id } = useParams();
    const DB = useDatabase();

    const season = useSelector((s: ReduxState) => s.season);

    const [loaded, setLoaded] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const [fencerInfo, setFencerInfo] = useState<IExistingFencer | null>(null);
    const [teams, setTeams] = useState<Record<string, ITeam>>({});
    const [meets, setMeets] = useState<IDualMeet_DB[]>([]);
    const [bouts, setBouts] = useState<IDualMeetBout[]>([]);
    const [events, setEvents] = useState<ICollegeEvent[]>([]);
    const [meetBoutRec, setMeetBoutRec] = useState<Record<string, IDualMeetBout[]>>({});
    const [eventMeetRec, setEventMeetRec] = useState<Record<string, Set<string>>>({});

    // Opponent info states for win rate calculation and filters.
    const [opponentIds, setOpponentIds] = useState<string[]>([])
    const [opponentInfo, setOpponentInfo] = useState<IDualMeetBoutFencerInfo[]>([]);
    const [opponentNames, setOpponentNames] = useState<string[]>([]);

    // teamInfo states for the filters.
    const [teamIds, setTeamIds] = useState<string[]>([])
    const [teamInfo, setTeamInfo] = useState<IDualMeetBoutFencerInfo[]>([]);
    const [teamNames, setTeamNames] = useState<string[]>([]);
    const [yourTeams, setYourTeams] = useState<string[]>([]);

    // chosenFencer and chosenTeam decide which team you wanted, based off of the order of the predetermined initial chosenOrder;
    const [chosenFencer, setChosenfencer] = useState(-1);
    const [chosenTeam, setChosenTeam] = useState(-1);
    // If true: then sort meets and events from newest to oldest.
    // If false: vice versa.
    const [chosenOrder, setChosenOrder] = useState(true);


    // I have no clue what this is for but I am afraid to end it.
    const [meetBoutRecPublished, setMeetBoutRecPublished] = useState<IDualMeet_DB[]>([]);

    const handlePlayerNameChange = (name: string) => {
        if (name == "All Fencers") {
            window.location.reload(false);
            setChosenfencer(-1);
            setChosenTeam(-1);
        }
        else if (name == null || name == "") {
            window.location.reload(false);
            setChosenfencer(-1);
            setChosenTeam(-1);
        }
        else {
            setChosenfencer(opponentNames.indexOf(name)+1);
            setChosenTeam(-1);
        }
    }

    const handleTeamNameChange = (name: string) => {
        if (name == "All Teams"){
            window.location.reload(false);
            setChosenTeam(-1);}
        else if (name == null || name == ""){
            window.location.reload(false);
            setChosenTeam(-1);}
        else{
            setChosenTeam(teamNames.indexOf(name));
            setChosenfencer(-1);
        }
    }

    const handleMeetOrderChange = (event) => {
        setChosenOrder(event.target.checked);
    }




    const handleFencerInfo = (result: DBResult<IExistingFencer>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setFencerInfo(result.data);
    };


    useEffect(() => {
        DB.getFencer(id)
            .then(handleFencerInfo)
            .catch((e: Error) => {
                setCriticalError(e.message);
            });
    }, [id]);

    useEffect(() => {
        if (fencerInfo?.teams) {
            Promise.all(fencerInfo.teams.map(l => DB.getTeam(l))).then(t => {
                const res: Record<string, ITeam> = {};
                for (const team of t) {
                    if (team.status === "fail") continue;
                    res[team.data.id] = team.data;
                }
                setTeams(res);
            });
        }
    }, [fencerInfo?.teams]);

    useEffect(() => {
        (async () => {
            const meetIDs: string[] = [];
            for (const teamID in teams) {
                const team = teams[teamID];
                if (team.dualMeets[season])
                    meetIDs.push(...team.dualMeets[season]);
            }
            const m = await Promise.allResolved(meetIDs.map(l => DB.getDualMeetRaw(l)));
            const dbMeets = m.filter(isSuccess).map(l => l.data);

            setMeets(dbMeets);

            const current = yourTeams;
            for (const team in teams){
                current.push(teams[team].name);
            }
            setYourTeams(current);

            const a = teamNames;

            for (const meet of dbMeets){

                const team1Name = meet.name.slice(0, meet.name.indexOf("vs.") - 5);
                const team2Name = meet.name.slice(meet.name.indexOf("vs.") + 4)
                if (yourTeams.indexOf(team1Name) < 0) {
                    if (a.indexOf(team1Name) < 0) {
                        a.push(team1Name);
                    }
                }
                else if (yourTeams.indexOf(team2Name) < 0) {
                    // this means that the team2 was not your team!
                    if (a.indexOf(team2Name) < 0)
                        a.push(team2Name);
                }
            }
            setTeamNames(a);


            const meetObj: Record<string, Set<string>> = {};
            const eventIDs: Set<string> = new Set();
            const boutIDs: string[] = [];


            if (chosenTeam >= 0){

                for (const meet of dbMeets) {
                    if (meet.name.indexOf(teamNames[chosenTeam]) >= 0) {
                        if (meet.eventID) {
                            const eventID = meet.eventID;
                            eventIDs.add(eventID);
                            if (meetObj[eventID]) {
                                meetObj[eventID].add(meet.id);
                            } else {
                                meetObj[eventID] = new Set([meet.id]);
                            }
                        }
                        boutIDs.push(...meet.bouts.map(l => l.id));
                    }}
            }
            else {
                for (const meet of dbMeets) {
                    if (meet.eventID) {
                        const eventID = meet.eventID;
                        eventIDs.add(eventID);
                        if (meetObj[eventID]) {
                            meetObj[eventID].add(meet.id);
                        } else {
                            meetObj[eventID] = new Set([meet.id]);
                        }
                    }
                    boutIDs.push(...meet.bouts.map(l => l.id));
                }
            }


            setEventMeetRec(meetObj);
            const e = await Promise.allResolved([...eventIDs].map(l => DB.getCollegeEvent(l)));
            const dbEvents = e.filter(isSuccess).map(l => l.data);
            dbEvents.sort((a, b) => Number(b.startedAt) - Number(a.startedAt));
            setEvents(dbEvents);
            const b = await Promise.allResolved(boutIDs.map(l => DB.getBout(l)));
            const dbBouts = b.filter(isSuccess).map(l => l.data);
            setBouts(dbBouts);

            const boutsObj: Record<string, IDualMeetBout[]> = {};
            if (chosenFencer > -1) {
                for (const bout of dbBouts) {
                    if (bout.fencer1.fencerInfo.id === opponentIds[chosenFencer] || bout.fencer2.fencerInfo.id == opponentIds[chosenFencer]) {
                        if (bout.fencer1.fencerInfo.id === id) {
                            if (boutsObj[bout.dualMeetId]) {
                                boutsObj[bout.dualMeetId].push(bout);
                            } else {
                                boutsObj[bout.dualMeetId] = [bout];
                            }
                            if (bout.fencer2.fencerInfo.id) {
                                if (opponentIds.indexOf(bout.fencer2.fencerInfo.id) === -1) {

                                    const x = opponentIds;
                                    x.push(bout.fencer2.fencerInfo.id);
                                    setOpponentIds(x);
                                    const y = opponentInfo;
                                    y.push(bout.fencer2.fencerInfo);
                                    setOpponentInfo(y);
                                    const z = opponentNames;
                                    z.push(bout.fencer2.fencerInfo.firstName + " " + bout.fencer2.fencerInfo.lastName);
                                    setOpponentNames(z);

                                }
                            }

                        }

                        if (bout.fencer2.fencerInfo.id === id) {
                            if (boutsObj[bout.dualMeetId]) {
                                boutsObj[bout.dualMeetId].push(bout);
                            } else {
                                boutsObj[bout.dualMeetId] = [bout];
                            }

                            if (bout.fencer1.fencerInfo.id) {
                                if (opponentIds.indexOf(bout.fencer1.fencerInfo.id) === -1) {
                                    // idk how to typescript? had this been python i would have just done a calm luh "not"
                                    const x = opponentIds;
                                    x.push(bout.fencer1.fencerInfo.id);
                                    setOpponentIds(x)
                                    const y = opponentInfo;
                                    y.push(bout.fencer1.fencerInfo);
                                    setOpponentInfo(y);
                                    const z = opponentNames;
                                    z.push(bout.fencer1.fencerInfo.firstName + " " + bout.fencer1.fencerInfo.lastName);
                                    setOpponentNames(z);

                                }
                            }
                        }
                    }
                }
            }

            else {
                for (const bout of dbBouts) {
                    if (bout.fencer1.fencerInfo.id === id) {
                        if (boutsObj[bout.dualMeetId]) {
                            boutsObj[bout.dualMeetId].push(bout);
                        } else {
                            boutsObj[bout.dualMeetId] = [bout];
                        }
                        if (bout.fencer2.fencerInfo.id) {
                            if (opponentIds.indexOf(bout.fencer2.fencerInfo.id) === -1) {
                                const x = opponentIds;
                                x.push(bout.fencer2.fencerInfo.id);
                                setOpponentIds(x);
                                const y = opponentInfo;
                                y.push(bout.fencer2.fencerInfo);
                                setOpponentInfo(y);
                                const z = opponentNames;
                                z.push(bout.fencer2.fencerInfo.firstName + " " + bout.fencer2.fencerInfo.lastName);
                                setOpponentNames(z);

                            }
                        }

                    }

                    if (bout.fencer2.fencerInfo.id === id) {
                        if (boutsObj[bout.dualMeetId]) {
                            boutsObj[bout.dualMeetId].push(bout);
                        } else {
                            boutsObj[bout.dualMeetId] = [bout];
                        }

                        if (bout.fencer1.fencerInfo.id) {
                            if (opponentIds.indexOf(bout.fencer1.fencerInfo.id) === -1) {
                                // idk how to typescript? had this been python i would have just done a calm luh "not"
                                const x = opponentIds;
                                x.push(bout.fencer1.fencerInfo.id);
                                setOpponentIds(x)
                                const y = opponentInfo;
                                y.push(bout.fencer1.fencerInfo);
                                setOpponentInfo(y);
                                const z = opponentNames;
                                z.push(bout.fencer1.fencerInfo.firstName + " " + bout.fencer1.fencerInfo.lastName);
                                setOpponentNames(z);

                            }
                        }
                    }
                }
            }

            const ids = opponentIds;
            const info = opponentInfo;
            const names = opponentNames;
            let teamns = teamNames;

            const x = ids.filter((id) => id.indexOf("writeIn") != 0)

            const y = info.filter((inf) => inf.firstName != "Unknown")

            for (const team of teamns) {
                console.log(team);
                let exists = false;
                for (const athlete of info) {
                    console.log(athlete.teamName);
                    if (athlete.teamName === team) {
                        exists = true;
                        break;
                    }
                }

                if (!exists){
                    teamns = teamns.filter((t) => t != team);

                }
            }



            setTeamNames(teamns);


            let z = names.filter((name) => name != "Unknown fencer")
            if (x[x.length-1] != "-1") {
                x.push("-1");
            }

            if (chosenTeam >= 0) {
                for (const i in z) {
                    console.log("info[i]");
                    console.log(info[i]);
                    if (info[i].teamName !=  teamNames[chosenTeam])
                            console.log("filtering: ")
                            z = z.filter((name) => name != info[i].firstName + " " + info[i].lastName);
                }
            }



            // TODO: Right now, I just added in the IfencerInfo listing to make it easier for osmeone in the future to add some more functionality to this if they wanted to.
            // so, as of right now, it really does not do much.
            setOpponentIds(x);
            setOpponentInfo(y);
            setOpponentNames(z);
            setMeetBoutRec(boutsObj);



            setLoaded(true);
            setMeetBoutRec(boutsObj);

            setLoaded(true);
        })();
    }, [teams, chosenFencer, chosenOrder, chosenTeam]);

    if (notFound) {
        return <ErrorPage message="The requested fencer could not be found." />;
    }

    if (!loaded) {
        return (
            <TbTPage>
                <CommonLoading color="#714FCA" size="large" />
            </TbTPage>
        );
    }

    if (!fencerInfo) {
        return (
            <TbTPage>
               <ErrorPage message="This season is empty or loading. If you are stuck on this page, please go back and choose a different season." />;
            </TbTPage>
        );
    }



    const sortedEvents = Object.entries(eventMeetRec)
        .filter(l => [...l[1]].some(j => j in meetBoutRec))
        .map(l => l[0]);

    if (chosenOrder) {
        sortedEvents.sort((a, b) => {
            if (!events.length) return 0;
            const ea = events.find(l => l.id === a)!;
            const eb = events.find(l => l.id === b)!;
            return Number(eb.startedAt) - Number(ea.startedAt);
        });
    }
    else {
        sortedEvents.sort((a, b) => {
            if (!events.length) return 0;
            const ea = events.find(l => l.id === a)!;
            const eb = events.find(l => l.id === b)!;
            return Number(ea.startedAt) - Number(eb.startedAt);
        });
    }



    let boutWins = 0;
    let totalBouts = 0;

    const allWins: Record<string, IDualMeetBout[]> = {};
    // TODO: Add later.
    // const personLeastWins: Record<string, IDualMeetBout[]> = {};

    for (const meet in meetBoutRec) {
        for (const bout of meetBoutRec[meet]) {
            const winner = boutWinner(bout);

            if (winner) {
                totalBouts += 1;

                // pushing all wins and all losses (not yet implemented) into a record (set, hashmap, for normal people coming from any other real language since for some reason ts calls it a record?!)
                // iterating through the record later to figure out who has the most wins and what not. O(3n) technically, could probably be solved with O(something less than that),
                // but to be honest, it does not make that much of a difference and I think that it will be helpful if we want to add features later maybe?!

                if (bout.fencer1.fencerInfo.id === id && winner === 1) {
                    boutWins += 1;
                    if (bout.fencer2.fencerInfo.id) {
                        if (allWins[bout.fencer2.fencerInfo.id]) {
                            allWins[bout.fencer2.fencerInfo.id].push(bout);
                        } else {
                            allWins[bout.fencer2.fencerInfo.id] = [bout];
                        }
                    }
                }

                if (bout.fencer2.fencerInfo.id === id && winner === 2) {
                    boutWins += 1;
                    if (bout.fencer1.fencerInfo.id) {
                        if (allWins[bout.fencer1.fencerInfo.id]) {
                            allWins[bout.fencer1.fencerInfo.id].push(bout);
                        } else {
                            allWins[bout.fencer1.fencerInfo.id] = [bout];
                        }
                    }
                }
            }
        }
    }

    return (
        <TbTPage className="eventsPage">
            <div style={{ height: 50 }}></div>
            <h1 style={{ fontWeight: "500", fontFamily: "Lexend Deca" }}>
                {fencerInfo.firstName} {fencerInfo.lastName}
            </h1>
            <h3 style={{ marginTop: "20px" }}>
                {Object.values(teams)
                    .map(l => l.name)
                    .join(", ")}
            </h3>


            <Accordion style={{width:"60%", margin:"auto"}}
            defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<Icon path={mdiChevronDown} size="20px" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Statistics</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex", flexDirection: "row", width: "100%", margin: "auto", flexWrap: "wrap"}}>

                    <div style={{ minWidth: 200, maxWidth: 200, margin: "auto", marginBottom:"10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>
                        <Card sx={{ width:"200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Wins:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {boutWins}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ minWidth: 200, maxWidth: 200, margin: "auto", marginBottom:"10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>
                        <Card sx={{ width:"200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Total Bouts:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {totalBouts}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>

                    <div style={{ minWidth: 200, maxWidth: 200, margin: "auto", marginBottom:"10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>
                        <Card sx={{ width:"200px", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Win Percentage:
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {Math.round(boutWins * 100 / totalBouts)}%
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>

                </AccordionDetails>
            </Accordion>

            <br></br>
            <Accordion style={{width:"60%", margin:"auto"}}
            defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<Icon path={mdiChevronDown} size="20px" />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Filters</Typography>
                </AccordionSummary>

                 <AccordionDetails style={{ display: "flex", flexDirection: "row", width: "100%", margin: "auto", flexWrap: "wrap"}}>

                    <div style={{ width:"220px", margin: "auto", marginBottom: "10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>

                        <Card sx={{ width:"100%", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px" }}>
                                <Switch
                                checked={chosenOrder}
                                onChange={handleMeetOrderChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {(chosenOrder) ? "Newest Meets First" : "Oldest Meets first"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>

                    <div style={{ width: "220px", margin: "auto", marginBottom: "10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>

                        <Card sx={{ width:"100%", margin: "auto", borderRadius: "30px" }}>
                            <CardContent sx={{ marginBottom: "0px", margin: "auto" }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={[...teamNames, "All teams"]}
                                    sx={{ width: "90%", margin:"auto", borderWidth: "0px"}}
                                    renderInput={(params) => <TextField {...params}  label="Choose School" />  }
                                    onChange={(event: any, newValue: string | null) => {
                                    handleTeamNameChange(newValue);}}
                                />
                            </CardContent>
                        </Card>

                    </div>
                    <div style={{ width: "220px", margin: "auto", marginBottom: "10px", borderRadius: "30px", alignItems: "center", justifyContent: "center", marginY:"auto", borderWidth: "0px", borderStyle:"solid", borderColor:"#FF00FF"}}>
                        <Card sx={{ width:"100%", margin: "auto", borderRadius: "30px" }}>

                            <CardContent sx={{ marginBottom: "0px", margin: "auto" }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={[...opponentNames, "All Fencers"]}
                                    sx={{ width: "90%", margin:"auto", borderWidth: "0px"}}
                                    renderInput={(params) => <TextField {...params}  label="Choose Fencer" />  }
                                    onChange={(event: any, newValue: string | null) => {
                                    handlePlayerNameChange(newValue);}}
                                />
                            </CardContent>

                        </Card>


                    </div>


                </AccordionDetails>
            </Accordion>



            <Box className="fencerInfoBox">
                {(sortedEvents.length > 0) ? (
                sortedEvents.map((l, i) => ( <EventSection
                        id={id}
                        data={events.find(j => j.id === l)!}
                        key={`eventInList${i}`}
                        meets={meets}
                        meetBoutRec={meetBoutRec}
                    /> )) ) : (<div>
                    The selected season is empty.
                        <br></br>
                        <Button variant="contained" onClick={history.goBack}>
                            Go Back to previous page
                        </Button>
                    </div>) }


            </Box>
            <div style={{ height: 30 }}></div>
        </TbTPage>
    );
};

export default FencerInfo;
