import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItemButton,
    ListItemText,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useRef, useState } from "react";
import { IExistingFencer, IFencerRecord } from "../../types";
import useDatabase from "../../hooks/database";
import SnackbarError from "../SnackbarError";
import { fencerName } from "../../utils/helpers";

const FencerRecordFixer = () => {
    const DB = useDatabase();

    const [id, setId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [searchResults, setSearchResults] = useState<IExistingFencer[]>([]);
    const [fencerRecords, setFencerRecords] = useState<Record<string, { wins: number; losses: number }>>({});
    const searching = useRef(false);
    const [selectedID, setSelectedID] = useState("");

    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const searchForFencer = () => {
        if (searching.current || disabled) return;
        searching.current = true;
        DB.getFencersByQuery({ id, firstName, lastName }).then(res => {
            if (res.status === "success") {
                setSearchResults(res.data);
                for (const fencer of res.data) {
                    DB.getFencerRecord(fencer.id).then(result => {
                        if (result.status === "success") {
                            const wins = Object.values(result.data.bouts).reduce((acc, cur) => acc + Number(cur.won), 0);
                            const losses = Object.values(result.data.bouts).reduce((acc, cur) => acc + Number(cur.lost), 0);
                            setFencerRecords(u => ({ ...u, [result.data.id]: { wins, losses } }));
                        }
                    });
                }
                setError("");
            } else {
                setSearchResults([]);
                setError(res.data);
            }
            searching.current = false;
        });
    };

    const fixRecords = () => {
        if (!selectedID) return;
        DB.migrateFencerRecords(selectedID).then(res => {
            if (res.status === "fail") {
                setError(res.data);
                setSuccess(false);
            } else {
                setError("");
                setSuccess(true);
            }
        });
    };

    const disabled = !id && !firstName && !lastName;

    return (
        <Card sx={{ marginBottom: "10px" }}>
            <CardHeader
                title="Fencer Record Fixer"
                titleTypographyProps={{
                    variant: "h4",
                    textAlign: "center",
                    fontFamily: "Lexend Deca"
                }}
            />
            <CardContent sx={{ textAlign: "left" }}>
                <Typography>
                    If your fencer is showing the incorrect record but has the proper amount of bouts when viewing their stats page, use
                    this tool. If the fencer does not have the proper bouts in their stats page, something else is the problem.
                </Typography>
                <Typography variant="caption">Note all inputs are case-sensitive.</Typography>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-around" }}>
                    <TextField value={id} onChange={e => setId(e.target.value)} label="Fencer ID" variant="standard" />
                    <TextField value={firstName} onChange={e => setFirstName(e.target.value)} label="First name" variant="standard" />
                    <TextField value={lastName} onChange={e => setLastName(e.target.value)} label="Last name" variant="standard" />
                </Box>
                <Button disabled={disabled} onClick={() => searchForFencer()} sx={{ margin: "10px 0", display: "block" }}>
                    Search for fencer...
                </Button>
                {searchResults.length ? (
                    <Card variant="outlined">
                        <List disablePadding sx={{ maxHeight: "200px", overflowY: "auto" }}>
                            {searchResults.map(l => (
                                <ListItemButton selected={selectedID === l.id} onClick={() => setSelectedID(l.id)} key={`sr${l.id}`}>
                                    <ListItemText
                                        primary={fencerName(l, {
                                            gradYear: true,
                                            record: fencerRecords[l.id] && `${fencerRecords[l.id]!.wins}-${fencerRecords[l.id]!.losses}`
                                        })}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Card>
                ) : null}
                <Button disabled={!selectedID} onClick={() => fixRecords()}>
                    Fix fencer records
                </Button>
                {success ? (
                    <Alert variant="outlined" severity="success">
                        Fencer record repair successful!
                    </Alert>
                ) : null}
                {error ? (
                    <Alert variant="outlined" severity="error">
                        {error}
                    </Alert>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default FencerRecordFixer;
